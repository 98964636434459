import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';

const colors = [
    'rgb(0, 0, 0)',
    '#1D3FF3',
    '#F6AE41',
    '#D93077',
    'red',
    '#10B36F'
];

const ColorPalette = ({ changeTheme, setcolor ,setShowPalette }) => {
    return (
        <div className='color-palette'>
            {colors.map((color, index) => (
                <div
                    key={index}
                    className="color-block"
                    style={{ height: '30px', width: '30px', backgroundColor: color, borderRadius: '50%', cursor: 'pointer' }}
                    onClick={() => {
                        changeTheme(color);
                        setcolor(color);
                        setShowPalette(false)
                        localStorage.setItem('--theme',color)
                    }}
                />
            ))}
        </div>
    );
};

const ColorPaletteToggle = () => {
    const [showPalette, setShowPalette] = useState(false);
    const [color, setcolor] = useState(localStorage.getItem('--theme') || '#10B36F');

    const togglePalette = () => {
        setShowPalette(!showPalette);
    };

    return (
        <div>
            <div className='mt-2'
                onClick={togglePalette}
                style={{ height: '20px', width: '20px', backgroundColor: color, borderRadius: '50%', cursor: 'pointer' }}
            />
            {showPalette && <ColorPalette setcolor={setcolor} setShowPalette={setShowPalette} changeTheme={(color) => { document.documentElement.style.setProperty('--theme', color) }} />}
        </div>
    );
};

export default ColorPaletteToggle;
