import React from 'react';
import { Toaster, toast } from 'react-hot-toast';

const Toast = () => {
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  );
};

export const showSuccess = (message, options = {}) => {
  toast.success(message, {
    style: {
      background: '#fff', // Change background color to green for success
      color: 'rgb(28, 186, 36)', // Text color
    },
    ...options 
  });
};

export const showError = (message, options = {}) => {
  toast.error(message, {
    style: {
      background: '#fff', 
      color: 'red',
    },
    ...options 
  });
};

export default Toast;





// import React from 'react';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const Toast = () => {
//   return (
//     <div>
//       <ToastContainer position="bottom-left" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false}  draggable={false} pauseOnHover />
//     </div>
//   );
// };

// export const showSuccess = (message, options = {}) => {
//   toast.success(message, options);
// };

// export const showError = (message, options = {}) => {
//   toast.error(message, options);
// };

// export default Toast;



