import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import { showSuccess, showError } from '../../toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IsModifiedModal from "../../Components/IsModified";
import DatePicker from "react-datepicker";
import { enIN } from 'date-fns/locale'


import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";



// import saveFormData from "./saveFormData";
import {
    Autocomplete,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import CountrySelect from "../../Components/utils/CountryCode";
import FreeSoloCreateOptionDialog from "./Addandsearch";
import ComboBox, { Category } from "./Categories";




const AddnewPurchaseGoods = () => {
    // const history = useHistory();
    const Navigate = useNavigate();
    const [address, setAddress] = useState("");




    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get("action") || "";
    const selectedid = searchParams.get("search") || "";

    const [formModified, setFormModified] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const generateRandom4DigitNumber = () => {
    //     return `CUST${Math.floor(Math.random() * 900) + 101}`;
    // };

    const [NewInd, setNewInd] = useState("")
    const [IndustryData, setIndustryData] = useState([]);
    const [vendorData, setvendorData] = useState([]);
    const user = localStorage.getItem("user_id");
    const COMPANY = localStorage.getItem("COMPANY");
    const [UserData, setUserData] = useState([]);
    const [countries, setcountries] = useState([]);
    const [_currency, setcurrency] = useState("Currency");
    const [fuelData, setfuelData] = useState([]);
    const [locations, setlocations] = useState([]);
    const [facilitys, setfacilitys] = useState([]);
    const [GoodsData, setGoodsData] = useState({
        GoodsID: "",
        productName: "",
        purchaseDate: new Date(),
        vendor: "",
        industryProcess: "",
        activity: "",
        category: "",
        amount: "",
        Currency: "",
        docs: '',
        source: '',
        destination: '',
        distance: '',
        VehicleType: '',
        FuelType: '',
        createdBy: user,
        Company: COMPANY


    });

    // const generateRandom4DigitNumber = async () => {
    //     // // debugger;
    //     let goods = await axiosInstance.get("/Goods");
    //     const sortedData = goods.data?.reverse()
    //     if (sortedData.length > 0) {
    //         const { GoodsID } = sortedData[0];
    //         let id = parseInt(GoodsID?.slice(4), 10);
    //         return `GOOD${id + 1}`;
    //     }
    //     else {
    //         return `GOOD1`;
    //     }

    // };

    const generateRandom4DigitNumber = async () => {
        try {
            // // debugger;
            const response = await axiosInstance.get("/Goods");
            // // debugger
            const sortedData = response?.data

            const latestIndustry = sortedData[0];
            console.log("latestIndustry", latestIndustry)
            let id = 1;
            if (latestIndustry) {
                const Goods = latestIndustry;
                id = parseInt(Goods?.GoodsID.substring(4)) + 1;
            }
            return `GOOD${id}`;
        } catch (error) {
            console.error("Error fetching latest Vendor ID:", error);
            return `GOOD0`;
        }
    };

    const getIndustryData = async () => {
        const USER = JSON.parse(localStorage.getItem('_USER'));
        // console.log(USER)
        try {
            await axiosInstance.get(`/industry/${USER ? USER.Company.Industry : ''}`)
                .then((res) => {
                    console.log("indID", res.data.data)
                    const sortedData = res.data.data;

                    setIndustryData(sortedData);


                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const getVendorData = async () => {
        try {
            await axiosInstance.get("/vendors")
                .then((res) => {
                    console.log("dss", res.data)
                    const sortedData = res.data;

                    setvendorData(sortedData);
                    console.log(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };


    const get_fuel = async () => {
        await axiosInstance.get("/fuels")
            .then((response) => {
                console.log("fueldata", response);
                setfuelData(response.data.data);
            })
            .catch((error) => {
                console.error("Axios Error:", error);
            });
    };


    useEffect(() => {
        const fetchData = async () => {
            const randomID = await generateRandom4DigitNumber();
            setGoodsData((prev) => ({
                ...prev,
                GoodsID: randomID
            }));
        };
        getVendorData()
        const USER = JSON.parse(localStorage.getItem('_USER'));
        console.log(USER)

        setUserData(USER)
        getIndustryData()
        fetchData();
        // console.log("UserData",UserData)
        get_fuel()

    }, []);


    const fetchData = async () => {
        const facilityRes = await axiosInstance.get(
            "/customerFacility"
        );
        const locationres = await axiosInstance.get(
            "/location"
        );
        console.log("loc", locationres.data);
        setlocations(locationres.data.userLocations);

        console.log("facility", facilityRes.data.data);
        setfacilitys(facilityRes.data.data);

    }

    useEffect(() => {

        axios.get('https://restcountries.com/v3.1/all')
            .then(response => {
                const countries = response.data.map(country => ({
                    name: country.name.common,
                    currency: country.currencies ? Object.keys(country.currencies)[0] : 'Unknown' // Check if currencies property exists
                }));
                console.log("countries",countries)
                setcountries(countries)
            })
            .catch(error => console.error('Error fetching data:', error));


        fetchData()


    }, []);



    useEffect(() => {
        if (selectedid) {
            axiosInstance.get(`/Goods/${selectedid}`).then((res) => {
                console.log("selectedID", res.data)
                const selectedgoods = res.data
                console.log("selectedVendor", selectedgoods)
                if (selectedgoods) {
                    setGoodsData(selectedgoods);

                }
            })
        }
    }, [selectedid])

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setFormModified(true)
        setGoodsData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {


        return true;
    };

    const makeApiCall = async (method, url, data, callback) => {
        try {
            // // debugger;
            const response = await axiosInstance[method.toLowerCase()](url, {
                ...data
            });

            console.log("res", response)

            if (response.data.success === false) {
                showError(response?.data?.message)
            }
            else {
                showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
                callback()
                return response.data;
            }

        } catch (error) {

            console.error("Error making API call:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    };

    const handleClickSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm();

        console.log(GoodsData)

        const EM = Category.filter((category) => category.category === GoodsData.category)
        console.log({ EM })

        showSuccess(`${EM[0]?.kg_co2_eq_per_usd_1} kg CO2 equivalent per USD`)
        showSuccess(EM[0]?.kg_co2_eq_per_usd_1 * GoodsData?.amount)
        const newData = {

        }
        // console.log("customerData", customerData);
        console.log("newdata", newData);
        // if (isValid) {
        //     try {
        //         const responseData = await makeApiCall(
        //             `${action === "" ? 'POST' : action === 'update' ? 'PUT' : 'DELETE'}`,
        //             `/${action === "" ? `Goods` : action === 'update' ? `Goods/${selectedid}` : `Goods/${selectedid}`}`,
        //             GoodsData, () => { Navigate('/purchaseGoods') }
        //         );
        //         console.log(responseData);
        //     } catch (error) {

        //         console.error("Error submitting form:", error);
        //     }
        // }

    };

    const containerStyle = {
        display: "flex",
    };

    const formStyle = {
        height: '80vh',
        overflow: 'scroll',
        padding: "10px",
        borderRadius: "10px",
        margin: "none",
        marginTop: '20px',
        backgroundColor: "white",
    };

    const uploadFile = async (file, name) => {
        try {
            console.log(file)
            const formData = new FormData();
            formData.append('pucFile', file);

            const response = await axiosInstance.post(
                "/uploadPUCFile",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log("uploads", response)
            setFormModified(true)
            return response?.data?.imageurl?.Location;
        } catch (error) {
            console.log(error);

            return null;
        }
    };

    const handleDocsUpload = async (value, name) => {
        // alert(`${value} - ${name}`)
        // // debugger;
        console.log(value)
        const fileUrl = await uploadFile(value, name);
        console.log(name)
        setGoodsData((prevValues) => ({
            ...prevValues,
            [name]: fileUrl,
        }));


    }



    const handleModalClose = (result) => {
        setIsModalOpen(false);
        if (!result) {
            Navigate('/purchaseGoods')
        }
    };

    const handleBackbutton = () => {
        // alert(formModified)
        if (formModified) {
            setIsModalOpen(true)
        }
        else {
            Navigate('/purchaseGoods')
        }
    }

    const generateRandom4DigitNumberIND = async () => {
        try {
            const response = await axiosInstance.get("/industry");
            // debugger
            const sortedData = response.data

            const latestIndustry = sortedData[0];
            console.log("latestIndustry", latestIndustry)
            let id = 1;
            if (latestIndustry) {
                const latestID = latestIndustry.IndustryID;
                id = parseInt(latestID.substring(3)) + 1;
            }
            return `IND${id}`;
        } catch (error) {
            console.error("Error fetching latest industry ID:", error);
            return `IND0`;
        }
    };

    const updateData = async (newData) => {
        try {
            console.log("new ind data", newData)
            const USER = JSON.parse(localStorage.getItem('_USER'));
            const a = {
                IndustryID: await generateRandom4DigitNumberIND(),
                Industry: USER ? USER.Company.Industry : '',
                IndustryProcess: newData.IndustryProcess,
                docs: '',
                createdBy: localStorage.getItem("user_id")
            }
            console.log("a=----", a)
            try {
                const responseData = await makeApiCall(
                    `POST`,
                    `/industry`,
                    a, () => { }
                );


                console.log(responseData);
                getIndustryData()
            } catch (error) {

                console.error("Error submitting form:", error);
            }
            //   setIndustryData(prevData => ([...prevData, response.data]));
        } catch (error) {
            console.error("Error adding industry data:", error);
        }
    };


    const handleCurrencyChange = (e, newValue) => {
        setGoodsData({ ...GoodsData, Currency: newValue ? newValue.currency : '' })
        setcurrency(`Currency: ${newValue?.currency}`)
        showSuccess(`${newValue?.name}: ${newValue?.currency}`)

        // const inUSD = ConverttoUSD(GoodsData.amount)




        console.log("newValue", newValue)
    }


    const debouncedHandleChange = (e, newValue) => {
        console.log({newValue})
        const EM = Category.filter((category) => category.category === newValue.category);
        showSuccess(`${EM[0]?.kg_co2_eq_per_usd_1} kg CO2 equivalent per USD`)
        setGoodsData({ ...GoodsData, category: newValue ? newValue.category : "" });
    };


    return (
        <div style={containerStyle}>
            <div>
                <Sidebar />
            </div>
            <div style={{ width: "100%", margin: '10px' }}>
                <Header />
                <div className="table-heading theme_c"><span onClick={() => handleBackbutton()}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New '}Purchased Goods & Services  <span className={action ? `_${action}` : '_edit'}>{`  ${GoodsData.productName || ''}`}</span></div>
                <div className="hidden_scrollbar" style={formStyle}>
                    <form className="fuelform">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <label htmlFor="col2">Goods & Service ID <span className="_MANDATORY">*</span></label>
                                <TextField
                                    variant="outlined"
                                    disabled
                                    fullWidth
                                    id="col2"
                                    value={GoodsData.GoodsID}
                                    name="GoodsID"
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <label htmlFor="col2">Product Name <span className="_MANDATORY">*</span></label>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    id="col2"
                                    value={GoodsData.productName}
                                    name="productName"
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <label>Purchase date <span className="_MANDATORY">*</span></label>
                                <DatePicker
                                    style={{ width: "100%" }}
                                    selected={new Date(GoodsData.purchaseDate)}
                                    // dateFormat="dd/MM/yyyy HH:mm"
                                    // showTimeInput
                                    // locale={enIN}
                                    maxDate={new Date()}
                                    onChange={(date) => setGoodsData((prevData) => ({ ...prevData, purchaseDate: date }))}
                                    customInput={<TextField variant="outlined" fullWidth id="col7" />}
                                />


                            </Grid>

                            <Grid item xs={4}>
                                <label>Vendor <span className="_MANDATORY"> *</span></label>
                                <Select
                                    fullWidth
                                    style={{ height: '38px' }}
                                    value={GoodsData.vendor}
                                    onChange={(e) => setGoodsData({ ...GoodsData, vendor: e.target.value })}
                                    required
                                    name="vendor"
                                >
                                    {vendorData.map((ind, i) => (
                                        <MenuItem key={i} value={ind._id} className="">
                                            <span>
                                                {ind.name}&nbsp;{ind.vendorID}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>



                            <Grid item xs={4}>

                                <label>Activity<span className="_MANDATORY"> *</span></label>
                                <Select
                                    fullWidth
                                    style={{ height: '38px' }}
                                    value={GoodsData.activity}
                                    // onChange={(e) => setGoodsData({ ...GoodsData, activity: e.target.value })}
                                    onChange={(e) => {
                                        const activityValue = e.target.value;
                                        setGoodsData(prevData => ({
                                            ...prevData,
                                            activity: activityValue,
                                            amount: activityValue === "Transportation and Distribution" ? "" : prevData.amount,
                                            Currency: activityValue === "Transportation and Distribution" ? "" : prevData.Currency,
                                            source: activityValue === "Transportation and Distribution" ? "" : prevData.source,
                                            destination: activityValue === "Transportation and Distribution" ? "" : prevData.destination,
                                            distance: activityValue === "Transportation and Distribution" ? "" : prevData.distance,
                                            VehicleType: activityValue === "Transportation and Distribution" ? "" : prevData.VehicleType,
                                            FuelType: activityValue === "Transportation and Distribution" ? "" : prevData.FuelType,
                                        }));
                                    }}
                                    name="activity"
                                    required

                                >
                                    <MenuItem value="Production Materials">Production Materials</MenuItem>
                                    <MenuItem value="Finished Goods">Finished Goods</MenuItem>
                                    <MenuItem value="Transportation and Distribution">Transportation and Distribution</MenuItem>
                                    <MenuItem value="Information Technology and Communication">Information Technology and Communication </MenuItem>
                                    <MenuItem value="Professional Services">Professional Services  </MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>

                                <label>Categories<span className="_MANDATORY"> *</span></label>


                                <Autocomplete
                                    className="autocomplete_Search"
                                    disablePortal
                                    id="category"
                                    name="category"
                                    value={{ category: GoodsData.category }}
                                    onChange={debouncedHandleChange}
                                    options={Category}
                                    getOptionLabel={(option) => option.category}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <label>Industry Process<span className="_MANDATORY"> *</span></label>
                                <FreeSoloCreateOptionDialog className="autocomplete_Search" value={GoodsData.industryProcess} industryData={IndustryData.IndustryProcess} updateData={updateData} setGoodsData={setGoodsData} />

                            </Grid>



                            {GoodsData.activity === "Transportation and Distribution" ? (
                                <>
                                    <Grid item xs={4}>
                                        <label>Source</label>
                                        <FormControl fullWidth variant="outlined">
                                            <Select
                                                // label="Type"
                                                fullWidth
                                                id="col3"
                                                name="source"
                                                value={GoodsData.source}
                                                onChange={handleInputChange}
                                            >

                                                {locations && locations.map((item, idx) => (
                                                    <MenuItem
                                                        style={{ height: "auto" }}
                                                        key={item.locationName}
                                                        value={item._id}
                                                    >
                                                        {item.locationName}
                                                    </MenuItem>
                                                ))}
                                                {facilitys && facilitys.map((item, idx) => (
                                                    <MenuItem
                                                        style={{ height: "auto" }}
                                                        key={item.address}
                                                        value={item._id}
                                                    >
                                                        {item.address}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <label>Destination</label>
                                        <FormControl fullWidth variant="outlined">
                                            <Select
                                                // label="Type"
                                                fullWidth
                                                id="col3"
                                                name="destination"
                                                value={GoodsData.destination}
                                                onChange={handleInputChange}
                                            >

                                                {locations && locations.map((item, idx) => (
                                                    <MenuItem
                                                        style={{ height: "auto" }}
                                                        key={item.locationName}
                                                        value={item._id}
                                                    >
                                                        {item.locationName}
                                                    </MenuItem>
                                                ))}
                                                {facilitys && facilitys.map((item, idx) => (
                                                    <MenuItem
                                                        style={{ height: "auto" }}
                                                        key={item.address}
                                                        value={item._id}
                                                    >
                                                        {item.address}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <label>Distance</label>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                            value={GoodsData.distance}
                                            name="distance"
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <label>Type of Vehicle</label>

                                        <Select
                                            fullWidth
                                            value={GoodsData.VehicleType}
                                            onChange={handleInputChange}
                                            style={{ height: '38px' }}
                                            name="VehicleType"
                                        >

                                            <MenuItem value="LCV">Light commercial vehicle (LCV)</MenuItem>
                                            <MenuItem value="HCV">Heavy Commercial Vehicle (HCV)</MenuItem>
                                            <MenuItem value="MCV">Manufacturing Commercial Vehicles (MCV)</MenuItem>
                                            <MenuItem value="SCV">Small Commercial Vehicle (SCV)</MenuItem>
                                            <MenuItem value="PT">Public Transport (PT)</MenuItem>
                                            <MenuItem value="PO">Privately Owned (PO)</MenuItem>
                                            <MenuItem value="CO">Company Owned (CO)</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={4} className="">
                                        <FormControl fullWidth variant="outlined">
                                            <label htmlFor="col8">Fuel Type  <span className="_MANDATORY">*</span></label>
                                            <Select
                                                // label="Fuel Type"
                                                fullWidth
                                                id="col8"
                                                name="FuelType"
                                                value={GoodsData.FuelType}
                                                onChange={handleInputChange}
                                            >

                                                {fuelData.map((fuel, i) => (
                                                    <MenuItem key={i} value={fuel.fuelID} className="">
                                                        <span>
                                                            {fuel.fuelType}&nbsp; <span style={{ color: localStorage.getItem('--theme') || '#ccc', fontSize: '10px' }}> in {fuel.Measurement}</span>
                                                        </span>
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            ) :
                                <>
                                    <Grid item xs={4}>
                                        <label htmlFor="col2">Amount <span className="_MANDATORY">*</span></label>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            id="col2"
                                            value={GoodsData.amount}
                                            name="amount"
                                            disabled={!GoodsData.category || !GoodsData.activity}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                        <label>Currency<span className="_MANDATORY"> *</span></label>
                                        <Select
                                            fullWidth
                                            style={{ height: '38px' }}
                                            value={GoodsData.Currency}
                                            onChange={(e) => setGoodsData({ ...GoodsData, Currency: e.target.value })}
                                            required
                                            name="Currency"
                                        >
                                            <MenuItem value="Nos">Nos</MenuItem>
                                            <MenuItem value="Kgs">Kgs</MenuItem>
                                            <MenuItem value="Lts">Lts</MenuItem>
                                        </Select>
                                    </Grid> */}
                                    {(GoodsData.category && GoodsData.activity)
                                        &&
                                        <Grid item xs={4}>

                                            <label className="">{_currency}<span className="_MANDATORY"></span></label>
                                            <Autocomplete
                                                className="autocomplete_Search"
                                                disablePortal
                                                id="Currency"
                                                name="Currency"
                                                onChange={(e, newValue) => handleCurrencyChange(e, newValue)}
                                                options={countries}
                                                disabled={!GoodsData.category || !GoodsData.activity}
                                                getOptionLabel={(option) => option?.name}
                                                // getOptionLabel={(option) => (<><span>{option.currency}</span>&nbsp;&nbsp; <span className="vsm _edit">{option.name}</span></>) }
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Grid>
                                    }

                                </>

                            }



                            <Grid item xs={12} className="mt-3">
                                <label htmlFor="col8">Upload</label>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "35px",
                                        border: "2px dotted lightgrey",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        borderRadius: "12px",
                                    }}
                                >
                                    {
                                        !GoodsData.docs &&
                                        <label>
                                            Drag and Drop or{" "}
                                            <Button
                                                sx={{
                                                    backgroundColor: "grey",
                                                    color: "white",
                                                    opacity: 0.8,
                                                    fontSize: "10px",
                                                    fontWeight: "300",
                                                }}
                                                onClick={() =>
                                                    document.querySelector('input[type="file"]').click()
                                                }
                                            >
                                                Browse
                                            </Button>
                                            <br />
                                            <span className="f-8 ">Acepted File types ( png , jpeg ,docx  )</span>

                                        </label>
                                    }

                                    <input
                                        type="file"
                                        name="docs"
                                        onChange={(e) =>
                                            handleDocsUpload(e.target.files[0], 'docs')
                                        }
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <div>
                                        {GoodsData.docs ? (
                                            <img
                                                src={GoodsData.docs}
                                                alt="Uploaded"
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <button
                                    className="submit_btn"
                                    style={{
                                        color: "white",
                                        marginTop: '20px',
                                        backgroundColor: "black",
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                    }}
                                    onClick={handleClickSubmit}
                                >
                                    {action ? action.toUpperCase() : 'Submit'}
                                </button>
                            </Grid>
                        </Grid>

                        {isModalOpen &&
                            <IsModifiedModal
                                open={isModalOpen}
                                onClose={handleModalClose}
                                description="Do you want to save the data before going back?"
                            />
                        }
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddnewPurchaseGoods;
