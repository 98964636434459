import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../Pages/LoginPage/Login";
import LocationMaster from "../Pages/LocationMaster/LocationMaster";
import AddNewLocation from "../Pages/LocationMaster/AddNewLocation";
import FuelMaster from "../Pages/FuelMaster/FuelMaster";
import AddNewFuelType from "../Pages/FuelMaster/AddNewFuelType";
import VehicleMaster from "../Pages/VehicleMaster/VehicleMaster";
import AddNewVehicleMaster from "../Pages/VehicleMaster/AddNewVehicleMaster";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Trip from "../Pages/Trip/Trip";
import CO2Calculator from "../Pages/CO2Calculator/CO2Calculator";
import RollsandPermissions from "../Pages/RollsAndPermissions/RollsandPermissions";
import SupportPage from "../Pages/Support/SupportPage";
import Documentation from "../Pages/Documentation/Documentation";
import AddNewDoc from "../Pages/Documentation/AddNewDoc";
import AddNewTrip from "../Pages/Trip/AddNewTrip";
import DriverMaster from "../Pages/Driver/DriverMaster";
import AddNewDriverMaster from "../Pages/Driver/AddNewDriverMaster";
import HeroDesign from "../Pages/Trip/heroDesign";
import PDFViewer from "../Components/Pdfviewer";
import ColorPaletteToggle from "../Components/utils/Cpallete";
import WorldVector from "../Components/Map";
import VendorMaster from "../Pages/vendor/vendorMaster";
import AddnewVendorMaster from "../Pages/vendor/addnewVendorMaster";
import CustomerFacility from "../Pages/Customer/CustomerFacility";
import AddnewCustomerMaster from "../Pages/Customer/AddnewCustomerFacility";
import PurchaseAndGoods from "../Pages/PurchaseAndGoods/PurchaseAndGoods";
import AddnewPurchaseGoods from "../Pages/PurchaseAndGoods/AddnewPurchaseGoods";
import Industry from "../Pages/industry/Industry";
import AddNewIndustry from "../Pages/industry/AddNewIndustry";
import Billing from "../Pages/Billing/Billing"
import AddNewBilling from "../Pages/Billing/AddNewBilling"
import NotFound from "../Pages/NotFound";
import FreeSoloCreateOptionDialog from "../Pages/PurchaseAndGoods/Addandsearch";
import Charts from "../Pages/Dashboard/Charts/Charts";
import GetPOint from "../Pages/Dashboard/Charts/PointStylingEmission";
import MapWithMarkers from "../Components/utils/WorldMap";
import CompanyMaster from "../Pages/Company/Company";
import AddnewCompanyMaster from "../Pages/Company/AddNewCompanyMaster";
import Invoices from "../Pages/Invoices/Invoices";



const moduleMapping = {
  "Dashboard": { route: "Dashboard", logo: '', displayName: "Dashboard", addroutenames: ["Dashboard"] },
  "Trips": { route: "trips", logo: 'triplogo', displayName: "Trips", addroutenames: ["trips", "addnewtrip"] },
  "Industry": { route: "industry", logo: 'triplogo', displayName: "Industry Process", addroutenames: ["industry", "AddNewindustry"] },
  "Billing": { route: "Billing", logo: 'triplogo', displayName: "Billing", addroutenames: ["Billing", "AddNewBill"] },
  "Company": { route: "CompanyMaster", logo: 'triplogo', displayName: "Company OnBoard", addroutenames: ["CompanyMaster", "AddnewCompanyMaster"] },
  "Purchase Goods & Services": { route: "purchaseGoods", logo: 'triplogo', displayName: "Purchase Goods & Services", addroutenames: ["purchaseGoods", "AddNewpurchaseGoods"] },
  "Customer Facility": { route: "customerfacility", logo: 'driver', displayName: "Customer Facility", addroutenames: ["customerfacility", 'AddnewCustomerfacility'] },
  "Roles & Permissions": { route: "rollsandpermission", logo: 'permission', displayName: "Roles & Permissions", addroutenames: ["rollsandpermission"] },
  "Destination Master": { route: "locationmaster", logo: 'location', displayName: "Destination Master", addroutenames: ["locationmaster",'AddNewLocationMaster'] },
  "Fuel Master": { route: "fuelmaster", logo: 'fuel', displayName: "Fuel Master", addroutenames: ["fuelmaster", 'AddNewFuelType'] },
  "Vendor Master": { route: "vendorMaster", logo: 'driver', displayName: "Vendor Master", addroutenames: ["vendorMaster",'AddnewVendorMaster'] },
  "Vehicle Master": { route: "VehicleMaster", logo: 'vehicle', displayName: "Vehicle Master", addroutenames: ["VehicleMaster", 'AddNewVehicleMaster'] },
  "Driver Master": { route: "DriverMaster", logo: 'driver', displayName: "Driver Master", addroutenames: ["DriverMaster", "AddNewDriverMaster"] },
  "CO2 Calculator": { route: "co2calculatorpage", logo: 'co2', displayName: "CO2 emission estimator", addroutenames: ["co2calculatorpage"] },
  "Support": { route: "SupportPage", logo: 'support', displayName: "Support", addroutenames: ["SupportPage"] },
  "Documentation": { route: "Documentation", logo: 'documentation', displayName: "Documentation", addroutenames: ["Documentation"] },
  "Invoices" : {route : "Invoices" , logo : 'documentation' , displayName : "Invoices" , addroutenames : ["Invoices"]}
};

function isSubmenuPresent(menu, target) {
  if (Array.isArray(menu)) {
      for (const item of menu) {
          if (typeof item === 'object') {
              if (isSubmenuPresent(Object.values(item), target)) {
                  return true;
              }
          } else if (item === target) {
              return true;
          }
      }
  }
  return false;
}


const ProtectedRoute = ({ element, access }) => {
  // debugger;
  const userAccess = JSON.parse(localStorage.getItem("access"));
  const isTokenAvailable = !!localStorage.getItem("token");
  const _USER = localStorage.getItem('welcome-NIRANARA').split('-')[0].trim();
  const _PATH = window.location.pathname?.slice(1);


  if (!_USER) {
    return <Navigate to="/login" />;
  }
  if (!isTokenAvailable) {
    return <Navigate to="/login" />;
  }

  // Check if the route is in user access
  const routeInfo = Object.values(moduleMapping).find(item => item.addroutenames?.includes(_PATH));
  console.log("routeInfo",routeInfo)
  if (!routeInfo ) {
    return <Navigate to="/NOT FOUND" />;
  }
  if ( !isSubmenuPresent(userAccess, routeInfo.displayName)) {
    return <Navigate to="/unauthorized" />;
  }

  return element;
};


const Index = () => {
  return (
    <Routes>
      {/* Routing of the Login Page */}
      <Route path="/" element={<Login />} />
      <Route path="/unauthorized" element={<NotFound />} />
      <Route path="/login" element={<Login />} access={['Manager', 'ADMIN']} />
      <Route path="/Analytics" element={<Charts  />} access={['Manager', 'ADMIN']} />
      <Route path="/point" element={<GetPOint  />} access={['Manager', 'ADMIN']} />
      <Route path="/map" element={<MapWithMarkers  />} access={['Manager', 'ADMIN']} />
      {/* Routing of the Location Master Page */}
      <Route path="/LocationMaster" element={<ProtectedRoute element={<LocationMaster />} />}></Route>
      <Route path="/AddNewLocationMaster" element={<ProtectedRoute element={<AddNewLocation />} />} />
      {/* Routing of the Fuel Master Page */}
      <Route path="/fuelmaster" element={<ProtectedRoute element={<FuelMaster />} />} />
      <Route path="/AddNewFuelType" element={<AddNewFuelType />} />
      {/* Routing of the Vehicle Master Page */}
      <Route path="/AddnewVendorMaster" element={<ProtectedRoute element={<AddnewVendorMaster />} />} />
      <Route path="/vendorMaster" element={<ProtectedRoute element={<VendorMaster />} />} />
      <Route path="/VehicleMaster" element={<ProtectedRoute element={<VehicleMaster />} />} />
      <Route path="/AddNewVehicleMaster" element={<AddNewVehicleMaster />} />

      <Route path="/CompanyMaster" element={<ProtectedRoute element={<CompanyMaster />} />} />
      <Route path="/AddnewCompanyMaster" element={<ProtectedRoute element={<AddnewCompanyMaster />} />} />

      
      {/* driver */}
      {/* <Route path="/Ty" element={<WorldVector />}/> */}
      <Route path="/DriverMaster" element={<DriverMaster />} />
      <Route path="/AddNewDriverMaster" element={<AddNewDriverMaster />} />
      {/* Routing of the Dashbaord Page*/}
      <Route path="/Dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
      {/* Routing of the Trip Page */}
      <Route path="/trips" element={<ProtectedRoute element={<Trip />} access={['SUPER ADMIN', 'ADMIN']} />} />
      <Route path="/AddNewTrip" element={<AddNewTrip />} />


      {/* purchase and goods */}
      <Route path="/purchaseGoods" element={<ProtectedRoute element={<PurchaseAndGoods />} access={['Manager', 'ADMIN']} />} />
      <Route path="/AddNewpurchaseGoods" element={<AddnewPurchaseGoods />} />

      {/* industry */}

      <Route path="/industry" element={<ProtectedRoute element={<Industry />} access={['Manager', 'ADMIN']} />} />
      <Route path="/AddNewindustry" element={<AddNewIndustry />} />

      {/* billing master  */}

      <Route path="/billing" element={<ProtectedRoute element={<Billing />} access={['Manager', 'ADMIN']} />} />
      <Route path="/AddNewBill" element={<AddNewBilling />} />

      <Route path="/newpage" element={<HeroDesign />} />
      {/* Routing of the CO2Page */}
      <Route path="/co2calculatorpage" element={<CO2Calculator />} />
      {/* Routing of the Rolls and Permission */}
      <Route path="/customerfacility" element={<ProtectedRoute element={<CustomerFacility />} />} />
      <Route path="/AddnewCustomerfacility" element={<ProtectedRoute element={<AddnewCustomerMaster />} />} />
      <Route path="/rollsandpermission" element={<ProtectedRoute element={<RollsandPermissions />} />} />
      {/* Routing of the Support Page */}
      <Route path="/SupportPage" element={<ProtectedRoute element={<SupportPage />} />} />
      {/* Routing of the Documentation Page */}
      <Route path="/Documentation" element={<Documentation />} />
      <Route path="/AddNewDocument" element={<AddNewDoc />} />
      <Route path="/Invoices" element={<Invoices />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Index;
