import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axios from "axios";
import { showSuccess, showError } from "../../toast";
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import axiosInstance from "../../Components/utils/axios"
import { useNavigate } from "react-router-dom";
import { retry } from "@aws-amplify/core/internals/utils";

const AddNewDoc = () => {
  const navigate = useNavigate();
  const [acceptedFileTypes, setAcceptedFileTypes] = useState("*");
  const [formData, setFormData] = useState({
    serialNo: "",
    name: "",
    type: "default",
    docType: "",
    file: null,
  });

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     name: `${prevFormData?.type}_${prevFormData?.docType}_${formData?.file?.name == undefined ? '':formData?.file?.name}`,
  //   }));
  // }, [formData.type, formData.docType,formData?.file]);

  const handleInputChange = (name, value) => {
    if (name === "type" && value == "default") {
      showError("Please Select the File Type")
      return
    }

    if (name === "type") {

      if (value == "image") { setAcceptedFileTypes("image/*") }
      else if (value == "document") { setAcceptedFileTypes(".doc, .docx") }
      else { setAcceptedFileTypes("application/pdf") }
    }

    setFormData({
      ...formData,
      [name]: value,
      
    });
   
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !formData.serialNo ||
      !formData.type ||
      !formData.name ||
      !formData.docType ||
      !formData.file 
    ) {
      showError("Please fill all fields are mandatory");
      return;
    }
    else if(formData.type == "default"){
      showError("Please select the File type");
      return;
    }

    try {
      const formData2 = new FormData();
      formData2.append("serialNo", formData.serialNo);
      formData2.append("name", `${formData?.name}_${formData?.type}_${formData?.docType}`);
      formData2.append("type", formData.type);
      formData2.append("docType", formData.docType);
      formData2.append("upload", formData.file);
      console.log("formData2", formData2)

      const response = await axiosInstance.post(
        "/submitDocuments",
        formData2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Data saved successfully!");
        navigate("/Documentation");
        // Assuming you are using React Router, replace with your navigation logic
        // import { navigate } from "react-router-dom";
        // navigate("/Documentation");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error during data submission:", error);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ height: "100vh" }}>
        {/* Sidebar component */}
        <Sidebar />
      </div>
      <div style={{ width: "80%", height: "100vh", margin: "auto" }}>
        {/* Header component */}
        <Header />

        {/* Form starts here */}
        <Paper
          elevation={3}
          style={{ padding: "20px", marginTop: "20px", borderRadius: "10px" }}
        >
          <form onSubmit={handleSubmit}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Document No.</TableCell>
                  <TableCell width="20%">Name</TableCell>
                  <TableCell width="10%">File Type</TableCell>
                  <TableCell width="20%">Doc. Type</TableCell>
                  <TableCell width="30%">File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Your form fields go here */}
                <TableRow>
                  <TableCell>
                    <TextField
                      name="serialNo"
                      placeholder="Serial No."
                      variant="outlined"
                      value={formData.serialNo}
                      onChange={(e) =>
                        handleInputChange("serialNo", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      style={{ width: '400px' }}
                      name="name"
                      placeholder="Name"
                      variant="outlined"
                      value={formData.name}
                      onChange={(e) =>
                        handleInputChange("name", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      style={{ width: '200px' }}
                      name="type"
                      variant="outlined"
                      placeholder="File Type"
                      fullWidth
                      value={formData.type}
                      onChange={(e) =>
                        handleInputChange("type", e.target.value)
                      }
                    >
                      <MenuItem value="default" >select File type</MenuItem>
                      <MenuItem value="image" >Image (svg,png,jpeg)</MenuItem>
                      <MenuItem value="document">Document (csv, word)</MenuItem>
                      <MenuItem value="file">File (pdf)</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>

                    <Select
                      style={{ width: '200px' }}
                      name="docType"
                      variant="outlined"
                      placeholder="Doc Type"
                      fullWidth
                      value={formData.docType}
                      onChange={(e) =>
                        handleInputChange("docType", e.target.value)
                      }
                    >
                      <MenuItem value="Trips">Trips</MenuItem>
                      <MenuItem value="Location">Location</MenuItem>
                      <MenuItem value="Vehicle">Vehicle</MenuItem>
                      <MenuItem value="Driver">Driver</MenuItem>
                      <MenuItem value="Fuel">Fuel</MenuItem>
                      <MenuItem value="Roles">Roles & Permission</MenuItem>
                      <MenuItem value="Support">Support</MenuItem>

                    </Select>

                    {/* ............. */}

                  </TableCell>
                  <TableCell>
                    <TextField
                      type="file"
                      name="file"
                      variant="outlined"
                      onChange={(e) =>
                        handleInputChange("file", e.target.files[0])
                      }
                      inputProps={{ accept: acceptedFileTypes }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* Add a submit button if needed */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                className="btn-hover"
                sx={{
                  color: "white",
                  backgroundColor: "black",
                  textTransform: "none",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </Paper>
        {/* Form ends here */}
      </div>
    </div>
  );
};

export default AddNewDoc;
