import { showError ,showSuccess } from "../../toast";

// Conversion factors for different fuel measurement types to liters
const measurementTypeConversionFactors = {
  Litres: 1,
  gallons: 3.78541178, // 1 galon = 3.78541178 litres
  Barrel: 158.9873, // 1 barrel = 158.9873 liters
  CubicMeters: 1000, // 1 cubic meter = 1000 liters
  cubicfeet: 28.3168, // 1 cubic foot = 28.3168 liters
  Kiloliters: 1000, // 1 kiloliter = 1000 liters
  Megajoule: 141.585, // Assuming = 141.585 liters 
  watt: 0.001, // 1 Watt = 0.001 liter
};

// Calculate CO2eq emissions
export const calculateCO2eqEmissions = (distance, fuelEfficiency, fuelType, fuelMeasurementType) => {

  const emissionFactor = getEmissionFactor(fuelType, fuelMeasurementType);
  //console.log("emissionFactor", emissionFactor);
//  // debugger;
const fuelUsed = distance / fuelEfficiency; // calculate fuel used in liters

// Log the initial fuel used in liters
//console.log("Fuel Used (Liters):", fuelUsed); 
//console.log("fuelMeasurementType ():", fuelMeasurementType);
// alert(fuelMeasurementType)
const convertedFuelUsed = fuelUsed / measurementTypeConversionFactors[fuelMeasurementType?.trim()?.replaceAll(' ','')]; 
if(window.location?.pathname.includes('co2')){
  showSuccess(` Fuel Used : ${convertedFuelUsed}  in ${fuelMeasurementType}`);

}
// Log the converted fuel used in liters
//console.log("Converted Fuel Used (Liters):", convertedFuelUsed);
  return fuelUsed * emissionFactor;
};



const getEmissionFactor = (fuelType, fuelMeasurementType) => {
  const emissionFactors = {
    jetfuel: 9.428,
    aviationgasoline: 8.333,
    gasoline: 8.598734991,
    onroaddieselfuel: 10.131,
    residualfueloil: 11.125,
    lpg: 6.1,
    cng: 0.053,
    lng: 4.46,
    ethanol: 5.56,
    biodiesel100: 9.46,
    e85ethanolgasonline: 1.289810249,
    b20biodieseldiesel: 8.104417776,
    petrol: 2.31,
    diesel: 2.659,
    hydrogen:0.01,
  };

  // alert(`fuelType- ${fuelType}`)
  const factor = emissionFactors[fuelType?.toLowerCase()] || 0;
// alert(factor)
  if (factor === 0  && fuelType) {
    showError(`Unknown fuel type: ${fuelType}`);
    return 0;
  }

  const conversionFactor = measurementTypeConversionFactors[fuelMeasurementType?.toLowerCase()] || 1;

  return factor / conversionFactor;
};
