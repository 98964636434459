import React, { useEffect, useState } from "react";
import { Typography, Paper, IconButton } from "@mui/material";
// import ConstructionIcon from "@mui/icons-material/Construction";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/system";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axios from "axios";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Link } from "react-router-dom";


const BackButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
});

const Billing = () => {
    // Add logic to navigate back to the previous page
    // window.history.back();
    const [data, setData] = useState([]);
    const containerStyle = {
      display: "flex",
    };

  
    const tableStyle = {
      width: "100%",margin:'10px'
    };

    const headerstyle = {
      width: "100%",
    };

    const getIndustryData = async () => {
      try {
        await axiosInstance.get("/Billing?sort=-createdAt")
          .then((res) => {
            console.log("dss",res.data.data)
            const sortedData = res.data.data;

            setData(sortedData);
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    };

    


    useEffect(()=>{
        getIndustryData()
    },[])
    
  
  return (
    <div style={containerStyle}>
      <div >
        <Sidebar />
      </div>

      <div style={tableStyle} className="trip_page">
        <div style={headerstyle}>
          <Header />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "",
            justifyContent: "space-between",
          }}
        >
         <div className="table-heading">Billing</div>
        
        </div>
        <br/>
        {/* <TripTable data={data} updateTripStatus={updateTripStatus} /> */}
        <TableComponent addLink="AddNewBill" pagename="Billing"   page="Billing" data={data} col_class="overflow"  exclude={['_id', '__v','vendor','quantity','industryProcess','industry','docs','createdAt','updatedAt','user']} edit={`/AddNewBill?action=update&search=`} deletelink={`/AddNewBill?action=delete&search=`}  />
      </div>
    </div>
  );
};

export default Billing;
