import React, { useEffect, useState } from "react";
import { Typography, Paper, IconButton } from "@mui/material";
// import ConstructionIcon from "@mui/icons-material/Construction";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/system";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axios from "axios";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Link } from "react-router-dom";
import notfound from "../../utils/notfound.png"

const BackButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
});

const PurchaseAndGoods = () => {
  // Add logic to navigate back to the previous page
  // window.history.back();
  const [data, setData] = useState([]);

  const containerStyle = {
    display: "flex",
  };


  const tableStyle = {
    width: "100%", margin: '10px'
  };

  const headerstyle = {
    width: "100%",
  };

  const getTripData = async () => {
    try {
      await axiosInstance.get("/Goods?sort=-createdAt")
        .then((res) => {

          const sortedData = res.data;

          setData(sortedData);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };






  useEffect(() => {
    getTripData()

  }, [])


  return (
    <div style={containerStyle}>
      <div >
        <Sidebar />
      </div>

      <div style={tableStyle} className="trip_page">
        <div style={headerstyle}>
          <Header />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading"> Purchased Goods & Services</div>

        </div>
        <br />
        {/* <TripTable data={data} updateTripStatus={updateTripStatus} /> */}


        {data.length > 0 ?
          <TableComponent addLink="AddNewpurchaseGoods" pagename="Goods" page="Goods" data={data} col_class="overflow" exclude={['_id', '__v', 'vendor', 'quantity', 'industryProcess', 'industry', 'docs', 'createdBy', 'createdAt', 'updatedAt','Company','Currency','FuelType','VehicleType','destination','distance','docs','industryProcess','source','amount']} edit={`/AddNewpurchaseGoods?action=update&search=`} deletelink={`/AddNewpurchaseGoods?action=delete&search=`} />
          :
          <>

<div class="wrapper">
           <div>
             <div class="img">
               <img src={notfound} />
             </div>
             <br />
             <h6>No Purchase Goods / Service Found </h6>
             <Link to="/AddNewpurchaseGoods" >
                <button className=" start_creating">START CREATING {window.location.pathname?.slice(1)}</button>

              </Link>
           </div>
         </div>

          

          </>

        }


      </div>
    </div>
  );
};

export default PurchaseAndGoods;
