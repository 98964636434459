import React, { useEffect, useState } from 'react'
import { Typography, Paper, IconButton } from "@mui/material";
import Sidebar from '../../Components/Sidebar/Sidebar'
import axiosInstance from "../../Components/utils/axios"
import Header from '../../Components/Header/Header'
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Link } from "react-router-dom";
import NotFound from "../NotFound";
import { styled } from "@mui/system";
import notfound from "../../utils/notfound.png"

const BackButton = styled(IconButton)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
});


const Invoices = () => {
    const [data, setData] = useState([]);
    const containerStyle = {
        display: "flex",
    };

    const tableStyle = {
        width: "100%", margin: '10px'
    };

    const headerstyle = {
        width: "100%",
    };


    const startDate = new Date()
    const endDate = new Date()
    const previousMonthDate = new Date(startDate);
    previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
    console.log("previousMonthDate", previousMonthDate);

    const facility = "All"

    const fetchInvoices = async (req, res) => {
        try {
            const res = await axiosInstance.get(`/getMonthlyInvoice?startDate=${previousMonthDate.toISOString()}&endDate=${endDate.toISOString()}&facilityId=${facility}`)
            console.log('res', res?.data)
            const sortedData = res.data;
            setData(sortedData)

        } catch (error) {
            console.log("Error", error)
        }
    }

    useEffect(() => {
        fetchInvoices()
    },[])

    return (
        <div style={containerStyle}>
            <div >
                <Sidebar />
            </div>
            <div style={tableStyle} className="trip_page">
                <div style={headerstyle}>
                    <Header />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="table-heading">Invoices</div>

                </div>
                <br />
                {/* <TripTable data={data} updateTripStatus={updateTripStatus} /> */}
                {data.length > 0 ?
                    <TableComponent pagename="Invoices" page="Industry" data={data} col_class="overflow" exclude={['_id', '__v', 'vendor', 'quantity', 'industryProcess', 'industry', 'docs', 'createdAt', 'updatedAt', 'createdBy']}  />
                    :
                    <>
                        <div class="wrapper">
                            <div>
                                <div class="img">
                                    <img src={notfound} />
                                </div>
                                <br />
                                <h6>NO Invoices Found </h6>
                                <br />
                                {/* <Link to="/AddNewindustry" >
                                    <button className=" start_creating">START CREATING INDUSTRY</button>
                                </Link> */}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Invoices
