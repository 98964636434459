import React, { useEffect, useState } from "react";
import { Typography, Paper, IconButton, CircularProgress } from "@mui/material";
// import ConstructionIcon from "@mui/icons-material/Construction";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/system";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import TripTable from "./TripTable";
import axios from "axios";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Link } from "react-router-dom";
import notfound from "../../utils/notfound.png"


const spinAnimation = (theme) => ({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(-360deg)",
    },
  },
  padding: "2rem",
  textAlign: "center",
  position: "relative",
  transition: "transform 0.3s ease-in-out",
  animation: "$spin 2s linear infinite", // Apply the spin animation
  "&:hover": {
    transform: "scale(1.05)",
  },
});

const AnimatedPaper = styled(Paper)(spinAnimation);

const BackButton = styled(IconButton)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
});

const Trip = () => {
  // Add logic to navigate back to the previous page
  // window.history.back();
  const [data, setData] = useState([]);
  const containerStyle = {
    display: "flex",
  };


  const tableStyle = {
    width: "100%", margin: '10px'
  };

  const headerstyle = {
    width: "100%",
  };

  const getTripData = async () => {
    try {
      await axiosInstance.get("/trips")
        .then((res) => {

          const sortedData = res.data.reverse().sort((a, b) => new Date(b.Date) - new Date(a.Date));

          setData(sortedData);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };



  const updateTripStatus = async (tripID, newStatus) => {
    try {
      // alert(`${tripID} - ${newStatus}`)
      // await axios.put(
      //   `https://nirantara-admin-api.applore.in/api/updateTripsStatus/${tripID}/status`,
      //   { status: newStatus }
      // );

      await axiosInstance.put(
        `/updateTripsStatus/${tripID}/status`,
        { status: newStatus }
      );
      getTripData()

      showSuccess('Trip status updated successfully!');
      // getTripData()
    } catch (error) {
      console.error('Error updating trip status:', error);
      showError('Error updating trip status. Please try again.');
    }
  };

  useEffect(() => {
    getTripData()
  }, [])


  return (
    <div style={containerStyle}>
      <div >
        <Sidebar />
      </div>

      <div style={tableStyle} className="trip_page">
        <div style={headerstyle}>
          <Header />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading"> Trip Master</div>
          

        </div>


        {/* <TripTable data={data} updateTripStatus={updateTripStatus} /> */}
        {
          data.length > 0 ?
            <TableComponent addLink="addnewtrip" pagename="Trips" updateTripStatus={updateTripStatus} page="trips" data={data} col_class="overflow" exclude={['_id', '__v', 'Date', 'DistanceTravelled', 'selectDriver', 'stopTime', 'tripstartdate', 'tripenddate', 'StartOdoReading', 'EndOdoReading', 'vehicleCapacity', 'vehicleEffeciency', 'fuelType', 'fuelName', 'vehicleID', 'tripDocs', 'vehicleDestination', 'vehicleSource', 'isPaused', 'pauseReason', 'stopDuration', 'stopStartTime', 'tripStartedAt', 'StartOdoImage', 'EndOdoImage', 'TripDelayReason', 'scanningProblem', 'CurrentLocation', 'vendor', 'VendorName', 'destinationName', 'sourceAddress', 'driverName', 'vendorName', 'vehicleName','DriverID','Company','user']} edit={`/AddNewTrip?action=update&search=`} deletelink={`/AddNewTrip?action=delete&search=`} ONLYDATA={JSON.parse(localStorage.getItem('_USER')).role.roleName ==="ADMIN"} />
            :
            <>
              {(

                <div class="wrapper">
                  <div>
                    <div class="img">
                      <img src={notfound} />
                    </div>
                    <br />
                    <h6>NO Trips Found </h6>
                    <br />
                    {JSON.parse(localStorage.getItem('_USER')).role.roleName !=="ADMIN" &&
                    <Link to="/addnewtrip" >
                    <button className="start_creating">START CREATING Trips</button>
                 </Link>}
                  </div>
                </div>

                
              )}
              {/* {data.length === 0 && (
                <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                  <CircularProgress />
                </div>
              )} */}
            </>
        }

      </div>
    </div>
  );
};

export default Trip;
