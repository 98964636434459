import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar"; // Assuming the correct path to your Sidebar component
import VehicleMasterTable from "./VehicleMasterTable";
import Header from "../../Components/Header/Header";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { showSuccess } from "../../toast";
import TableComponent from "../../Components/CustomTable/CustomTable";
import axiosInstance from "../../Components/utils/axios"
import { Typography } from "@mui/material";
import notfound from "../../utils/notfound.png"

const LocationMaster = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get("search") || "";

  const [data, setData] = useState([]);
  const containerStyle = {
    display: "flex",
  };

  const tableStyle = {
    width: "100%",
    margin: "10px",
  };


  useEffect(() => {
    axiosInstance.get("/vehicle")
      .then((response) => {
        if (searchValue === "") {
          setData(response.data.data);
        } else {
          const filteredData = response.data.data.filter(
            (veh) => veh.vehicleType === searchValue
          );
          setData(filteredData?.reverse());
          if (filteredData.length == 0) {
            showSuccess(`No Data found of ${searchValue} vehicle type`);
          }
        }
        console.log("data set");
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, [searchValue]);

  console.log(data);

  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>

      <div style={tableStyle}>
        <div className="Site_Header">
          <Header />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading">Vehicle Master</div>

        </div>
        <div>
          {/* <VehicleMasterTable data={data} /> */}


          {data.length > 0 ?
            <TableComponent addLink="AddNewVehicleMaster" pagename="Vehicle" page="VehicleMaster" data={data}
              exclude={['_id', '__v', 'vehicleMake', 'vehicleModel', 'vehicleYear', 'lastService', 'vehiclePOC', 'vehicleCapacity', 'distancemeasure', 'vehicleCondition', 'pucFile', 'rcFile', 'polutionFile', 'insuranceFile', 'fuelType', 'vehicleCategory', 'vehicle', 'TransportType','fuelname','LicenseNumber','Company']} edit={`/AddNewVehicleMaster?action=update&search=`} deletelink={`/AddNewVehicleMaster?action=delete&search=`} ONLYDATA={JSON.parse(localStorage.getItem('_USER')).role.roleName ==="ADMIN"} />

            :
            <>


<div class="wrapper">
           <div>
             <div class="img">
               <img src={notfound} />
             </div>
             <br />
             <h6>NO Vehicle Found</h6>
             <br />
             {JSON.parse(localStorage.getItem('_USER')).role.roleName !=="ADMIN" &&
              <Link to="/AddNewVehicleMaster" >
              <button className=" start_creating">START CREATING {window.location.pathname?.slice(1)}</button>

            </Link>
             }
               
           </div>
         </div>


            </>
          }
        </div>
      </div>
    </div>
  );
};

export default LocationMaster;
