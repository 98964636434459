const FuelMasterConfig = async () => {
    try {
        const measurementOptions = {
            petrol: ["Litres", "Gallon"],
            diesel: ["Litres", "Gallon"],
            hydrogen: ["Kilograms", "Cubic Meters"],
            electric: ["Kilowatt-hours", "watt"],
            cng: ["Cubic Feet", "Cubic Meters"],
        };
        const fuelTypes = [
            { key: 'petrol', value: 'Petrol' },
            { key: 'diesel', value: 'Diesel' },
            { key: 'hydrogen', value: 'Hydrogen' },
            { key: 'electric', value: 'Electric' },
            { key: 'cng', value: 'CNG' },
        ];


        const selectedMeasurementOptions = measurementOptions['electric'] || [];

        const measurementOptionsKeyValue = selectedMeasurementOptions.map((option) => ({
            key: option,
            value: option,
        }));

        return {
            _id: { type: 'none', disabled: true },
            __v: { type: 'none', disabled: true },
            fuelID: { type: 'text', disabled: true, label: 'Fuel ID' },
            fuelType: { type: 'select', label: 'Fuel Type', required: true, options: fuelTypes, onChange: (iem)=>{
                // alert(iem)
            } },
            Measurement: { type: 'select', label: 'Fuel Measurement', required: true, options: measurementOptionsKeyValue },
        };
    } catch (error) {
        console.error('Error fetching Fuel config:', error);
        return {};
    }
};

export default FuelMasterConfig;
