import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar"; // Assuming the correct path to your Table component
import FuelTable from "./FuelTable";
import Header from "../../Components/Header/Header";
import axios from "axios";
import axiosInstance from "../../Components/utils/axios"
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

const FuelMaster = () => {
  const [data, setData] = useState([]);
  const containerStyle = {
    display: "flex",
  };

  const tableStyle = {
    width: "100%",
    margin: "10px",
  };

  const get_data = async () => {
    await axiosInstance.get("/fuels")
      .then((response) => {
        console.log("fueldata", response);
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };
  useEffect(() => {
    get_data();
  }, []);

  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={tableStyle}>
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading">Fuel Master</div>
        
        </div>
        {/* <FuelTable data={data} /> */}
        <TableComponent addLink="AddNewFuelType" pagename="Fuel" page="fuelmaster" data={data}  exclude={[ '_id','__v','fuelDoc']} edit={`/AddNewFuelType?action=update&search=`} deletelink={`/AddNewFuelType?action=delete&search=`} />


      </div>
    </div>
  );
};

export default FuelMaster;
