import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import { showSuccess, showError } from '../../toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IsModifiedModal from "../../Components/IsModified";
import DatePicker from "react-datepicker";
import { enIN } from 'date-fns/locale'


import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";



// import saveFormData from "./saveFormData";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import CountrySelect from "../../Components/utils/CountryCode";




const AddNewIndustry = () => {
    // const history = useHistory();
    const Navigate = useNavigate();
    const [address, setAddress] = useState("");




    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get("action") || "";
    const selectedid = searchParams.get("search") || "";

    const [formModified, setFormModified] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const generateRandom4DigitNumber = () => {
    //     return `CUST${Math.floor(Math.random() * 900) + 101}`;
    // };

    const USER = JSON.parse(localStorage.getItem('_USER'));

    const [IndustryData, setIndustryData] = useState({
        IndustryID: "",
        Industry: USER ? USER.Company.Industry :"",
        IndustryProcess: "",
        docs: '',
        createdBy: localStorage.getItem("user_id")

    });

    const generateRandom4DigitNumber = async () => {
        try {
            const response = await axiosInstance.get("/industry");
            // // debugger
            const sortedData = response.data

            const latestIndustry = sortedData[0];
            console.log("latestIndustry", latestIndustry)
            let id = 1;
            if (latestIndustry) {
                const latestID = latestIndustry.IndustryID;
                id = parseInt(latestID.substring(3)) + 1;
            }
            
            return `IND${id}`;
        } catch (error) {
            console.error("Error fetching latest industry ID:", error);
            return `IND0`;
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            const randomID = await generateRandom4DigitNumber();
            setIndustryData((prev) => ({
                ...prev,
                IndustryID: randomID 
            }));
        };

        fetchData();
    }, []);


    useEffect(() => {
        if (selectedid) {
            axiosInstance.get(`/industry/${selectedid}`).then((res) => {
                console.log("selectedID", res.data)
                const selectedgoods = res.data.data
                console.log("selectedVendor", selectedgoods)
                if (selectedgoods) {
                    setIndustryData(selectedgoods);

                }
            })
        }
    }, [selectedid])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormModified(true)
        setIndustryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {


        return true;
    };

    const makeApiCall = async (method, url, data, callback) => {
        try {
            // // debugger;
            console.log("INDUSTRYdata",data)
            const response = await axiosInstance[method.toLowerCase()](url, {
                ...data
            });

            console.log("res", response)

            if (response.data.success === false) {
                showError(response?.data?.message)
            }
            else {
                showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
                callback()
                return response.data;
            }

        } catch (error) {

            console.error("Error making API call:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    };

    const handleClickSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm();
        // // debugger
        console.log(IndustryData)
        const newData = {

        }
        // console.log("customerData", customerData);
        console.log("newdata", newData);
        if (isValid) {
            try {
                const responseData = await makeApiCall(
                    `${action === "" ? 'POST' : action === 'update' ? 'PUT' : 'DELETE'}`,
                    `/${action === "" ? `industry` : action === 'update' ? `industry/${selectedid}` : `industry/${selectedid}`}`,
                    IndustryData, () => { Navigate('/industry') }
                );
                console.log(responseData);
            } catch (error) {

                console.error("Error submitting form:", error);
            }
        }

    };

    const containerStyle = {
        display: "flex",
    };

    const formStyle = {
        height: '80vh',
        overflow: 'scroll',
        padding: "10px",
        borderRadius: "10px",
        margin: "none",
        marginTop: '20px',
        backgroundColor: "white",
    };

    const uploadFile = async (file, name) => {
        try {
            console.log(file)
            const formData = new FormData();
            formData.append('pucFile', file);

            const response = await axiosInstance.post(
                "/uploadPUCFile",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            console.log("uploads", response)
            setFormModified(true)
            return response?.data?.imageurl?.Location;
        } catch (error) {
            console.log(error);

            return null;
        }
    };

    const handleDocsUpload = async (value, name) => {
        // alert(`${value} - ${name}`)
        // // debugger;
        console.log(value)
        const fileUrl = await uploadFile(value, name);
        console.log(name)
        setIndustryData((prevValues) => ({
            ...prevValues,
            [name]: fileUrl,
        }));


    }



    const handleModalClose = (result) => {
        setIsModalOpen(false);
        if (!result) {
            Navigate('/Industry')
        }
    };

    const handleBackbutton = () => {
        // alert(formModified)
        if (formModified) {
            setIsModalOpen(true)
        }
        else {
            Navigate('/Industry')
        }
    }



    return (
        <div style={containerStyle}>
            <div>
                <Sidebar />
            </div>
            <div style={{ width: "100%", margin: '10px' }}>
                <Header />
                <div className="table-heading theme_c"><span onClick={() => handleBackbutton()}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New'} Industry   <span className={action ? `_${action}` : '_edit'}>{`  ${IndustryData.IndustryProcess || ''}`}</span></div>
                <div className="hidden_scrollbar" style={formStyle}>
                    <form className="fuelform">
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <label htmlFor="col2">Process ID <span className="_MANDATORY">*</span></label>
                                <TextField
                                    variant="outlined"
                                    disabled
                                    fullWidth
                                    id="col2"
                                    value={IndustryData.IndustryID}
                                    name="IndustryID"
                                    onChange={handleInputChange}
                                />
                            </Grid>


                            <Grid item xs={4}>
                                <label>Industry <span className="_MANDATORY"> *</span></label>
                                <Select
                                    fullWidth
                                    style={{ height: '38px' }}
                                    value={IndustryData.Industry}
                                    onChange={(e) => setIndustryData({ ...IndustryData, Industry: e.target.value })}
                                    required
                                    disabled={action === 'view'}
                                    name="Industry"
                                >
                                    <MenuItem value="Industry 1">Industry 1</MenuItem>
                                    <MenuItem value="Industry 2">Industry 2</MenuItem>
                                    <MenuItem value="Industry 3">Industry 3</MenuItem>
                                    <MenuItem value="Industry 4">Industry 4</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={4}>
                                <label htmlFor="col2">Industry Process <span className="_MANDATORY">*</span></label>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    id="col2"
                                    value={IndustryData.IndustryProcess}
                                    name="IndustryProcess"
                                    onChange={handleInputChange}
                                    disabled={action === 'view'}
                                />
                            </Grid>

                            <Grid item xs={12} className="mt-3">
                                <label htmlFor="col8">Upload</label>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: "35px",
                                        border: "2px dotted lightgrey",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        borderRadius: "12px",
                                    }}
                                >
                                    {
                                        !IndustryData.docs &&
                                        <label>
                                            Drag and Drop or{" "}
                                            <Button
                                                sx={{
                                                    backgroundColor: "grey",
                                                    color: "white",
                                                    opacity: 0.8,
                                                    fontSize: "10px",
                                                    fontWeight: "300",
                                                }}
                                                onClick={() =>
                                                    document.querySelector('input[type="file"]').click()
                                                }
                                                disabled={action === 'view'}
                                            >
                                                Browse
                                            </Button>
                                            <br />
                                            <span className="f-8 ">Acepted File types ( png , jpeg ,docx  )</span>

                                        </label>
                                    }

                                    <input
                                        type="file"
                                        name="docs"
                                        onChange={(e) =>
                                            handleDocsUpload(e.target.files[0], 'docs')
                                        }
                                        accept="image/*"
                                        style={{ display: "none" }}
                                    />
                                    <div>
                                        {IndustryData.docs ? (
                                            <img
                                                src={IndustryData.docs}
                                                alt="Uploaded"
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <button
                                    className="submit_btn"
                                    style={{
                                        color: "white",
                                        marginTop: '20px',
                                        backgroundColor: "black",
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                    }}
                                    onClick={handleClickSubmit}
                                    disabled={action === 'view'}
                                >
                                    {action ? action.toUpperCase() : 'Submit'}
                                </button>
                            </Grid>
                        </Grid>

                        {isModalOpen &&
                            <IsModifiedModal
                                open={isModalOpen}
                                onClose={handleModalClose}
                                description="Do you want to save the data before going back?"
                            />
                        }
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddNewIndustry;
