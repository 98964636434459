import axios from "axios";
import { showError, showSuccess } from "../../toast";

import axiosInstance from "../../Components/utils/axios";



const SaveFormData = async (formData, type ,callback) => {
  
  
  try {

    let url;

    // // debugger;
    if (type === "update") {
      
      url = `/location/${formData.locationID}`;
    } else if (type === "delete") {

      url = `/location/${formData.locationID}`;
    } else {
      
      url = "/location";
    }

    // const response = await axios({
    //   method: type === "update" ? "PUT" : type === "delete" ? "DELETE" : "POST",
    //   url,
    //   data: formData,
    // });

    const methodName = type === "update" ? "put" : type === "delete" ? "delete" : "POST".toLowerCase();
    console.log("Method name:", methodName); 
    const response = await axiosInstance[methodName](url, {
      ...formData
    });

     console.log("location res",response)
    if (response.data.success == false) {
 
    showError(response?.data?.message)
    
    } else {
      showSuccess(`${type == 'update' ? 'Update':type == 'delete'?'Deleted' : 'Saved'} successfully`);
    callback()
    }
  } catch (error) {
    console.error("Error performing operation:", error);
  }
};

export default SaveFormData;
