import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DynamicForm from '../../Components/CustomTable/DynamicForm';
import vehicleMasterConfig from "../MasterForms/config/VehicleConfig"
import driverMasterConfig from './config/DriverConfig';
import FuelMasterConfig from './config/FuelConfig';
import LocationMasterConfig from './config/LocationConfig';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadIcon from '@mui/icons-material/Download';
import { Link, Navigate } from 'react-router-dom';
import { calculateCO2eqEmissions } from '../utils/Co2e';
import { fetchFuelData } from '../DataFunctions/Apidata';
import { showError } from '../../toast';
const DetailsForm = ({ data, onSubmit, page, edit, deletelink, updateTripStatus, onlyDocs }) => {
  const [editMode, setEditMode] = useState(false);
  const [MasterConfig, setMasterConfig] = useState()
  const [Fueldata, setFueldata] = useState([]);
  const configMap = {
    VehicleMaster: vehicleMasterConfig,
    DriverMaster: driverMasterConfig,
    fuelmaster: FuelMasterConfig,
    locationmaster: LocationMasterConfig,
  };


  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#dfedd1",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const getFuelData = async () => {
    const a = await fetchFuelData();
    setFueldata(a)
  }

  useEffect(() => {
    console.log("--->", data);
    getFuelData()
  }, [data]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSave = (updatedData) => {
    // Handle save logic here if needed
    console.log('Updated Data:', updatedData);
    setEditMode(false);
    onSubmit(updatedData);
  };


  const handleEditFunction = (data) => {

    edit()
  }

  const handledeleteFunction = (data) => {

    deletelink()
  }

  // trips page -----> status key
  const handleUpdateStatus = (tripid, value) => {

    // alert(`${tripid} - ${event}`)
    updateTripStatus(value, tripid)
  }


  function formatKey(key) {
    let formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
    return formattedKey;
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = date.getMonth();
    const monthAbbreviation = monthArray[monthIndex];
    const year = date.getFullYear();

    // Format the date as "day month year"
    const formattedDate = `${day} ${monthAbbreviation} ${year}`;

    return formattedDate;
  }

  const handleCellClick = (key, value, event) => {
    event.preventDefault();

    if (typeof value === 'object') {
      const clickX = event.clientX;
      const clickY = event.clientY;

      const tooltip = document.createElement('div');
      tooltip.className = 'custom-tooltip';
      tooltip.style.top = `${clickY}px`;
      tooltip.style.left = `${clickX}px`;

      const formattedValue = JSON.stringify(value, null, 2);
      const filteredData = Object.entries(JSON.parse(formattedValue)).filter(([key]) => !['_id', '__v', 'password'].includes(key));

      const divContainer = document.createElement('div');
      filteredData.forEach(([key, value]) => {
        const divElement = document.createElement('div');
        divElement.className = 'df';
        divElement.style.gap = '20px';
        divElement.style.margin = '10px';
        divElement.innerHTML = `
              <span class='_key'>${key}:</span>
              <span class='_value'>${value}</span>
          `;
        divContainer.appendChild(divElement);
      });

      const preElement = document.createElement('pre');
      preElement.className = 'GREEN_BORDER';
      preElement.appendChild(divContainer);

      tooltip.appendChild(preElement);

      document.body.appendChild(tooltip);

      setTimeout(() => {
        tooltip.classList.add('show-tooltip');
      }, 10);

      setTimeout(() => {
        if (!tooltip.classList.contains('hovered')) {
          removeTooltip();
        }
      }, 2000);

      tooltip.addEventListener('mouseenter', () => {
        tooltip.classList.add('hovered');
        clearTimeout(removeTimer);
      });

      tooltip.addEventListener('mouseleave', () => {
        tooltip.classList.remove('hovered');
        removeTooltip();
      });

      const removeTooltip = () => {
        tooltip.classList.remove('show-tooltip');
        setTimeout(() => {
          if (!tooltip.classList.contains('hovered')) {
            tooltip.style.display = 'none';
          }
        }, 300);
      };

      const removeTimer = setTimeout(() => {
        removeTooltip();
      }, 2000);
    }

    console.log("value", value);


  };

  const renderContent = (key, value) => {
    switch (key) {
      case 'Driver':

        return value?.DriverName || value?._id;
      case 'Vehicle':

        return value?.vehicleNumber || value?._id;
      case 'Source':

        return value?.address || value?._id;

      case 'Destination':

        return value?.locationName || value?._id;
      case 'user':
      case 'createdBy':

        return value?.name || value?._id;
      case 'role':
      case 'Role':

        return value?.roleName || value?._id;
      case 'Company':
      case 'Company':

        return value?.Company || value?._id;

      case 'vendor':


        return value?.name || value?._id;
      default:
        return value._id;
    }
  };



  const getFuel = (fuelID, type) => {

    const fuel = Fueldata.find((fuel) => fuel.fuelID === fuelID);
    if (type == "type") {

      return fuel?.fuelType
    }
    else {
      return fuel?.Measurement
    }
  }

  //  ENTITY

  const imageEntries = [];

  const keysToExclude = ['_id', '__v', 'password', 'vehicleDestination', 'vehicleID', 'expanded', 'vehicleSource'];

  const regularEntries = Object.entries(data)
    .filter(([key]) => !keysToExclude.includes(key))
    .map(([key, value], index) => {
      if (value === null || value === undefined) {
        return null;
      }
      if (typeof value === 'object') {
        return (
          <div key={key} className="details-row">
            <span className="details-key"   >{formatKey(key)}</span>
            <span style={{ cursor: 'pointer' }} onClick={(event) => handleCellClick(key, value, event)} className={`details-value ${key}_bg bg-btn_${index}`} >👁️{renderContent(key, value)} </span>
          </div>
        );
      }


      if (key === 'vehicleCondition' && page === 'VehicleMaster') {

        return (
          <>
            <span className="details-key" style={{ textTransform: 'capitalize', letterSpacing: '0.5px' }}>{formatKey(key)}</span>
            <FormControlLabel
              style={{ transform: 'scale(0.8)', fontWeight: '700', marginLeft: '-30px' }}
              className=''
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={value}
                  defaultChecked={value}
                  onChange={(e) => (!value)}

                />
              }
              label={value === true ? 'New Vehicle' : 'Old Vehicle'}
              variant="outlined"
            />
          </>
        );
      }

      if (key === 'status' && page === 'trips') {
        return (
          <>
            <div key={key} className="details-row">
              <span className="details-key" style={{ textTransform: 'capitalize', letterSpacing: '0.5px' }}>{key}</span>
              <select
                style={{ fontSize: '10px !important' }}
                className={`table-select _${value}  details_select`}
                value={value}
                onChange={(event) => handleUpdateStatus(event.target.value, data._id)}
              >
                <option value="Pending">Yet to Start</option>
                <option value="Progress">Progress</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Completed">Completed</option>

              </select>
            </div>
            <div key={key} className="details-row">
              <span className="details-key" style={{ textTransform: 'capitalize', letterSpacing: '0.5px' }}>Emission </span>
              <span className=' _edit'>
                {calculateCO2eqEmissions(
                  data?.DistanceTravelled || 0,
                  data?.vehicleEffeciency,
                  getFuel(data.fuelType, 'type'),
                  getFuel(data.fuelType, 'measurement')
                )?.toFixed(5)} KgCO₂e

              </span>
            </div>

          </>
        );
      }

      else if (typeof value === 'string' && value.includes('.pdf')) {
        imageEntries.push(
          <div key={key} className="">
            <span className="details-key">{key}:</span> <br />
            <p className='docs-viewer-item'><DownloadDoneIcon /> {` Download ${value?.split("/").pop()}`}  <a target='_blank' href={value}><DownloadIcon /></a></p>
          </div>
        );
        return null;
      }
      else if (typeof value === 'string' && /\.(png|jpeg|jpg)$/i.test(value)) {
        imageEntries.push(
          <div key={key} className="docs-viewer-item">
            <span className="details-key">{formatKey(key)}:</span>
            <img src={value} alt={`${key} Document`} />
          </div>
        );
        return null;
      }
      else if (typeof value === 'string' && (/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})\.(\d{3})Z$/.test(value) || /^\d{2}\/\d{2}\/\d{4}$/.test(value))) {

        return (
          <div key={key} className="details-row">
            <span className="details-key" style={{ textTransform: 'capitalize', letterSpacing: '0.5px' }}>{key}</span>
            <span onClick={(event) => handleCellClick(key, value, event)} className={`details-value ${key}_bg bg-btn_${index}`} title={value}>{formatDate(value)}</span>
          </div>
        );
      }

      return (
        <div key={key} className="details-row">
          <span className="details-key"   >{formatKey(key)}</span>
          <span style={{ cursor: 'pointer' }} onClick={(event) => handleCellClick(key, value, event)} className={`details-value ${key}_bg bg-btn_${index}`} >{value === null || value === "" || value === undefined ? 'Not Assiged ' : value.toString()} </span>
        </div>
      );
    })
    .filter(entry => entry !== null);

  return (
    <div className="DriverDetailsForm">
      {editMode ? (
        <DynamicForm page={page} formData={data} mode="edit" onSubmit={handleSave} masterPage={configMap[page]} />
      ) : (
        <>
          {

            <div className="details-card">
              {!onlyDocs && regularEntries}
              {!onlyDocs &&
                <div className="details-actions">
                  {
                    typeof edit === 'function' ?
                      <button className="_edit edit-button" onClick={() => handleEditFunction(data)} >
                        <EditIcon /> Edit
                      </button>
                      :
                      <Link to={edit + `${data._id}`}>
                        <button className=" _edit edit-button" >
                          <EditIcon /> Edit
                        </button>
                      </Link>
                  }

                  {/* <button className="edit-button" onClick={handleEditClick}>
              Edit
            </button> */}

                  {
                    typeof deletelink === 'function' ?
                      <button className="_delete delete-button" onClick={() => handledeleteFunction(data)} >
                        <DeleteForeverIcon /> Delete
                      </button>
                      :
                      <Link to={deletelink + `${data._id}`}>
                        <button className=" _delete delete-button" >
                          <DeleteForeverIcon /> Delete
                        </button>
                      </Link>
                  }




                </div>
              }

            </div>
          }

          <div className="Docs_Viewer">
            {imageEntries}
          </div>
        </>
      )}
      {editMode && (
        <button className="cancel-button" onClick={handleCancelEdit}>
          ⬅️
        </button>
      )}
    </div>
  );
};

export default DetailsForm;
