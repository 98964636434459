import React from 'react'
import "../Pages/Notfound.css"
import Header from '../Components/Header/Header';
import Sidebar from '../Components/Sidebar/Sidebar';
import notfound from "../utils/notfound.png"

function NotFound() {

  const tableStyle = {
    width: "100%",
    margin: "10px",
  };

  const containerStyle = {
    display: "flex",
  };



  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={tableStyle}>
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading">{window?.location?.pathname?.slice(1) == 'unauthorized' ? 'unauthorized' : 'NOT FOUND'} </div> </div>

        {window?.location?.pathname?.slice(1) == 'unauthorized' ?
         <>
         <div class="wrapper">
           <div>
             <div class="img">
               <img src={notfound} />
             </div>
             <br />
             <h6>unauthorized</h6>
             <br />
             <span>Whoops.... this information is Private</span>

           </div>
         </div>
       </>
          :
          <>
            <div class="wrapper">
              <div>
                <div class="img">
                  <img src={notfound} />
                </div>
                <br />
                <h6>Result Not Found</h6>
                <br />
                <span>Whoops.... this information is not available for a moment</span>

              </div>
            </div>
          </>
        }

      </div>
    </div>
  )

}

export default NotFound;