
const driverMasterConfig = async () => {
    try {
     
        return {
            _id: { type: 'none', disabled: true },
            __v: { type: 'none', disabled: true },
            DriverID: { type: 'text', disabled: true, label: 'Driver ID' },
            DriverName: { type: 'text', label: 'Driver Name', required: true },
            LicenseNumber: { type: 'text', label: 'License Number', required: true },
            PhoneNumber: { type: 'text', label: 'Phone Number', required: true },
        };
    } catch (error) {
        console.error('Error fetching Driver  data:', error);
        return {};
    }
};

export default driverMasterConfig;
