import React, { useEffect, useRef, useState } from 'react';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import axiosInstance from "../../../Components/utils/axios";
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import GetFuelConsuptiononTrips from './GetFuelConsuption';
import { fetchFuelData } from '../../../Components/DataFunctions/Apidata';
// import Bar from 'chart.js/auto';


function GetFuelConsuptionByVehicletype({facility}) {
    const [value, setvalue] = useState("vehicleType")
    const chartRef = useRef(null);

    const [Data, setData] = useState([])
    const [Fuels, setFuels] = useState(null)
    const [streamvalue, setstreamValue] = useState('All')



    const getVehData = async () => {
        try {
            await axiosInstance.get(`/getFuelEfficiencyComparison/${value}/${facility}/${streamvalue}`)
                .then((res) => {

                    const sortedData = res.data;
                     console.log("fuel consiption",sortedData)
                    setData(sortedData);
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };



    const GetFuel = (fuelID) => {
        const thisFuel = Fuels.filter((fuel) => fuel.fuelID === fuelID)
        return thisFuel[0]?.fuelType
    }


    function CustomTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
            return (
                <div className="_custom-tooltip">
                    <span className="total-emission">{`${value}:  ${value === "fuelType" ? GetFuel(payload[0].payload._id) : label}`}</span><br />
                    <span className="average-emission">{`Avg Eff: ${payload[0].payload.averageEfficiency}`}</span><br />
                    <span className="average-emission">{`No of Trips :${payload[0].payload.count}`}</span><br />
                </div>
            );
        }

        return null;
    }


    const getFuels = async () => {

        const fuels = await fetchFuelData()
        console.log({ fuels })
        setFuels(fuels)
        // return fuels
    }

    useEffect(() => { getFuels() }, [])

    useEffect(() => {

        getVehData()

    }, [value,facility,streamvalue])


    const handleStreamChange = (event) => {
        setstreamValue(event.target.value);
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Category'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Efficiency (L)'
                }
            }
        }
    };

    const chartData = {
        labels: Data.map(item => item._id),
        datasets: [
            {
                label: 'Total fuel Efficiency',
                data: Data.map(item => item.averageEfficiency),
                backgroundColor: '#1c486b'
            },
            {
                label: 'Average Fuel Efficiency',
                data: Data.map(item => item.count),
                backgroundColor: '#488257'
            }
        ]
    };

     console.log({chartData})

    return (
        <div className='Echart p-2'>
            <Grid container xs={12} className='' >
                <Grid item xs={8}>
                    <label className='_dash_heading' >Fuel Consumption (in L) <span className="_MANDATORY"> </span></label>

                </Grid>
                <Grid item xs={4}>
                    <Select
                        fullWidth
                      
                        value={value}
                        onChange={(e) => setvalue(e.target.value)}
                        required
                        name="ATTRIBUTES"
                    >
                         <MenuItem value="fuelType">Fuel Type</MenuItem>
                        <MenuItem value="TransportType">Transport Type</MenuItem>
                        <MenuItem value="vehicleCategory">Vehicle Category</MenuItem>
                        <MenuItem value="vehicleType">Vehicle Type</MenuItem>

                        {/* <MenuItem value="Company">Company</MenuItem> */}
                       

                    </Select>
                </Grid>
            </Grid>
            {/* <ResponsiveContainer  width="100%" height="100%"> */}
            <BarChart
                width={600}
                height={300}
                data={Data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_id" />
                <YAxis style={{ fontSize: '14px' }}
                    label={{ value: '', angle: -90, position: 'outsideLeft', dx: -10 }}
                    // ticks={[0, 20, 40, 60]}  // Define your ticks with units
                    tickFormatter={(value) => `${value} `}
                />
                <Tooltip  content={<CustomTooltip />} />
                <Legend />
                <Bar barSize={50} dataKey="averageEfficiency" name="Total fuel Efficiency" fill="#1c486b" />
                <Bar barSize={50} dataKey="count" name="Average Fuel Efficiency" fill="#488257" />
            </BarChart>


           {/* <div style={{height:'200px'}}>
           <Bar ref={chartRef} data={chartData} options={options} />
           </div> */}


            {/* </ResponsiveContainer> */}
            <div className='stream  '>
                <FormControl component="fieldset" style={{FlexDirection:'row'}} >
                    <RadioGroup value={streamvalue} onChange={handleStreamChange} className="df">
                        <FormControlLabel value="All" control={<Radio />} label="All" />
                        <FormControlLabel value="Upstream" control={<Radio />} label="Upstream" />
                        <FormControlLabel value="Downstream" control={<Radio />} label="Downstream" />
                    </RadioGroup>
                </FormControl>
            </div>

            {/* <GetFuelConsuptiononTrips /> */}
        </div>
    );
}

export default GetFuelConsuptionByVehicletype;
