import { fetchFuelData } from '../../DataFunctions/Apidata';

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 20 }, (_, index) => ({
  key: currentYear - index,
  value: currentYear - index,
}));
const vehicleMasterConfig = async () => {
  try {
    const fuelData = await fetchFuelData();
    const fuelTypeOptions = fuelData.map((fuel) => ({
      key: fuel.fuelID,
      value: fuel.fuelType,
    }));

    const VEHICLETYPES =  [
      { key: 'HCV', value: 'Heavy Commercial Vehicle (HCV)' },
      { key: 'LCV', value: 'Light Commercial Vehicle (LCV)' },
      { key: 'MCV', value: 'Manufacturing Commercial Vehicles (MCV)' },
      { key: 'SCV', value: 'Small Commercial Vehicle (SCV)' },
    ]
    const Distancemeasure =  [
      { key: 'Km', value: 'Kilo meters' },
      { key: 'Mile', value: 'Miles' },
     
    ]


    return {
      _id: { type: 'none', disabled: true },
      __v: { type: 'none', disabled: true },
      vehicleID: { type: 'text', disabled: true ,label: 'Vehicle Number', required: true },
      vehicleNumber: { type: 'number', label: 'Vehicle Number', required: true },
      vehicleType: { type: 'select', label: 'Vehicle Type',required: true,options: VEHICLETYPES},
      vehicleMake: { type: 'text', label: 'Make', required: true },
      vehicleModel: { type: 'text', label: 'Model', required: true },
      vehicleYear: { type: 'select', label: 'Year', required: true, options: years },
      lastService: { type: 'datepicker', label: 'Last Service', required: true },
      fuelType: { type: 'select', label: 'Fuel Type', required: true, options: fuelTypeOptions },
      vehicleCondition: { type: 'switch', label: 'Vehicle Condition', defaultChecked: true },
      vehiclePOC: { type: 'datepicker', label: 'PUC Date', required: false },
      pucFile: { type: 'file', label: 'PUC Docs', required: false },
      rcFile: { type: 'file', label: 'RC Docs', required: false },
      polutionFile: { type: 'file', label: 'Pollution Docs', required: false },
      insuranceFile: { type: 'file', label: 'Insurance Docs', required: false },
      vehicleEffeciency: { type: 'text', label: 'Fuel Efficiency', required: true },
      distancemeasure: { type: 'select', label: 'Distance Measure', required: true, options: Distancemeasure },
      vehicleCapacity: { type: 'text', label: 'Load Capacity (ton)', required: false },
      fuelname: { type: 'none', label: 'Load Capacity (ton)', required: false },
    };
  } catch (error) {
    console.error('Error fetching fuel data:', error);
    return {};
  }
};

export default vehicleMasterConfig;
