import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import axiosInstance from "../../../Components/utils/axios";
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { fetchFuelData } from '../../../Components/DataFunctions/Apidata';

function GetDistanceByVehAttribute({ facility }) {
    const [value, setValue] = useState("TransportType");
    const [data, setData] = useState([]);
    const [Fuels, setFuels] = useState(null)
    const [streamvalue, setstreamValue] = useState('All')


    const getVehData = async () => {
        try {
            const response = await axiosInstance.get(`/getDistanceByVehicleAttributes/${value}/${facility}/${streamvalue}`);
            console.log("a", response.data);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    const GetFuel = (fuelID) => {
        const thisFuel = Fuels.filter((fuel) => fuel.fuelID === fuelID)
        return thisFuel[0]?.fuelType
    }


    const getFuels = async () => {

        const fuels = await fetchFuelData()
        console.log({ fuels })
        setFuels(fuels)
        // return fuels
    }

    useEffect(() => { getFuels() }, [])

    useEffect(() => {
        getVehData();
    }, [value, facility,streamvalue]);

    function CustomTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
            return (
                <div className="_custom-tooltip">
                    <span className="label">{`${value}: ${value === "fuelType" ? GetFuel(label) : label}`}</span><br />
                    <span className="total-distance">{`Total Distance: ${payload[0].value} KM`}</span><br />
                    <span className="avg-distance">{`Average Distance: ${payload[1].value} KM`}</span><br />
                    <span className="avg-distance">{`No of Trips: ${payload[1].payload.tripCount} `}</span><br />
                </div>
            );
        }
        return null;
    }


    const handleStreamChange = (event) => {
        setstreamValue(event.target.value);
    };

    return (
        <div className='Echart p-2'>
            <Grid container item xs={12}>
                <Grid item xs={8}>
                    <label className='_dash_heading' > Distance Covered by (in Km) <span className="_MANDATORY"> </span></label>

                </Grid>
                <Grid item xs={4}>
                    <Select
                        fullWidth

                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        required
                        name="ATTRIBUTES"
                    >
                        <MenuItem value="fuelType">Fuel Type</MenuItem>
                        <MenuItem value="vehicleType">Vehicle Type</MenuItem>
                        <MenuItem value="vehicleCategory">Vehicle Category</MenuItem>
                        <MenuItem value="TransportType">Transport Type</MenuItem>

                        

                    </Select>
                </Grid>

            </Grid>
            
            {/* <ResponsiveContainer width="100%" height="100%"> */}
                <BarChart
                    width={600}
                    height={300}
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="_id" />
                    <YAxis style={{ fontSize: '14px' }}
                        label={{ value: '', angle: -90, position: 'outsideLeft', dx: -20 }}
                        // ticks={[0, 20, 40, 60]}  // Define your ticks with units
                        tickFormatter={(value) => `${value}`}
                    />                <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar barSize={50} dataKey="totalDistance" name="Total Distance" fill="#1c486b" />
                    <Bar barSize={50} dataKey="averageDistance" name="Average Distance" fill="#488257" />
                </BarChart>
            {/* </ResponsiveContainer> */}
            <div className='stream  '>
                <FormControl component="fieldset" style={{FlexDirection:'row'}} >
                    <RadioGroup value={streamvalue} onChange={handleStreamChange} className="df">
                        <FormControlLabel value="All" control={<Radio />} label="All" />
                        <FormControlLabel value="Upstream" control={<Radio />} label="Upstream" />
                        <FormControlLabel value="Downstream" control={<Radio />} label="Downstream" />
                    </RadioGroup>
                </FormControl>
                {/* <span>KM</span> */}
            </div>

        </div>
    );
}

export default GetDistanceByVehAttribute;
