import React from 'react'
import GetAverageEmmByFuel from './GetAverageEmmByFuel'
import GetAverageEmissionsByVehicleType from './getAvgEmmByVehType'
import GetAverageEmissionsByVendor from './getAverageEmissionsByVendor'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import Header from '../../../Components/Header/Header'
import GetVehicleAnalytics from './GetVehicleAnalytics'
import GetEmissionByVendor from './getEmmisionbyVendor'
import GetEmissionByVendorandVehicles from './GetEmisionbyVendorAndVeh'
import GetFuelConsuptiononTrips from './GetFuelConsuption'
import GetFuelConsuptionByVehicletype from './GetfuelConsuptionbyVehCategory'
import GetFuelTypeDistributionByVendor from './GetFuelTypeDistributionByVendor'
import GetTopEmissionContributorsBYVeh from './getTopEmissionContributorsBYVeh'
import EmissionTrend from './EmissionTrendBymonths'
import GetDistanceByVehAttribute from './GetDistanceBVehAttr'
import CustomChart from './PointStylingEmission'
import SimpleChart from './PointStylingEmission'
import ChartWithPointStyling from './PointStylingEmission'
import GetPoint from './PointStylingEmission'

function Charts() {


    const tableStyle = {
        width: "100%",
        margin: "10px",
    };


    return (
        <div className='df'>
            <div>
                <Sidebar />
            </div>
            <div style={tableStyle}>
                <Header />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="table-heading">ANALYTICS</div>

                </div>
                <div className='CHART_CONATAINER '>
                
                    {/* <GetAverageEmmByFuel /> */}
                    {/* <GetAverageEmissionsByVehicleType /> */}
                 
                    {/* <GetVehicleAnalytics />
                    <GetEmissionByVendor />
                    <GetEmissionByVendorandVehicles />
                    <GetFuelConsuptiononTrips />
                    <GetFuelConsuptionByVehicletype />
                    <GetFuelTypeDistributionByVendor />
                  
                    <EmissionTrend />
                    <GetDistanceByVehAttribute /> */}
                    {/* <GetAverageEmissionsByVendor /> */}
                </div>


            </div>
        </div>

    )
}

export default Charts
