import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IsModified from "../../Components/IsModified";

import { showSuccess, showError } from '../../toast';
// import saveFormData from "./saveFormData";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import CountrySelect from "../../Components/utils/CountryCode";




const AddnewCompanyMaster = () => {
    // const history = useHistory();
    const Navigate = useNavigate();

    const generateRandomUserID = () => {
        return Math.floor(Math.random() * 9000) + 1000; // Generates a random four-digit number
    };
    const getCurrentDate = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get("action") || "";
    const selectedid = searchParams.get("search") || "";
    const [Image, setImage] = useState('');

    const USER = JSON.parse(localStorage.getItem('_USER'))
    const [formModified, setFormModified] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [INDdata, setINDdata] = useState([]);
    const [CountryCode, setCountryCode] = useState('91')

    const [CompanyData, setCompanydata] = useState({
        profile: '',
        Company: "",
        address: "",
        contact: "",
        registrationNumber: "",
        gstNumber: "",
        role: "65ba187092b7532a0abd6074", // ADMIN  bcs super admin only creaated ADMIN 
        Industry: USER.Company.Industry || ""
    })

    const [formData, setFormData] = React.useState({
        userID: generateRandomUserID(),
        Company: "",
        name: "",
        email: "",
        countrycode: CountryCode,
        mobile: "",
        userRole: "65ba187092b7532a0abd6074",
        addedDate: getCurrentDate(),
        Facility: "",
    });


    const getIndData = async () => {
        try {
            const response = await axiosInstance.get(
                "/Allindustry"
            );
            console.log("INDdata", response);
            const data = response.data;

            setINDdata(data);
        } catch (error) {
            console.error("Axios Error:", error);
        }
    };


    useEffect(() => {
        getIndData()
    }, [])

    const handleModalClose = (result) => {
        setIsModalOpen(false);
        if (!result) {
            Navigate('/companyMaster')
        }
    };

    const containerStyle = {
        display: "flex",
    };

    const formStyle = {
        height: '80vh',
        overflow: 'scroll',
        padding: "10px",
        borderRadius: "10px",
        margin: "none",
        marginTop: '20px',
        backgroundColor: "white",
    };



    const handleBackbutton = () => {
        if (formModified) {
            setIsModalOpen(true)
        }
        else {
            Navigate('/CompanyMaster')
        }

    }

    const handleInputChange = (e, formDataSetter) => {
        const { name, value } = e.target;
        console.log(name, value);
        formDataSetter((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCountryChange = (country) => {
        // alert(country);
        // setFormData(prev => ({
        //   ...prev,
        //   countrycode: country
        // }));
        console.log("country", country)
        setCountryCode(country)
    };

    // ---------------------------------------

    const handleFileChange = async (file, name) => {
        try {
            const imageUrl = await handleFileUpload(file);
            setImage(imageUrl)
            setCompanydata((prevData) => ({
                ...prevData,
                [name]: imageUrl,
            }));
        } catch (error) {
            console.log(error);
        }
    };




    const handleFileUpload = async (file) => {
        try {
            const formData = new FormData();
            formData.append('DriverIDfile', file);
            const response = await axiosInstance.post('/uploadDriverID', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response?.data?.imageurl?.Location;
        } catch (error) {
            console.log(error);
            throw error; // Rethrow the error for handling at higher level if necessary
        }
    };



    const handleBrowseClick = (e) => {
        e.preventDefault();
        const fileInput = document.getElementById("fileInput");
        fileInput.click();
    };


    const handleCompanyChange = (e) => {
        const { name, value } = e.target;
        setCompanydata((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleCreateCompany = async (company) => {
        // // // debugger;
        console.log("CompanyData", CompanyData);
        console.log("user", formData)
        // alert(CompanyData.Company)
        const roleResponse = await axiosInstance.post(
            "/companies",
            CompanyData
        );
        console.log(roleResponse);

        if (roleResponse.status === 200) {
            showSuccess("Company Added !");
            const Company = roleResponse.data._id;

            const userdata ={...formData, Company}
            console.log({userdata})
            // now create user of this company 
            console.log(formData)
            await axiosInstance.post("/users",userdata ).then((res) => {
                // debugger;
                console.log(res)
                if(res.data.status === 200){
                    Navigate('/CompanyMaster')
                }
            })

        }
        else{
            showError(roleResponse.data.error)
        }


    }


    return (
        <div style={containerStyle}>
            <div>
                <Sidebar />
            </div>
            <div style={{ width: "100%", margin: '10px' }}>
                <Header />
                <div className="table-heading theme_c"><span onClick={() => handleBackbutton()}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New'} Company  <span className={action ? `_${action}` : '_edit'}>{`${CompanyData.Company || ''}`}</span></div>
                <div className="hidden_scrollbar" style={formStyle}>
                    <form className="RollsPermissionForm _company_form">
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12} className="mt-3">
                                <label htmlFor="col8">Company Logo</label>
                                <div
                                    style={{
                                        width: "100%",
                                        padding: Image ? "8px" : "30px",
                                        border: "2px dotted lightgrey",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        borderRadius: "12px",
                                    }}
                                >
                                    <label>
                                        {Image == "" ? `Drag and Drop or` : <img className="_DP" src={Image} />}
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            id="fileInput"
                                            name="profile"
                                            onChange={(e) => handleFileChange(e.target.files[0], 'profile')}

                                        />
                                        {Image == "" &&
                                            <Button
                                                sx={{
                                                    backgroundColor: "rgba(192, 192, 192, 1)",
                                                    color: "white",
                                                }}
                                                onClick={(e) => handleBrowseClick(e)}
                                            >
                                                browse
                                            </Button>}
                                    </label>
                                    <input
                                        type="file"
                                        name="image"

                                        accept="*"
                                        style={{ display: "none" }}
                                    />

                                </div>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Company Name *"
                                    name="Company"
                                    value={CompanyData.Company}
                                    onChange={handleCompanyChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Company Address *"
                                    name="address"
                                    value={CompanyData.address}
                                    onChange={handleCompanyChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Contact Number *"
                                    name="contact"
                                    type="number"
                                    value={CompanyData?.contact.slice(0, 10)}
                                    onChange={handleCompanyChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField

                                    label="Registration Number *"
                                    name="registrationNumber"
                                    value={CompanyData.registrationNumber}
                                    onChange={handleCompanyChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <TextField
                                    className=""
                                    label="GST Number *"
                                    name="gstNumber"
                                    value={CompanyData.gstNumber}
                                    onChange={handleCompanyChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <FormControl fullWidth className="" style={{ marginTop: '16px' }}>
                                    <InputLabel id="userRole">Industry *</InputLabel>
                                    <Select
                                        labelId="Industry"
                                        className="rolesSelect"
                                        value={CompanyData.Industry}
                                        name="Industry"
                                        label="Industry"
                                        onChange={handleCompanyChange}
                                    >
                                        {INDdata?.map((item, idx) => (
                                            <MenuItem
                                                style={{ height: "auto" }}
                                                key={idx}
                                                value={item._id}
                                            >
                                                {item.Industry}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>

                                <TextField
                                    className=""
                                    label="Admin Name *"
                                    name="name"
                                    value={formData.name}
                                    onChange={(e) => handleInputChange(e, setFormData)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <TextField
                                    className=""
                                    label="Admin Email *"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => handleInputChange(e, setFormData)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className="df">
                                    <div style={{ marginTop: '16px' }}>
                                        <CountrySelect id="newUserCNCode" width={140} CountryCode={formData.countrycode} onChange={handleCountryChange} page="rollsPermission" />
                                    </div>

                                    <TextField
                                        style={{ width: '300px' }}
                                        label="Mobile Number *"
                                        name="mobile"
                                        type="number"
                                        value={formData?.mobile?.slice(0, 10)}
                                        onChange={(e) => handleInputChange(e, setFormData)}
                                        fullWidth
                                        maxLength={10}
                                        margin="normal"
                                    />
                                </div>
                            </Grid>
                        </Grid>





                        {/* <FormControl fullWidth>
  <InputLabel id="userRole">Role *</InputLabel>
  <Select
    labelId="userRole"
    className="rolesSelect"
    value={CompanyData.role}
    name="role"
    label="Age"
    onChange={handleCompanyChange}
  >
    {newdata.map((item, idx) => (
      <MenuItem
        style={{ height: "auto" }}
        key={idx}
        value={item._id}
      >
        {item.roleName}
      </MenuItem>
    ))}
  </Select>
</FormControl>
<br />
<br />
<FormControl fullWidth>
  <InputLabel id="userRole">Facility *</InputLabel>
  <Select
    labelId="Facility"
    className="rolesSelect"
    value={CompanyData.Facility}
    name="Facility"
    label="Facility"
    onChange={handleCompanyChange}
  >
    {facilitydata?.map((item, idx) => (
      <MenuItem
        style={{ height: "auto" }}
        key={idx}
        value={item._id}
      >
        {item.facilityName}
      </MenuItem>
    ))}
  </Select>
</FormControl> */}

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                className="submit_btn"
                                onClick={handleCreateCompany}
                                variant="contained"
                                style={{
                                    width: "100%",
                                    padding: "15px",
                                    backgroundColor: "black",
                                    color: "white",
                                    textTransform: "none",
                                    marginTop: "5%",
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </form>

                    {isModalOpen &&
                        <IsModified
                            open={isModalOpen}
                            onClose={handleModalClose}
                            description="Do you want to save the data before going back?"
                        />
                    }
                </div>
            </div>

        </div>
    );
};

export default AddnewCompanyMaster;
