import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import { showSuccess, showError } from '../../toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IsModifiedModal from "../../Components/IsModified";
import DatePicker from "react-datepicker";
import { enIN } from 'date-fns/locale'
import { countries } from "../../Components/utils/CountryCode"

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";



// import saveFormData from "./saveFormData";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import CountrySelect from "../../Components/utils/CountryCode";




const AddNewBilling = () => {
    // const history = useHistory();
    const Navigate = useNavigate();
    const [address, setAddress] = useState("");




    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get("action") || "";
    const selectedid = searchParams.get("search") || "";

    const [formModified, setFormModified] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countries, setcountries] = useState([]);

    // const generateRandom4DigitNumber = () => {
    //     return `CUST${Math.floor(Math.random() * 900) + 101}`;
    // };

    const user = localStorage.getItem("user_id");
    const [SelectedCurrency, setSelectedCurrency] = useState(''); // State to store selected currency code

    const [billingData, setbillingData] = useState({
        country: "",
        currency: "",
        price: null,
        user: user
    });


    useEffect(() => {

        axios.get('https://restcountries.com/v3.1/all')
            .then(response => {
                const countries = response.data.map(country => ({
                    name: country.name.common,
                    currency: country.currencies ? Object.keys(country.currencies)[0] : 'Unknown' // Check if currencies property exists
                }));
                setcountries(countries)
            })
            .catch(error => console.error('Error fetching data:', error));


    }, []);


    useEffect(() => {
        if (selectedid) {
            axiosInstance.get(`/Billing/${selectedid}`).then((res) => {
                console.log("selectedID", res.data)
                const selectedgoods = res.data.data
                console.log("selectedVendor", selectedgoods)
                if (selectedgoods) {
                    setbillingData(selectedgoods);

                }
            })
        }
    }, [selectedid])



    const validateForm = () => {

        if (!billingData.country) {
            showError("Please select a country");
            return false;
          }
          if (!billingData.price) {
            showError("Please Enter the Price ");
            return false;
          }
        return true;
    };

    const makeApiCall = async (method, url, data, callback) => {
        try {
            // debugger;
            const response = await axiosInstance[method.toLowerCase()](url, {
                ...data
            });

            console.log("res", response)

            if (response.data.success === false) {
                showError(response?.data?.message)
            }
            else {
                showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
                callback()
                return response.data;
            }

        } catch (error) {

            console.error("Error making API call:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    };

    const handleClickSubmit = async (e) => {
        e.preventDefault();

        const isValid = validateForm();
        // debugger
   
      
        // console.log("customerData", customerData);
        console.log("newdata", billingData);
        if (isValid) {
            try {
                const responseData = await makeApiCall(
                    `${action === "" ? 'POST' : action === 'update' ? 'PUT' : 'DELETE'}`,
                    `/${action === "" ? `Billing` : action === 'update' ? `Billing/${selectedid}` : `Billing/${selectedid}`}`,
                    billingData, () => { Navigate('/Billing') }
                );
                console.log(responseData);
            } catch (error) {

                console.error("Error submitting form:", error);
            }
        }

    };

    const containerStyle = {
        display: "flex",
    };

    const formStyle = {
        height: '80vh',
        overflow: 'scroll',
        padding: "10px",
        borderRadius: "10px",
        margin: "none",
        marginTop: '20px',
        backgroundColor: "white",
    };




    const handleModalClose = (result) => {
        setIsModalOpen(false);
        if (!result) {
            Navigate('/Billing')
        }
    };

    const handleBackbutton = () => {
        // alert(formModified)
        if (formModified) {
            setIsModalOpen(true)
        }
        else {
            Navigate('/Billing')
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormModified(true);
        setbillingData({ ...billingData, [name]: value });
    };

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        const selectedCountryData = countries.find(country => country.name === selectedCountry);
    
        if (selectedCountryData) {
            setbillingData(prevData => ({
                ...prevData,
                country: selectedCountry,
                currency: selectedCountryData.currency
            }));
        } else {
            setbillingData(prevData => ({
                ...prevData,
                country: selectedCountry,
                currency: ""
            }));
        }
    };
    



    return (
        <div style={containerStyle}>
            <div>
                <Sidebar />
            </div>
            <div style={{ width: "100%", margin: '10px' }}>
                <Header />
                <div className="table-heading theme_c"><span onClick={() => handleBackbutton()}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New'} Country   <span className={action ? `_${action}` : '_edit'}>{`  ${billingData.country || ''}`}</span></div>
                <div className="hidden_scrollbar" style={formStyle}>
                    <form className="fuelform">
                        <Grid container spacing={2}>



                            <Grid item xs={4}>
                                <label>Country <span className="_MANDATORY"> *</span></label>
                                <Select
                                    fullWidth
                                    style={{ height: '38px' }}
                                    value={billingData.country}
                                    onChange={handleCountryChange}
                                    required
                                    disabled={action === 'view'}
                                    name="country"
                                >
                                    {countries.map((item, idx) => (
                                        <MenuItem
                                            style={{ height: "auto" }}
                                            key={item.idx}
                                            value={item.name}
                                        >
                                            <span>
                                                {item.name}&nbsp; <span style={{ color: localStorage.getItem('--theme') || '#ccc', fontSize: '10px' }}>  {item.currency}</span>
                                            </span>

                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={4}>
                                <label htmlFor="col2">Price  in  {billingData?.currency} &nbsp;&nbsp;<span className="_MANDATORY">*</span></label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="col2"
                                    type="number"
                                    value={billingData.price}
                                    name="price"
                                    
                                    onChange={handleInputChange}
                                />
                            </Grid>
                          

                            <Grid item xs={4}>
                                <button
                                    className="submit_btn"
                                    style={{
                                        color: "white",
                                        marginTop: '20px',
                                        backgroundColor: "black",
                                        width: "100%",  
                                        padding: "10px",
                                        borderRadius: "5px",
                                        textAlign: "center",
                                    }}
                                    onClick={handleClickSubmit}
                                    disabled={action === 'view'}
                                >
                                    {action ? action.toUpperCase() : 'Submit'}
                                </button>
                            </Grid>
                        </Grid>

                        {isModalOpen &&
                            <IsModifiedModal
                                open={isModalOpen}
                                onClose={handleModalClose}
                                description="Do you want to save the data before going back?"
                            />
                        }
                    </form>

                </div>
            </div>
        </div>
    );
};

export default AddNewBilling;
