import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  InputLabel,
  Typography,
  FormControl,
} from "@mui/material";
import Modal from "react-modal";
import axios from "axios";
// import BorderColorIcon from "@mui/icons-material/BorderColor";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import ButtonGroup from '@mui/material/ButtonGroup';
// import Button from '@mui/material/Button';

import CountrySelect from "../../Components/utils/CountryCode";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// const AccessModel = [
//   { title: "Dashboard" },
//   { title: "Trips" },
//   { title: "Billing" },
//   { title: "Industry Process" },
//   { title: "Purchase Goods & Services" },
//   { title: "Setup" },
//   { title: "Location Master" },
//   { title: "Vendor Master" },
//   { title: "Vehicle Master" },
//   { title: "Driver Master" },
//   { title: "Fuel Master" },
//   { title: "CO2 Calculator" },
//   { title: "Role & Permission" },
//   { title: "Support" },
//   { title: "Documentation" },
// ];

const AccessModel = [
  { title: "Dashboard" },
  { title: "Trips" },
  {
    title: "Setup",
    submenus: [
      { title: "Customer Facility" },
      { title: "Roles & Permissions" },
      { title: "Destination Master" },
      { title: "Industry Process" },
      { title: "Purchase Goods & Services" },
      {
        title: "Vendor Master",
        submenus: [
          { title: "Vehicle Master" },
          { title: "Driver Master" }
        ]
      }
    ]
  },
  { title: "CO2 Calculator" },
  { title: "Support" },
  { title: "Documentation" }
];


const MultipleSelectCheckbox = () => {
  const [roleFormData, setRoleFormData] = useState({
    roleAccessName: [],
  });

  const handleInputChange = (event) => {
    const selectedModules = event.target.value;
    setRoleFormData((prevFormData) => ({
      ...prevFormData,
      roleAccessName: selectedModules,
    }));
  };
};
const ModuleSelection = ({
  roleFormData,
  setRoleFormData,
  handleInputChange,
}) => {
  const handleChange = (event) => {
    const selectedModules = event.target.value;
    // Assuming roleAccessName is an array in your state
    setRoleFormData((prevFormData) => ({
      ...prevFormData,
      roleAccessName: selectedModules,
    }));
  };
};
const TableBody = ({
  data,
  setviewModalIsOpen,
  setSelectedRowData,
  setisEdit,
}) => (
  <tbody>
    {data.map((row, index) => {
      // Format the addedDate to display only the date without the time
      const formattedDate = new Date(row.addedDate).toLocaleDateString();

      return (
        <>
          <tr
            key={row.userId}
            className={index % 2 === 0 ? "even-row" : "odd-row"}
          >
            <td>{row.userID}</td>
            <td>
              {row.name}
              {"     "}
              <br />
              {row.email}
            </td>
            <td>{row.mobilenumber}</td>
            <td>{row.role}</td>
            <td>{row.name}</td>
            <td>{formattedDate}</td>
            <td
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                padding: "26px 0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#4B465C",
                    color: "#fff",
                    textTransform: "none",
                    width: "50px",
                    height: "24px",
                  }}
                  onClick={() => {
                    setSelectedRowData(row);
                    setisEdit(false);
                    setviewModalIsOpen(true);

                  }}
                >
                  view
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  style={{
                    textTransform: "none",
                    width: "50px",
                    height: "24px",
                  }}
                  onClick={() => {
                    setSelectedRowData(row);
                    setisEdit(true);
                    setviewModalIsOpen(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                  >
                    <path
                      d="M19.5353 6.80503L15.6948 2.96534C15.5671 2.83763 15.4155 2.73632 15.2487 2.6672C15.0818 2.59808 14.903 2.5625 14.7224 2.5625C14.5418 2.5625 14.363 2.59808 14.1961 2.6672C14.0293 2.73632 13.8777 2.83763 13.75 2.96534L3.15305 13.5623C3.02482 13.6895 2.92315 13.841 2.85396 14.0078C2.78476 14.1747 2.74943 14.3536 2.75001 14.5342V18.3748C2.75001 18.7395 2.89487 19.0892 3.15274 19.3471C3.4106 19.6049 3.76033 19.7498 4.12501 19.7498H18.5625C18.7448 19.7498 18.9197 19.6774 19.0486 19.5484C19.1776 19.4195 19.25 19.2446 19.25 19.0623C19.25 18.88 19.1776 18.7051 19.0486 18.5762C18.9197 18.4472 18.7448 18.3748 18.5625 18.3748H9.91032L19.5353 8.74979C19.663 8.62211 19.7643 8.47051 19.8335 8.30367C19.9026 8.13683 19.9382 7.958 19.9382 7.77741C19.9382 7.59682 19.9026 7.41799 19.8335 7.25115C19.7643 7.0843 19.663 6.93271 19.5353 6.80503ZM7.96555 18.3748H4.12501V14.5342L11.6875 6.97175L15.5281 10.8123L7.96555 18.3748ZM16.5 9.84034L12.6603 5.99979L14.7228 3.93729L18.5625 7.77784L16.5 9.84034Z"
                      fill="#4B465C"
                    />
                  </svg>
                </Button>
              </div>
            </td>
          </tr>
        </>
      );
    })}
  </tbody>
);

const RollsAndPermissionTable = () => {
  const [data, setData] = useState([]);
  const [CompData, setCompData] = useState([])
  const [newdata, setNewData] = useState([]);
  const [facilitydata, setfacilitydata] = useState([]);
  const [INDdata, setINDdata] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [roleModalIsOpen, setRoleModalIsOpen] = React.useState(false);
  const [viewModalIsOpen, setviewModalIsOpen] = React.useState(false);
  const [SelectedRowData, setSelectedRowData] = React.useState([]);
  const [isEdit, setisEdit] = React.useState(false);

  const [sortOrder, setSortOrder] = useState(true);

  const [Companies, setCompanies] = useState([]);

  // useEffect(() => {
  //   handleSortToggle();
  // }, []); 

  const handleSortToggle = () => {
    const clonedData = data.sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate))
    setData(sortOrder ? clonedData : clonedData.reverse());
    setSortOrder((prevSortOrder) => !prevSortOrder);
  };


  const openModal1 = () => {
    setIsModalOpen(true);
  };

  const closeModal1 = () => {
    setIsModalOpen(false);
  };

  const get_data = async () => {
    try {
      const response = await axiosInstance.get(
        `/users`
      );
      console.log("rollsdata", response);
      const data = response.data;
      setData(data?.reverse());
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const get_company = async () => {
    try {
      const response = await axiosInstance.get(
        `/companies`
      );
      console.log("comp data", response);
      const data = response.data.reverse();
      setCompData(data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };


  const get_facility_data = async () => {
    try {
      const response = await axiosInstance.get(
        "/customerFacility"
      );
      console.log("rollsdata", response);
      const data = response.data;

      setfacilitydata(data.data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };
  const getIndustryDAta = async () => {
    try {
      const response = await axiosInstance.get(
        "/industry"
      );
      console.log("INDdata", response);
      const data = response.data;

      setINDdata(data);
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  useEffect(() => {
    get_data();
    get_company()
    getIndustryDAta()
    get_facility_data()
  }, [modalIsOpen, roleModalIsOpen, viewModalIsOpen]);

  const get_newroledata = async () => {
    try {
      const response = await axiosInstance.get(
        "/newrole"
      );
      console.log("newroledata", response);
      setNewData(response.data); // Fix state update
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };

  const [roleFormData, setRoleFormData] = React.useState({
    roleName: "",
    roleAccessName: [],
  });

  // const handleAutocompleteChange = (e, value) => {
  //   setRoleFormData((prevData) => ({
  //     ...prevData,
  //     roleAccessName: value,
  //   }));
  // };
  const handleAutocompleteChange = (event, value) => {
    let newValue = [...value];

    const selectParentOptions = (item) => {
      if (item.parent) {
        if (!newValue.some(val => val.title === item.parent.title)) {
          newValue.push(item.parent);
        }
        selectParentOptions(item.parent);
      }
    };

    const deselectChildOptions = (item) => {
      if (item.submenus) {
        item.submenus.forEach((submenu) => {
          const index = newValue.findIndex(val => val.title === submenu.title);
          if (index !== -1) {
            newValue.splice(index, 1);
          }

          deselectChildOptions(submenu);
        });
      }
    };

    newValue.forEach((item) => {
      if (item.title === "Setup") {
        item.submenus.forEach((submenu) => {
          if (!newValue.some(val => val.title === submenu.title)) {
            newValue.push(submenu);
            selectParentOptions(submenu);
          }
        });
      } else if (item.submenus) {
        deselectChildOptions(item);
      }
    });

    console.log({ ...roleFormData, roleAccessName: newValue });
    setRoleFormData({ ...roleFormData, roleAccessName: newValue });
  };







  const handleInputChange = (e, formDataSetter) => {
    const { name, value } = e.target;
    console.log(name, value);
    formDataSetter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleupdateInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setSelectedRowData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const CreateValidation = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.mobile ||
      !formData.userRole
    ) {
      showError("Please fill all mandatory fields !");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      showError("Please enter a valid email address!");
      return false;
    }

    // Phone validation
    const phoneRegex = /^\d{10}$/; // Assuming 10-digit phone number
    if (!phoneRegex.test(formData.mobile)) {
      showError("Please enter a valid 10-digit phone number!");
      return false;
    }
    if (formData.countrycode == "") {
      showError("Please Select the Country Code")
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // // debugger;
    console.log("formdata", formData);
    const valid = CreateValidation();

    if (valid) {

      try {
        await axiosInstance.post("/users", {
          ...formData,
        })
          .then((res) => {
            if (res) {
              // alert("data added");
              get_data();
              console.log("res", res)
              if (res.data.success) {
                showSuccess("User Added!");
                setFormData({
                  name: "",
                  email: "",
                  mobile: "",
                  userRole: "",
                });
                closeModal();
              }
              else {
                showError(res.data.message);
              }
            }
          });
      } catch (error) { }
    }
  };

  const handleRoleSubmit = async (e) => {
    e.preventDefault();
    console.log(roleFormData);

    try {
      const roleAccessNamesArray = roleFormData.roleAccessName.map(
        (item) => item.title
      );
      const roleAccessNameString = roleAccessNamesArray.join(", ");
      const formDataToSend = {
        ...roleFormData,
        roleAccessName: roleAccessNameString,
      };

      console.log(formDataToSend);
      if (!roleFormData.roleName || roleFormData.roleAccessName.length == 0) {
        showError("Please Add a Role And Access Name");
        return;
      }

      const roleResponse = await axiosInstance.post(
        "/newrole",
        formDataToSend
      );
      console.log(roleResponse);
      showSuccess("Role Added !");
      get_newroledata();
      closeRoleModal();
      setRoleFormData({
        roleName: "", // assuming roleName is a string
        roleAccessName: [], // assuming roleAccessName is an array
      });
    } catch (error) {
      console.error("Axios Error:", error);
    }
  };


  const getAllCompany = async () => {
    try {
      const response = await axiosInstance.get(
        `/companies`
      );
      console.log("rollsdata", response);
      const data = response.data;
      setCompanies(data)
    } catch (error) {
      console.error("Axios Error:", error);
    }
  }


  useEffect(() => {
    getAllCompany()
    get_newroledata();
  }, []);

  // const tableData = generateRandomData();

  function openModal() {
    setIsOpen(true);
    get_newroledata();
  }

  function closeModal() {
    setIsOpen(false);
  }


  const openRoleModal = () => {
    setRoleModalIsOpen(true);
  };

  const closeRoleModal = () => {
    setRoleModalIsOpen(false);
  };
  const generateRandomUserID = () => {
    return Math.floor(Math.random() * 9000) + 1000; // Generates a random four-digit number
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [CountryCode, setCountryCode] = useState('91')
  const [formData, setFormData] = React.useState({
    userID: generateRandomUserID(),
    Company: localStorage.getItem("welcome-NIRANARA")?.split('-')[0]?.trim() === "SUPER ADMIN" ? "NIRANTARA" : localStorage.getItem('COMPANY')?.trim(),
    name: "",
    email: "",
    countrycode: CountryCode,
    mobile: "",
    userRole: "",
    addedDate: getCurrentDate(),
    Facility: "",
  });
  const [Image, setImage] = useState('');
  const [companymodalIsOpen, setModalIsOpen] = useState(false);
  const USER = JSON.parse(localStorage.getItem('_USER'));
  console.log({ USER })
  const [CompanyData, setCompanydata] = useState({
    profile: '',
    Company: "",
    address: "",
    contact: "",
    registrationNumber: "",
    gstNumber: "",
    role: "65ba187092b7532a0abd6074", // ADMIN 
    Industry: USER.Company.Industry || ""
  })


  const customStyles = {
    overlay: {
      color: "black",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "auto",
      maxWidth: "500px",
      backgroundColor: "white", // Transparent to allow the nested Paper to control background
      border: "none", // Remove border to give a clean look
      color: "black",
    },
  };

  const userStyles = {
    overlay: {
      color: "black",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      height: "auto",
      maxWidth: "450px",
      backgroundColor: "white", // Transparent to allow the nested Paper to control background
      border: "none", // Remove border to give a clean look
      color: "black",
    },
  };

  const handleupdate = async () => {
    const mobileWithCountryCode = SelectedRowData?.mobilenumber;
    console.log(SelectedRowData)
    try {
      const roleResponse = await axiosInstance.put(
        `/updateuser/${SelectedRowData.userID}`,
        { ...SelectedRowData, countrycode: CountryCode, mobilenumber: mobileWithCountryCode }
      );
      // // debugger;
      console.log("roleResponse", roleResponse);

      if (roleResponse?.data?.success) {
        showSuccess("Updated !");
        setviewModalIsOpen(false);
        closeModal();
      }
      else {
        showError(roleResponse.data.message);

      }

      // get_newroledata();
    } catch (error) {
      showError('Something went wrong');
      console.error("Axios Error:", error);
    }
    closeRoleModal();
  };


  const handleDelete = async () => {
    const mobileWithCountryCode = SelectedRowData?.mobilenumber;
    // alert(SelectedRowData?.mobilenumber)
    try {
      const roleResponse = await axiosInstance.delete(
        `/deleteuser/${SelectedRowData._id}`,
        { ...SelectedRowData, countrycode: CountryCode, mobilenumber: mobileWithCountryCode }
      );
      setviewModalIsOpen(false);
      console.log(roleResponse);
      closeModal();
      showSuccess("Deleted !");
      // get_newroledata();
    } catch (error) {
      console.error("Axios Error:", error);
    }
    closeRoleModal();

  };



  const handleCountryChange = (country) => {
    // alert(country);
    // setFormData(prev => ({
    //   ...prev,
    //   countrycode: country
    // }));
    console.log("country", country)
    setCountryCode(country)
  };


  const handleFileChange = async (file, name) => {
    try {
      const imageUrl = await handleFileUpload(file);
      setImage(imageUrl)
      setCompanydata((prevData) => ({
        ...prevData,
        [name]: imageUrl,
      }));
    } catch (error) {
      console.log(error);
    }
  };




  const handleFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('DriverIDfile', file);
      const response = await axiosInstance.post('/uploadDriverID', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response?.data?.imageurl?.Location;
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error for handling at higher level if necessary
    }
  };


  const handleCreateCompany = async (company) => {
    console.log("CompanyData", CompanyData);

    // alert(CompanyData.Company)
    const roleResponse = await axiosInstance.post(
      "/companies",
      CompanyData
    );
    console.log(roleResponse);
    setModalIsOpen(false);
    if (roleResponse.status === 200) {
      showSuccess("Company Added !");
      getAllCompany()
    }


  }

  const handleBrowseClick = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };


  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setCompanydata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const oncloseModal = () => {
    setModalIsOpen(false);

  };


  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "1%",
        }}
      >
        <div className="table-heading">All {USER.role.roleName === "SUPER ADMIN" ? ' Companies' : 'Users'} </div>
        <div
          style={{
            gap: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: 'absolute',
            right: '20px',
            top: '60px'
          }}
        >
          {/* {
            ["SUPER ADMIN"].includes(USER?.role?.roleName) &&
           <div style={{boxShadow:'0 0 4px 0 rgba(0,0,0,0.1)',borderRadius:'6px'}}>
             <ButtonGroup
              default
              disableElevation
              variant="contained"
              aria-label="Disabled button group"
              className="docs-button-group" // Apply custom styles using CSS
            >

              <Button
                onClick={() => settblShow(true)}
                style={{
                  backgroundColor: 'transparent',
                  color: tblShow ? '#007bff' : '#000',
                }}
              >
                COMPANY
              </Button>
              <Button
                onClick={() => settblShow(false)}
                style={{
                  backgroundColor: 'transparent',
                  color: !tblShow ? '#007bff' : '#000',
                }}
              >
                USERS
              </Button>
            </ButtonGroup>
           </div>
          } */}

          {["SUPER ADMIN", "ADMIN"].includes(USER?.role?.roleName) &&
            <Button
              className="themebg"
              onClick={openModal}
              style={{
                padding: "2px 12px",
                color: "#fff",
                border: "none",
                textTransform: "none",
                boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.2)",
              }}
            >
              + NEW USER
            </Button>
          }
          {["SUPER ADMIN", "ADMIN"].includes(USER?.role?.roleName) &&
            <Button
              onClick={openRoleModal}
              className="themebg"
              style={{
                padding: "2px 12px",
                color: "#fff",
                textTransform: "none",
                border: "none",
                boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.2)",
              }}
            >
              + NEW ROLE
            </Button>
          }
          {["SUPER ADMIN"].includes(USER?.role?.roleName) && <Button
            className="themebg"
            onClick={setModalIsOpen}
            style={{
              padding: "2px 12px",
              color: "#fff",
              border: "none",
              textTransform: "none",
              boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.2)",
            }}
          >
            + NEW COMPANY
          </Button>}

        </div>
      </div>
      <div >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        ></div>
        <div className="">
        <TableComponent pagename="Users" page="rollsandpermission" data={data} exclude={['_id', '__v', 'userID', 'addedDate', 'password', 'enteredOtp', 'updatedAt', 'updatedBy', 'countrycode', 'Facility']} col_class="overflow" edit={() => {
                // setSelectedRowData(row);
                setisEdit(true);
                setviewModalIsOpen(true);
              }} deletelink={() => {
                setisEdit(false);
                setviewModalIsOpen(true);
              }} setSelectedRowData={setSelectedRowData} />




          {/* <table className="styled-table" style={{ width: "100%" }}>
            <thead onClick={() => handleSortToggle()}>
              <tr>
                <th>User ID</th>
                <th>Name with Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Created By</th>
                <th>Date</th>
                <th style={{ textAlign: "center", zIndex: "999" }}></th>
              </tr>
            </thead>
            <TableBody
              data={data}
              setviewModalIsOpen={setviewModalIsOpen}
              setSelectedRowData={setSelectedRowData}
              setisEdit={setisEdit}
            />
          </table> */}
        </div>

        {/* company modal */}


        <Modal
          isOpen={companymodalIsOpen}
          onRequestClose={oncloseModal}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <Grid>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Lora",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "32px",
                marginBottom: "3%",
              }}
            >
              Create Company
            </Typography>
            <Typography
              variant="h7"
              sx={{
                fontFamily: "Lora",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5%",
              }}
            >
              Complete the Form to Personalize Your Experience
            </Typography>
          </Grid>
          <form className="RollsPermissionForm _company_form">

            <Grid item xs={12} className="mt-3">
              <label htmlFor="col8">Company Logo</label>
              <div
                style={{
                  width: "100%",
                  padding: Image ? "8px" : "30px",
                  border: "2px dotted lightgrey",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "12px",
                }}
              >
                <label>
                  {Image == "" ? `Drag and Drop or` : <img className="_DP" src={Image} />}
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    id="fileInput"
                    name="profile"
                    onChange={(e) => handleFileChange(e.target.files[0], 'profile')}

                  />
                  {Image == "" &&
                    <Button
                      sx={{
                        backgroundColor: "rgba(192, 192, 192, 1)",
                        color: "white",
                      }}
                      onClick={(e) => handleBrowseClick(e)}
                    >
                      browse
                    </Button>}
                </label>
                <input
                  type="file"
                  name="image"

                  accept="*"
                  style={{ display: "none" }}
                />

              </div>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={6}>
                <TextField
                  label="Company Name *"
                  name="Company"
                  value={CompanyData.Company}
                  onChange={handleCompanyChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Company Address *"
                  name="address"
                  value={CompanyData.address}
                  onChange={handleCompanyChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>

            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={6}>
                <TextField
                  label="Contact Number *"
                  name="contact"
                  type="number"
                  value={CompanyData?.contact.slice(0, 10)}
                  onChange={handleCompanyChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField

                  label="Registration Number *"
                  name="registrationNumber"
                  value={CompanyData.registrationNumber}
                  onChange={handleCompanyChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid item xs={6}>

                <TextField
                  className=""
                  label="GST Number *"
                  name="gstNumber"
                  value={CompanyData.gstNumber}
                  onChange={handleCompanyChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>

                <FormControl fullWidth className="" style={{ marginTop: '16px' }}>
                  <InputLabel id="userRole">Industry *</InputLabel>
                  <Select
                    labelId="Industry"
                    className="rolesSelect"
                    value={CompanyData.Industry}
                    name="Industry"
                    label="Industry"
                    onChange={handleCompanyChange}
                  >
                    {INDdata?.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto" }}
                        key={idx}
                        value={item._id}
                      >
                        {item.Industry}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>


            {/* <FormControl fullWidth>
              <InputLabel id="userRole">Role *</InputLabel>
              <Select
                labelId="userRole"
                className="rolesSelect"
                value={CompanyData.role}
                name="role"
                label="Age"
                onChange={handleCompanyChange}
              >
                {newdata.map((item, idx) => (
                  <MenuItem
                    style={{ height: "auto" }}
                    key={idx}
                    value={item._id}
                  >
                    {item.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userRole">Facility *</InputLabel>
              <Select
                labelId="Facility"
                className="rolesSelect"
                value={CompanyData.Facility}
                name="Facility"
                label="Facility"
                onChange={handleCompanyChange}
              >
                {facilitydata?.map((item, idx) => (
                  <MenuItem
                    style={{ height: "auto" }}
                    key={idx}
                    value={item._id}
                  >
                    {item.facilityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                className="submit_btn"
                onClick={handleCreateCompany}
                variant="contained"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: "black",
                  color: "white",
                  textTransform: "none",
                  marginTop: "5%",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </Modal>

        {/* Modal for the first button on the top of the table */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={userStyles}

          contentLabel="Example Modal"
        >
          <Grid>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Lora",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "32px",
                marginBottom: "3%",
              }}
            >
              Create User
            </Typography>
            <Typography
              variant="h7"
              sx={{
                fontFamily: "Lora",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5%",
              }}

            >
              Complete the Form to Personalize Your Experience
            </Typography>
          </Grid>
          <form className="RollsPermissionForm">
            <label htmlFor="fuelType">Company</label><br />
            <FormControl style={{ width: "400px", height: '39px' }}>

              <Select
                id="Company"
                value={formData.Company}
                name="Company"
                fullWidth
                onChange={(e) => handleInputChange(e, setFormData)}
                required
                disabled={localStorage.getItem("welcome-NIRANARA")?.split('-')[0]?.trim() !== "SUPER ADMIN"}

              >
                {Companies?.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    <img className="menu_img" src={item.profile} /> &nbsp;  &nbsp;{item.Company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Name *"
              name="name"
              value={formData.name}
              onChange={(e) => handleInputChange(e, setFormData)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email *"
              name="email"
              value={formData.email}
              onChange={(e) => handleInputChange(e, setFormData)}
              fullWidth
              margin="normal"
            />
            <div className="df">
              <div style={{ marginTop: '16px' }}>
                <CountrySelect id="newUserCNCode" width={140} CountryCode={formData.countrycode} onChange={handleCountryChange} page="rollsPermission" />
              </div>

              <TextField
                style={{ width: '300px' }}
                label="Mobile Number *"
                name="mobile"
                type="number"
                value={formData?.mobile?.slice(0, 10)}
                onChange={(e) => handleInputChange(e, setFormData)}
                fullWidth
                maxLength={10}
                margin="normal"
              />
            </div>

            <br />


            {/* <div >
                <label htmlFor="fuelType">Measurement Type</label><br />
                <FormControl style={{ width: "300px", height: '39px' }}>

                  <Select
                    id="measurementType"
                    value={selectedMeasurement}
                    onChange={(e) => handleMeasurementSelect(e.target.value)}
                    required
                  >
                    {measurementData.map((measurement, index) => (
                      <MenuItem key={index} value={measurement}>
                        {measurement}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}
            <FormControl fullWidth>
              <InputLabel id="userRole">Role *</InputLabel>
              <Select
                labelId="userRole"
                className="rolesSelect"
                value={formData.userRole}
                name="userRole"
                label="Age"
                onChange={(e) => handleInputChange(e, setFormData)}
              >
                {newdata.map((item, idx) => (
                  <MenuItem
                    style={{ height: "auto" }}
                    key={idx}
                    value={item._id}
                  >
                    {item.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="userRole">Facility *</InputLabel>
              <Select
                labelId="Facility"
                className="rolesSelect"
                // value={formData.userRole}
                name="Facility"
                label="Facility"
                onChange={(e) => handleInputChange(e, setFormData)}
              >
                {facilitydata?.map((item, idx) => (
                  <MenuItem
                    style={{ height: "auto" }}
                    key={idx}
                    value={item._id}
                  >
                    {item.facilityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                className="submit_btn"
                onClick={handleSubmit}
                variant="contained"
                style={{
                  width: "100%",
                  padding: "15px",
                  backgroundColor: "black",
                  color: "white",
                  textTransform: "none",
                  marginTop: "5%",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </Modal>
        {/* Modal for the Second button on the top of the table */}
        <Modal
          isOpen={roleModalIsOpen}
          onRequestClose={closeRoleModal}
          style={customStyles}
          contentLabel="Role Modal"
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Lora",
              fontWeight: "400",
              fontSize: "24px",
              lineHeight: "32px",
              marginBottom: "3%",
            }}
          >
            Create New Role
          </Typography>
          <Typography
            variant="h7"
            sx={{
              fontFamily: "Lora",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              marginBottom: "5%",
            }}
          >
            Empower Your Team: Create a New User Role
          </Typography>
          <form className="RollsPermissionForm New_Role">
            <TextField
              label="Role Name"
              name="roleName"
              value={roleFormData.roleName}
              onChange={(e) => handleInputChange(e, setRoleFormData)}
              fullWidth
              margin="normal"
            />
            <Autocomplete
              multiple
              // id="checkboxes-tags-demo"

              options={AccessModel}
              disableCloseOnSelect
              value={roleFormData.roleAccessName}
              onChange={handleAutocompleteChange}
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Access Module Name"
                // placeholder="Favorites"
                />
              )}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "5%",
              }}
            >
              <Button
                className="submit_btn"
                onClick={handleRoleSubmit}
                variant="contained"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  textTransform: "none",
                  width: "100%",
                  padding: "15px",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        </Modal>

        {/* Modal for the viewe button  */}
        <Modal
          isOpen={viewModalIsOpen}
          onRequestClose={() => setviewModalIsOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Grid>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Lora",
                fontWeight: "400",
                fontSize: "24px",
                lineHeight: "32px",
                marginBottom: "3%",
              }}
            >
              {isEdit ? "Edit" : "View"} Roles and Permission
            </Typography>
            <Typography
              variant="h7"
              sx={{
                fontFamily: "Lora",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5%",
              }}
            >
              Explore a uniquely personalized view of your data for<br /> a seamless and customized user experience
            </Typography>
          </Grid>
          <form>
            {/* <TextField
              label="User ID"
              name="userID"
              value={SelectedRowData.userID}
              onChange={handleupdateInputChange}
              fullWidth
              margin="normal"
            /> */}


            <FormControl style={{ width: "400px", height: '39px' }}>

              <Select
                id="Company"
                value={SelectedRowData?.Company}
                onChange={handleupdateInputChange}
                name="Company"
                fullWidth
                required
                disabled={localStorage.getItem("welcome-NIRANARA")?.split('-')[0]?.trim() !== "SUPER ADMIN"}

              >
                {Companies?.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    <img className="menu_img" src={item.profile} /> &nbsp;  &nbsp;{item.Company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Name"
              name="name"
              value={SelectedRowData.name}
              onChange={handleupdateInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              value={SelectedRowData.email}
              onChange={handleupdateInputChange}
              fullWidth
              margin="normal"
            />
            <div className="df">
              <div style={{ marginTop: '16px' }}>
                {/* <CountrySelect id="newUserCNCode" width={170} CountryCode={SelectedRowData?.countrycode || formData.countrycode} onChange={handleCountryChange} /> */}
              </div>
              <TextField
                label="mobile"
                name="mobilenumber"
                value={SelectedRowData?.mobilenumber?.split("-")[1] || SelectedRowData.mobilenumber}
                onChange={handleupdateInputChange}
                fullWidth
                margin="normal"
              />
            </div>
            <FormControl fullWidth>
              <InputLabel id="userRole">Role</InputLabel>
              <Select

                className="rolesSelect"
                value={SelectedRowData.role}
                name="role"
                label="Role"
                onChange={handleupdateInputChange}
              >
                {newdata.map((item, idx) => (
                  <MenuItem
                    style={{ height: "auto" }}
                    key={idx}
                    value={SelectedRowData ? item?._id : SelectedRowData.role?._id}
                  >
                    {item.roleName}

                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <TextField
              label="Created By"
              name="name"
              value={SelectedRowData.name}
              onChange={handleupdateInputChange}
              fullWidth
              margin="normal"
            /> */}
            <TextField
              label="created At"
              name="addedDate"
              value={SelectedRowData.addedDate ? new Date(SelectedRowData.addedDate).toLocaleDateString("en-GB") : ''}
              onChange={handleupdateInputChange}
              fullWidth
              dateFormat="dd/MM/yyyy"
              margin="normal"
            />
            {
              SelectedRowData?.updatedAt &&
              <TextField
                label="Updated At"
                name="addedDate"
                value={new Date(SelectedRowData.updatedAt).toLocaleDateString()}
                disabled
                fullWidth
                margin="normal"
              />
            }
            {
              SelectedRowData?.updatedBy &&
              <TextField
                label="Updated By"
                name="addedDate"
                value={SelectedRowData.updatedBy}
                disabled
                fullWidth
                margin="normal"
              />
            }

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={isEdit ? handleupdate : handleDelete}
                variant="contained"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  textTransform: "none",
                }}
              >
                {isEdit ? 'Update' : 'delete'}
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default RollsAndPermissionTable;
