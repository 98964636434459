import axios from "axios";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios";
const saveFormData = async (formData, type, callback) => {
  console.log("formData", formData);

  try {
    let url;

    if (type === "update") {

      url = `/updatefuels/${formData.fuelID}`;
    } else if (type === "delete") {

      url = `/deletefuels/${formData.fuelID}`;
      // url = `/deletefuels/${formData.fuelID}`;
    } else {

      url = "/addfuels";
    }

    // const response = await axios({
    //   method: type === "update" ? "PUT" : type === "delete" ? "DELETE" : "POST",
    //   url,
    //   data: formData,
    // });

    const methodName = type === "update" ? "put" : type === "delete" ? "delete" : "POST".toLowerCase();
    console.log("Method name:", methodName); 
    const response = await axiosInstance[methodName](url, {
      ...formData
    });


    console.log("response", response)
    // alert(response.data.success)a
    if (response.data.success == false) {
      console.log(response)
      showError(response?.data?.message)
    } else {
      showSuccess(`${type == 'update' ? 'Update' : type == 'delete' ? 'Deleted' : 'Saved'} successfully`);
      callback();


    }
  } catch (error) {
    console.error("Error performing operation:", error);
  }
};

export default saveFormData;
