import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import axiosInstance from "../../../Components/utils/axios";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Grid, MenuItem, Select, Modal, Paper, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip as BarTooltip, Legend } from 'recharts';

function GetEmissionByVendor() {

    const [data, setData] = useState([]);
    const [status, setstatus] = useState("All");
    const [selectedVendorData, setSelectedVendorData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [streamvalue, setstreamValue] = useState('All')
    const [sortBy, setsortBy] = useState("totalDistance");
    const getVehData = async () => {
        try {
            const response = await axiosInstance.get(`/getEmissionsByVendor/${status}/${streamvalue}/${sortBy}`);
            console.log("dsd", response.data); // Display only the first 10 data points initially
            setData(response.data); // Display only the first 10 data points initially
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVehData();
    }, [status, streamvalue, sortBy]);

    const handlePieClick = (vendorData) => {
        setSelectedVendorData(vendorData);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    function CustomTooltip({ active, payload, label }) {
        console.log({ payload });
        if (active && payload && payload.length) {
            return (
                <div className="_custom-tooltip">
                    <span className="label">{`Vendor: ${payload[0].payload._id}`}</span><br />
                    <span className="total-emission">{`Total Distance: ${payload[0].payload.totalDistance} KM`}</span><br />
                    <span className="total-emission">{`Total Emission: ${payload[0].payload.totalEmission?.toFixed(5)} KGCO2e`}</span><br />
                    <span className="average-emission">{`Total Carbon Intensity: ${payload[0].payload.totalCarbonIntensity?.toFixed(5)} KG`}</span><br />
                    <span className="average-emission">{`No of Trips: ${payload[0].payload.tripsCount} `}</span><br />
                </div>
            );
        }
        return null;
    }
    const COLORS = ['#1c486b', '#023E8A', '#0077B6', '#0096C7', '#00B4D8', '#48CAE4', '#90E0EF'];


    const handleStreamChange = (event) => {
        setstreamValue(event.target.value);
    };


    const handleSelctChange = (e) => {

        setsortBy(e.target.value);
        if (e.target.value === "All") {
            handlePieClick(data)

        }
    };

    return (
        <div className='Echart  Em_vendor p-2' >
            <Grid container xs={12} >
                <Grid item xs={9}>
                    <label className='_dash_heading'>Vendor Performance  in<span className="_MANDATORY"> </span></label>
                    <span className='vsm _red'>Unit : {sortBy === "totalEmission" ? 'KGCO2e' : sortBy === "totalDistance" ? 'KM' : 'KG'}</span>

                </Grid>
                <Grid item xs={3}>
                    <Select
                        fullWidth

                        value={sortBy}
                        onChange={(e) => handleSelctChange(e)}
                        required
                        name="ATTRIBUTES"
                    >
                                                <MenuItem value="All">All</MenuItem>
                        <MenuItem value="totalCarbonIntensity">Carbon Intensity</MenuItem>
                        

                        <MenuItem value="totalDistance">Total Distance</MenuItem>
                        <MenuItem value="totalEmission">Total Emission</MenuItem>

                    </Select>
                </Grid>
            </Grid>

            {/* <ButtonGroup
                default
                disableElevation
                variant="contained"
                aria-label="Disabled button group"
                className="docs-button-group"

            >

                <Button
                    onClick={() => setstatus('Completed')}
                    style={{
                        backgroundColor: 'transparent', fontSize: '10px', height: '20px', padding: '8px',
                        color: status === "Completed" ? '#1c486b' : '#000',
                    }}
                >
                    Completed
                </Button>
                <Button
                    onClick={() => setstatus('Progress')}
                    style={{
                        backgroundColor: 'transparent', fontSize: '10px', height: '20px', padding: '5px',
                        color: status === "Progress" ? '#1c486b' : '#000',
                    }}
                >
                    Progress
                </Button>
                <Button
                    onClick={() => setstatus('Cancelled')}
                    style={{
                        backgroundColor: 'transparent', fontSize: '10px', height: '20px', padding: '5px',
                        color: status === "Cancelled" ? '#1c486b' : '#000',
                    }}
                >
                    Cancelled
                </Button>
                <Button
                    onClick={() => handlePieClick()}
                    style={{
                        backgroundColor: 'transparent', fontSize: '10px', height: '20px', padding: '5px',
                        color: isModalOpen ? '#1c486b' : '#000',
                    }}
                >
                    All
                </Button>
            </ButtonGroup> */}

            <div className='df jsb'>
                <PieChart width={560} height={250}>
                    {/* <Pie
                    data={data.slice(0, 10)}
                    dataKey="totalEmission"
                    nameKey="_id"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#1c486b"
                    label
                // onClick={handlePieClick} // Add onClick handler
                /> */}
                    <Pie
                        data={data.slice(0, 5)}
                        // cx={120}
                        // cy={200}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"

                        paddingAngle={5}
                        dataKey={sortBy === "All" ? "totalEmission" : sortBy}
                        nameKey="_id"
                        label

                        formatter={(value) => `${value} KGCO2e`}

                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} label={entry._id} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>

                    <Tooltip formatter={(value, name) => [`${value} KgCO₂e`, name]} content={<CustomTooltip />} />
                </PieChart>
                <div style={{margin:'13px 0 -30px'}}>
                <label className='df' style={{ fontSize: '14px',textAlign:'right',justifyContent:'end' }}>Top 5 Vendors</label>

                    {data?.slice(0,5)?.map((entry, index) => (
                      <p className='mt-2 vendor_legends'><p className='sm_box' style={{background:COLORS[index % COLORS.length]}}></p>{entry._id}</p>
                    ))}
                </div>
            </div>

            <div className='stream  '>
                <FormControl component="fieldset" style={{ FlexDirection: 'row' }} >
                    <RadioGroup value={streamvalue} onChange={handleStreamChange} className="df">
                        <FormControlLabel value="All" control={<Radio />} label="All" />
                        <FormControlLabel value="Upstream" control={<Radio />} label="Upstream" />
                        <FormControlLabel value="Downstream" control={<Radio />} label="Downstream" />
                    </RadioGroup>
                </FormControl>
            </div>
            {data.length === 0 && <span className='_red  mt-2'>No Trips  Found of status :  {status}</span>}

            <Modal open={isModalOpen} onClose={handleCloseModal}>
                <Paper style={{ margin: 'auto 20px', marginTop: 70, width: 'auto', padding: 20 }}>
                    <label>Emission by All Vendors <span className="_MANDATORY"> </span></label>
                    <ScatterChart width={500} height={300} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="_id" />
                        <YAxis style={{ fontSize: '10px' }}
                            label={{ value: 'Emission (KgCO₂e)', angle: -90, position: 'outsideLeft', dx: -20 }}
                            tickFormatter={(value) => `${value}`}
                        />
                        <Scatter name="Total Emission" dataKey="totalEmission" fill="#1c486b" />
                        <Tooltip content={<CustomTooltip />} />
                    </ScatterChart>

                </Paper>
            </Modal>
        </div>
    );
}

export default GetEmissionByVendor;
