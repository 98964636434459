import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import saveFormData from "./saveFormData";
import { showSuccess, showError } from '../../toast';
import DatePicker from "react-datepicker";
import { Button, Container, FormControl, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import axiosInstance from "../../Components/utils/axios";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadIcon from '@mui/icons-material/Download';
import "./DriverTable.css"
import { setDriver } from "mongoose";
import CountrySelect from "../../Components/utils/CountryCode";
  import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const AddNewDriver = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action") || "";
  const DRIVERID = searchParams.get("search") || "";

 

  const [driverId, setDriverId] = useState(DRIVERID == "" ? "" : null);
  const [driverName, setDriverName] = useState("");
  const [vendor, setvendor] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [rating, setrating] = useState(0);
  const [noOfTrips, setnoOfTrips] = useState(0);
  const [yearsofwork, setyearsofwork] = useState(0);
  const [licenseexpireDate, setlicenseexpireDate] = useState("");
  const [phone, setphone] = useState("");
  const [Image, setImage] = useState("");
  const [profile, setprofile] = useState("");
  const [CountryCode, setCountryCode] = useState("91")

  const [vendorData,setvendorData] = useState([])


  const generateRandom4DigitNumber = async () => {
    try {
        const response = await axiosInstance.get("/getDriver");
        // debugger
        const sortedData = response.data?.reverse()

        const latestIndustry = sortedData[0];
        console.log("driver", sortedData)
        let id = 1;
        if (latestIndustry) {
            const latestID = latestIndustry.DriverID;
            id = parseInt(latestID.substring(3)) + 1;
        }
        return `DVR${id}`;
    } catch (error) {
        console.error("Error fetching latest industry ID:", error);
        return `DVR0`;
    }
};

useEffect(() => {
  const fetchData = async () => {
      const randomID = await generateRandom4DigitNumber();
      setDriverId(randomID)
  };

  fetchData();
}, []);


  const handleFileUpload = async (file) => {
    // // debugger;
    console.log(file)
    try {
      const formData = new FormData();
      formData.append("DriverIDfile", file);
      console.log("formData", formData)
      const response = await axiosInstance.post(
        "/uploadDriverID",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response?.data)
      return response?.data?.imageurl?.Location


    } catch (error) {
      console.log(error)
    }
  };

  const validateForm = () => {
    if (!driverName) {
      showError("Please Enter the Driver Name ");
      return false;
    }
    if (!vendor) {
      showError("Please Select the Vendor Name ");
      return false;
    }
    if (!licenseNumber) {
      showError("Please Enter the License Number ");
      return false;
    }
    if (!CountryCode || !phone) {
      showError("Please Enter the Valid Phone Number");
      return false;
    }
    return true;
  };


  const handleClickSubmit = async (e) => {
    e.preventDefault();
    // alert(yearsofwork)
    console.log("id", Image);
    console.log("p", profile);
    const isValid = validateForm();
    if (isValid) {
      // // debugger;    
      saveFormData({
        DriverID: driverId,
        vendor,
        profilePic: profile,
        DriverName: driverName,
        LicenseNumber: licenseNumber,
        licenseExpirationDate: licenseexpireDate,
        countrycode: CountryCode,
        PhoneNumber: phone,
        ratings: rating,
        years: yearsofwork,
        noOfTrips: noOfTrips,
        DriverIDfile: Image,
        createdBy: localStorage.getItem('user_id'),
        Company :localStorage.getItem('COMPANY')
      }, action,DRIVERID, () => {
        navigate("/DriverMaster");
      });


    }


  };

  const containerStyle = {
    display: "flex",
  };



  const formStyle = {
    padding: "10px",
    borderRadius: "10px",
    width: '67%',
    margin: "none",
    height: "313px",
    backgroundColor: "white",
  };


  const get_trip = async () => {
    await axiosInstance.get("/getDriver")
      .then((response) => {
        console.log("driver", response.data);

        const selectedDVR = response.data.find((id) => id._id === DRIVERID);
        console.log("filter driver", selectedDVR);
        setDriverId(selectedDVR.DriverID)
      
        setDriverName(selectedDVR.DriverName)
        setLicenseNumber(selectedDVR.LicenseNumber)
        setvendor(selectedDVR.vendor)
        setphone(selectedDVR.PhoneNumber)
        setImage(selectedDVR.DriverIDfile)
        setprofile(selectedDVR.profilePic)
        setlicenseexpireDate(new Date(selectedDVR.licenseExpirationDate))
        setCountryCode(selectedDVR.countrycode)
        setnoOfTrips(selectedDVR.noOfTrips)
        setyearsofwork(selectedDVR.years)
        setrating(selectedDVR.ratings)
        //  alert(selectedDVR.countrycode)

        // setTripData({
        //   ...tripData,
        //   ...selectedtrip,
        // });

      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };


  const get_Vendor = async () => {
    await axiosInstance.get("/vendors")
      .then((response) => {
        console.log("vendors", response);
        setvendorData(response.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  useEffect(()=>{
    get_Vendor()
  },[])

  useEffect(() => {
    get_trip()
  }, [DRIVERID])

  const handleCountryChange = (option) => {
    // alert(option)
    setCountryCode(option)
  }

  const handleFileChange = async (file, name) => {
    // // debugger;
    // alert(name)
    const a = await handleFileUpload(file)
    if (name == "DriverIDfile") {
      setImage(a);
    }
    else {
      setprofile(a)
    }


  };

  const handleBrowseClick = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleDateChange = (date) => {
    // alert(date)
    setlicenseexpireDate(date)
  };

  return (
    <div style={containerStyle}>
      <div >
        <Sidebar />
      </div>

      <div style={{ width: "100%", margin: '10px' }}>
        <Header />
        <div >
          <div className="table-heading theme_c"><span onClick={() => navigate('/DriverMaster')}><ArrowBackIcon /></span>{action ? action + '>' :'  Add New'} Driver   <span className={action ?`_${action}`:''}>{` ${driverName}`}</span></div>
          <Paper elevation={3} style={{ padding: "20px", marginTop: "10px", overflow: 'scroll', height: '89vh' }}>
            <form autoComplete="off" className="driverform">

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <label htmlFor="driverId">Driver ID</label>
                  <br />
                  <TextField
                    fullWidth
                    id="driverId"
                    value={driverId}
                    onChange={(e) => setDriverId(e.target.value)}
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col2">Associated Vendor <span className="_MANDATORY">*</span></label>
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="vendor"
                    value={vendor || ''}
                    onChange={(e) => setvendor(e.target.value)}
                  >
                    {vendorData.map((fuel, i) => (
                      <MenuItem key={i} value={fuel._id} className="">
                        <span>
                          {fuel.name}&nbsp;
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
                <Grid item xs={4}>
                  <label >Profile Photo</label>
                  <br />
                  {
                    profile == "" ?
                      <TextField
                        type="file"
                        name="profilePic"
                        variant="outlined"
                        onChange={(e) => handleFileChange(e.target.files[0], 'profilePic')}
                      />
                      :
                      profile?.includes('.pdf') ?
                        <p className='docs-viewer-item'>{` ${profile?.split("/").pop()}`}  <a target='_blank' href={profile}><DownloadIcon /></a></p>
                        :
                        <img src={profile} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                  }

                </Grid>

                <Grid item xs={4}>
                  <label htmlFor="driverName">Driver Name  <span className="_MANDATORY">*</span></label>
                  <br />
                  <TextField
                    fullWidth

                    id="driverName"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                    required
                  />

                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="licenseNumber">License Number <span className="_MANDATORY">*</span></label>
                  <br />
                  <TextField
                    fullWidth
                    type="text"
                    id="licenseNumber"
                    value={licenseNumber?.slice(0,24)}
                    autoComplete="off"
                    onChange={(e) => setLicenseNumber(e.target.value)}
                    required
                  />
                
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="licenseNumber">License Expiration date <span className="_MANDATORY">*</span></label>
                  <br />
                  <DatePicker
                    selected={licenseexpireDate}
                    // value={licenseexpireDate}
                    onChange={(date) => handleDateChange(date)}
                    // maxDate={new Date()} // Disable future dates
                    customInput={
                      <TextField
                        variant="outlined"
                        // placeholder="2244"
                        fullWidth
                        id="col2"
                        name="Date"
                        value={licenseexpireDate}
                        inputProps={{ readOnly: true }} 
                      // placeholder="DD-MM-YYYY"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="licenseNumber">Phone Number <span className="_MANDATORY">*</span></label>
                  <br />
                  <div className="df jsb">
                    <CountrySelect id="newUserCNCode" width={170} CountryCode={CountryCode} onChange={handleCountryChange} />
                    <TextField
                      sx={{ width: "300px", height: "39px" }}
                      id="licenseNumber"
                      value={phone?.toString()?.slice(0,11)}
                      type="number"
                      onChange={(e) => setphone(e.target.value)}
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="licenseNumber">Ratings </label>
                  <br />
                  <TextField
                    fullWidth
                    type="number"
                    id=""
                    value={rating}
                    onChange={(e) => setrating(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor="licenseNumber">No of Trips  </label>
                  <br />
                  <TextField
                    fullWidth
                    type="number"
                    id=""
                    value={noOfTrips}
                    onChange={(e) => setnoOfTrips(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <label htmlFor=""> Years of Work </label>
                  <br />
                  <TextField
                    fullWidth
                    type="number"
                    id=""
                    value={yearsofwork}
                    onChange={(e) => setyearsofwork(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} className="mt-3">
                  <label htmlFor="col8">Driver ID Upload</label>
                  <div
                    style={{
                      width: "100%",
                      padding: "35px",
                      border: "2px dotted lightgrey",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      borderRadius: "12px",
                    }}
                  >
                    <label>
                      {Image == "" ? `Drag and Drop or` : <img style={{ width: '100%' }} src={Image} />}
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        name="DriverIDfile"
                        onChange={(e) => handleFileChange(e.target.files[0], 'DriverIDfile')}

                      />
                      {Image == "" &&
                        <Button
                          sx={{
                            backgroundColor: "rgba(192, 192, 192, 1)",
                            color: "white",
                          }}
                          onClick={(e) => handleBrowseClick(e)}
                        >
                          browse
                        </Button>}
                    </label>
                    
                    <span className="mt-3" style={{fontSize:'10px'}}>Accepted File Types ( png, jpg, docx)</span>
                    <input
                      type="file"
                      name="image"

                      accept="*"
                      style={{ display: "none" }}
                    />

                  </div>
                </Grid>
                <Grid item xs={4}>
                  <button
                  className="a"
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      width: "280px",
                      padding: "12px",
                      margin: '27px 0',
                      // height: "40px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                    onClick={handleClickSubmit}
                  >
                    {action == "" ? `Submit` : action.toUpperCase()}
                  </button>
                </Grid>
              </Grid>

            </form>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default AddNewDriver;
