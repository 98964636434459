import React, { useState } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

export default function CustomButtonGrp({setmodule}) {
  const [activeButton, setActiveButton] = useState(1); // State to track the active button

  const handleButtonClick = (index ,module) => {
    setmodule(module)
    setActiveButton(index); // Update the active button index
  };

  return (
    <ButtonGroup
      default
      disableElevation
      variant="contained"
      aria-label="Disabled button group"
      className="docs-button-group" // Apply custom styles using CSS
    >
   
      <Button
        onClick={() => handleButtonClick(1,'All')}
        style={{
          backgroundColor:  'transparent',
          color: activeButton === 1 ? '#007bff' : '#000',
        }}
      >
        Admin
      </Button>
      <Button
        onClick={() => handleButtonClick(2,'Driver')}
        style={{
          backgroundColor:  'transparent',
          color: activeButton === 2 ? '#007bff' : '#000',
        }}
      >
        Driver
      </Button>
    </ButtonGroup>
  );
}
