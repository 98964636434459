import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Typography, Select, MenuItem, InputLabel } from '@mui/material';
import { types } from 'util';
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import { showSuccess, showError } from "../../toast";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import axiosInstance from "../../Components/utils/axios"

const DynamicForm = ({ formData, mode, onSubmit, masterPage ,setaddmode}) => {
  const [formValues, setFormValues] = useState(formData);
  const [masterConfig, setMasterConfig] = useState(null);



  const HandleBack =()=>{
    // alert(mode)
    if(mode === "add"){
      setaddmode(false)
    }
    
  }

// for Geting all CONFIG
  useEffect(() => {
   console.log(formData)
    const fetchMasterPage = async () => {
      // // debugger;
      const masterConfigData = await masterPage();
      console.log(masterConfigData)
      setMasterConfig(masterConfigData);
    };

    fetchMasterPage();
  }, [masterPage]);

  // For mode VIEW || ADD || UPDATE
  useEffect(() => {
    if (mode === 'add') {
      setFormValues({});
    } else {
      setFormValues(formData);
    }
  }, [formData, mode]);

  const handleInputChange = async (key, value) => {
    console.log(masterConfig[key]?.type)
    if (masterConfig[key]?.type === 'file') {
      console.log(value)
      const fileUrl = await uploadFile(value);
      console.log(fileUrl)
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: fileUrl,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    }
  };

  const uploadFile = async (file) => {
    try {
      console.log(file)
      const formData = new FormData();
      formData.append("pucFile", file);

      const response = await axiosInstance.post(
        "/uploadPUCFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("uploads", response)
      return response?.data?.imageurl?.Location;
    } catch (error) {
      console.log(error);
      // Handle error appropriately (e.g., show an error message)
      return null;
    }
  };




  const formatDate = (date) => {
    if (typeof date === 'string') {
      const [month, day, year] = date.split('/');
      return `${year}-${month}-${day}`;
    } else if (date instanceof Date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return date.toLocaleDateString(undefined, options);
    }

    return date;
  };

  // only for datepickers in MODULE
  const handleDateChange = (key, date) => {
    if (date instanceof Date) {
      const formattedDate = formatDate(date);
      setFormValues((prevValues) => ({
        ...prevValues,
        [key]: formattedDate,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#dfedd1",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const renderInputField = (key, fieldType, config) => {
    if (!config || fieldType === "none" ) {
      return null;
    }
    // console.log("Ds")
    // console.log(config)
    // alert(fieldType)

    switch (fieldType) {
      case 'select':

      const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (config[key]?.onChange) {
          config[key].onChange(key, selectedValue);
          console.log(key, selectedValue);
        }
        handleInputChange(key, selectedValue);
      };

        return (
          <Grid item xs={4} key={key}>
            <InputLabel>{config[key]?.label}</InputLabel>
            <Select
              fullWidth
              value={formValues[key]}
              onChange={handleSelectChange}
              required
              disabled={mode === 'view'}
            >
              {config[key]?.options.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        );
      case 'datepicker':
        return (
          <Grid item xs={4} key={key}>
            <InputLabel>{config[key]?.label}</InputLabel>
            <DatePicker
              style={{ width: '100%' }}
              selected={formValues[key] ? new Date(formValues[key]) : null}
              onChange={(date) => handleDateChange(key, date)} 
              dateFormat="dd-MM-yyyy"
              maxDate={new Date()}
              customInput={
                <TextField
                  variant="outlined"
                  value={formValues[key]}
                  disabled={mode === 'view'}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                />
              }
            />
          </Grid>
        );
      case 'switch':
        return (
          <Grid item xs={4} key={key} >
            <FormControlLabel style={{ padding: '20px' }}
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  checked={formValues[key]}
                  defaultChecked={formValues[key]}
                  onChange={(e) => handleInputChange(key, !formValues[key])}
                />
              }
              label={`${formValues[key] ? 'NEW' : 'OLD'} VEHICLE`}
              variant="outlined"
            />
          </Grid>

        );
      case 'file':
        const fileUrl = formValues[key];


        if (key === 'polutionFile' && !formValues['vehicleCondition']) {
          return null;
        }


        return (
          <Grid item xs={4} key={key}>
            <Typography>{config[key]?.label}</Typography>
            {fileUrl ? (
              <img
                src={fileUrl}
                alt="Uploaded File"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            ) : (
              <TextField
                type='file'
                fullWidth
                value={formValues[key]}
                onChange={(e) => handleInputChange(key, e.target.files[0])}
                required
                disabled={mode === 'view' || config[key]?.disabled}
              />
            )}
          </Grid>
        );
        case 'number':
          return (
            <Grid item xs={4} key={key}>
              <InputLabel>{config[key]?.label}</InputLabel>
              <TextField
                fullWidth
                type="number" 
                value={formValues[key]}
                onChange={(e) => handleInputChange(key, e.target.value)}
                required
                disabled={mode === 'view' || config[key]?.disabled}
              />
            </Grid>
          );
      default:
        return (
          <Grid item xs={4} key={key}>
            <Typography>{config[key]?.label}</Typography>
            <TextField
              fullWidth
              value={formValues[key]}
              onChange={(e) => handleInputChange(key, e.target.value)}
              required
              disabled={mode === 'view' || config[key]?.disabled}
            />
          </Grid>
        );
    }
  };

  if (masterConfig === null) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      {mode === "add" && <p onClick={()=>HandleBack()}>⬅️</p> }
      
      <Grid container spacing={2}>
        {Object.keys(masterConfig).map((key) => renderInputField(key, masterConfig[key]?.type, masterConfig))}
        <Grid item xs={4}>
          <Button
            style={{
              color: 'white',
              backgroundColor: 'black',
              width: '280px',
              padding: '12px',
              margin: '27px 0',
              borderRadius: '5px',
              textAlign: 'center',
            }}
            type="submit"
            disabled={mode === 'view'}
          >
            {mode === 'edit' ? 'Save' : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DynamicForm;
