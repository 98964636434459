import React, { useEffect, useState } from 'react';
import DynamicForm from './DynamicForm';

function DetailsCard({ data, pdfs }) {
  const [editMode, setEditMode] = useState(false);


  useEffect(()=>{
console.log("--->",data)
  },[])

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSave = (updatedData) => {
    // Handle save logic here if needed
    console.log('Updated Data:', updatedData);
    setEditMode(false);
  };

  const keysToHide = ['__v', '_id'];
  const visibleKeys = Object.keys(data).filter((key) => !keysToHide.includes(key));

  return (
    <div className='Details_CARD'>
      {editMode ? (
        <DynamicForm formData={data} mode="edit" onSubmit={handleSave} />
      ) : (
        <>
          <div className="details-card">
            {visibleKeys.map((key) => (
              <div key={key} className="details-row">
                <span className="details-key" >{key}:</span>
                <span className="details-value">{data[key]}</span>
              </div>
            ))}
            <div className="details-actions">
              <button className="edit-button" onClick={handleEditClick}>
                Edit
              </button>
              <button className="delete-button">Delete</button>
            </div>
          </div>
          <div className='Docs_Viewer'>
            <img src={data?.DriverIDfile} alt="Driver ID" />
          </div>
        </>
      )}
      {editMode && (
        <button className="cancel-button" onClick={handleCancelEdit}>
          Cancel
        </button>
      )}
    </div>
  );
}

export default DetailsCard;
