import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axiosInstance from "../../Components/utils/axios";

import { Button, TextField } from "@mui/material";
import OTPInput from "otp-input-react";
import { showError, showSuccess } from "../../toast";
import CountrySelect from "../utils/CountryCode";
import { BsFillShieldLockFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginPageContainer = styled.div`
  display: flex;
  height: 100vh;

`;

const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F5F6FA;
`;

const LoginForm = styled.form`
  width: 100%;
  max-width: 400px;
  font-size:14px;
  padding: 20px;
  border: 1px solid #fff;
  background:#fff;
  border-radius: 8px;
`;
const NirantaraContainer = styled.div`
  flex: 1;
  background-color: #F5F6FA;
  display: flex;
margin-top:30px;
  justify-content:center;
 
 
`;

const LoginPage = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [countryCode, setCuntryCode] = useState("")

  const navigate = useNavigate();

  const accessModule = {
    access: [
      "Dashboard",
      "Trips",
      {
        "name": "Setup",
        "submenus": [
          "Customer Facility",
          "Roles & Permissions",
          "Destination Master",
          "Vendor Master",
          {
            "name": "Vendor Master",
            "submenus": [
              "Vehicle Master",
              "Driver Master"
            ]
          }
        ]
      },
      "CO2 Calculator",
      "Support",
      "Documentation"
    ]
  };




  const handleLogin = async (e) => {
    e.preventDefault();

    // alert(`${countryCode}`+`${mobileNumber}`)

    try {
      if (countryCode == "") {
        showError('Please Select the Country Code')
      }
      if (mobileNumber.trim().length < 10) {
        showError("Please Enter a Valid Mobile Number ");
        return;
      }

      const response = await axiosInstance.post("/login", {
        mobilenumber: mobileNumber,
        countrycode: countryCode,
      });

      if (response.status === 200) {
        localStorage.setItem("token", `${response.data.accessToken}`);
        showSuccess("User Verified ");
        showSuccess(`Sending OTP : +${countryCode + mobileNumber} `);

        handleSendOTP({ mobile: mobileNumber })

      } else if (response.status === 400) {
        showError("Login Failed. Please check your credentials.");
      } else {
        showError("An unexpected error occurred during login.");
      }
    } catch (error) {
      console.log("An error occurred during login:", error);
      showError(error.response?.data?.message || "Login failed");
    }
  };

  const handleSendOTP = async ({ mobile }) => {
    try {
      // alert(mobile)
      const response = await axiosInstance.post("/sendotp", {
        mobilenumber: mobile,
        countrycode: countryCode,
      });
      console.log(",,,,", response)
      if (response.status == 200) {
        showSuccess("OTP sent successfully");
        setShowOTP(true);
      } else {
        showError("Failed to send OTP");
      }
    } catch (error) {
      console.log("Error sending OTP:", error);
      showError(error.response?.data?.message || "Failed to send OTP");
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      // alert(otp)
      const response = await axiosInstance.post("/verifyotp", {
        mobilenumber: mobileNumber,
        enteredOtp: otp,
      });
      // // debugger
      console.log("response", response)
      // alert("get res")
      if (response.status == 200) {
        showSuccess("OTP verified ssuccessfully");
        localStorage.setItem("token", response?.data.accessToken);
        localStorage.setItem("welcome-NIRANARA", `${response?.data.accessroles.roleName} - ${response.data.user.name}`);
        
        localStorage.setItem("user_id", `${response.data.user?._id}`);
        localStorage.setItem("COMPANY", `${response.data.user?.Company?._id}`);
        showSuccess("Login success");
        const USER_ROLE = response?.data.accessroles?.roleName;
        const roleAccessPages = response?.data.accessroles?.access;
        const User_Details = response?.data.user;

        localStorage.setItem("access", JSON.stringify(roleAccessPages));
        localStorage.setItem("_USER", JSON.stringify(User_Details));
        LoginWith(USER_ROLE)
      } else {
        showError("Failed to verify OTP");
      }
    } catch (error) {
      console.log("Error verifying OTP:", error);
      // LoginWith(error.response?.data?.message || "Failed to verify OTP");
    }
  };

  const moduleMapping = {
    "Dashboard": { route: "Dashboard", logo: '', displayName: "Dashboard", addroutenames: ["Dashboard"] },
    "Trips": { route: "trips", logo: 'triplogo', displayName: "Trips", addroutenames: ["trips", "addnewtrip"] },
    "Industry": { route: "industry", logo: 'triplogo', displayName: "Industry", addroutenames: ["industry", "AddNewindustry"] },
    "Billing": { route: "Billing", logo: 'triplogo', displayName: "Industry", addroutenames: ["Billing", "AddNewBill"] },
    "Goods & Services": { route: "purchaseGoods", logo: 'triplogo', displayName: "Goods & Services", addroutenames: ["purchaseGoods", "AddNewpurchaseGoods"] },
    "Customer Facility": { route: "customerfacility", logo: 'driver', displayName: "Customer Facility", addroutenames: ["customerfacility", 'AddnewCustomerfacility'] },
    "Roles & Permissions": { route: "rollsandpermission", logo: 'permission', displayName: "Roles & Permissions", addroutenames: ["rollsandpermission"] },
    "Destination Master": { route: "locationmaster", logo: 'location', displayName: "Destination Master", addroutenames: ["locationmaster", 'AddNewLocationMaster'] },
    "Fuel Master": { route: "fuelmaster", logo: 'fuel', displayName: "Fuel Master", addroutenames: ["fuelmaster", 'AddNewFuelType'] },
    "Vendor Master": { route: "vendorMaster", logo: 'driver', displayName: "Vendor Master", addroutenames: ["vendorMaster"] },
    "Vehicle Master": { route: "VehicleMaster", logo: 'vehicle', displayName: "Vehicle Master", addroutenames: ["VehicleMaster", 'AddNewVehicleMaster'] },
    "Driver Master": { route: "DriverMaster", logo: 'driver', displayName: "Driver Master", addroutenames: ["DriverMaster", "AddNewDriverMaster"] },
    "CO2 Calculator": { route: "co2calculatorpage", logo: 'co2', displayName: "CO2 emission estimator", addroutenames: ["co2calculatorpage"] },
    "Support": { route: "SupportPage", logo: 'support', displayName: "Support", addroutenames: ["SupportPage"] },
    "Documentation": { route: "Documentation", logo: 'documentation', displayName: "Documentation", addroutenames: ["Documentation"] }
  };

  const LoginWith = (ROLE) => {
    if (ROLE === "ADMIN") {
      navigate("/Dashboard");
    }
    else {
      const FIRSTROUTE = JSON.parse(localStorage.getItem("access"))[0]
      navigate(`/${moduleMapping[FIRSTROUTE]?.route}`);
    }

  }

  useEffect(() => {
    setIsFormValid(!!mobileNumber);
  }, [mobileNumber]);


  async function fetchCountryCode() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      const ipAddress = data.ip;
      const locationResponse = await fetch(`https://ipapi.co/${ipAddress}/json/`);
      const locationData = await locationResponse.json();
      const countryCode = locationData.country_calling_code;
      return countryCode;
    } catch (error) {
      console.error('Error fetching country code:', error);
      return null;
    }
  }


  useEffect(() => {

    fetchCountryCode()
      .then(cc => {
        // alert( ` cc :${cc}`);
        setCuntryCode(cc)
      })
      .catch(error => {
        console.error(error);
      });

  }, [])

  const handleCountryChange = (option) => {
    console.log(option)
    setCuntryCode(option)

  }

  return (
    <LoginPageContainer>
      {/*  */}
      <div className="login_logo">
        <img
          src="/logoo.png"
          style={{ width: "200px" }}
          alt="Nirantara Poster"
        />
      </div>
      <NirantaraContainer className="loginLeft">

        <img
          src="/loginVector.svg"
          style={{ width: "70%" }}
          alt="Nirantara Poster"
        />
      </NirantaraContainer>
      <LoginContainer>
      
     <div>
     <h4 ><b>Welcome to Nirantra !</b></h4>
     <br/>

        <LoginForm onSubmit={showOTP ? handleVerifyOTP : handleLogin}>
          
          <h4 style={{ marginTop: "5%" }}>
            {showOTP ? "Verify OTP" : "Account Login"}
          </h4>
          <h6 style={{ color: "#696F79" }}>
            {showOTP
              ? "Enter the OTP sent to your mobile number"
              : "Login with your registered mobile number"}
          </h6>

          <label
            htmlFor="mobileNumber"
            style={{ color: "#696F79", marginTop: "7%" }}
          >
            Mobile Number
          </label>
          <br />
          <div className="df jsb _form_input">
            <CountrySelect id="loginCountrySelect" width={170} CountryCode={countryCode || '91'} onChange={handleCountryChange} />

            <input
              style={{
                marginBottom: "10px",
                width: "100%",
                boxSizing: "border-box",
              }}

              type="number"

              maxLength={10}
              id="mobileNumber"
              placeholder="Mobile Number"
              value={mobileNumber?.length < 10 ? mobileNumber : mobileNumber?.slice(0, 10)}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>
        
          {showOTP && (
            <div className="otp_box">
              <label htmlFor="otp" style={{ color: "#696F79" }}>
                OTP
              </label>
              <br />
              <OTPInput
                value={otp}
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onChange={(otp) => setOtp(otp)}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                secure
              />
            </div>
          )}
          <br />
          <Button
            className="login-btn"
            type="submit"
            style={{
              width: "100%",
              padding: "12px",
              fontSize: "16px",
              backgroundColor: "black",
              color: "white",
              borderRadius: "5px",
            }}
            disabled={!isFormValid}
          >
            {showOTP ? "Verify OTP" : "Send OTP"}
          </Button>
          {!showOTP && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
                marginTop: "5px",
              }}
            >
              <div onClick={handleSendOTP}>Resend OTP</div>
            </div>
          )}
        </LoginForm>
     </div>
      </LoginContainer>
    </LoginPageContainer>
  );
};

export default LoginPage;
