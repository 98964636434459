import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Sidebar.css";
import nirantara from "../../utils/nirantaralogo.png";
import dashboardlogo from "../../utils/_dashboard.svg";
import triplogo from "../../utils/_trip.svg";
import location from "../../utils/_location.svg";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SettingsIcon from '@mui/icons-material/Settings';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; import vehicle from "../../utils/_vehicle.svg";
import fuel from "../../utils/_fuel.svg";
import driver from "../../utils/_driver.svg";
import co2 from "../../utils/_co2.svg";
import permission from "../../utils/_roles.svg";
import support from "../../utils/_support.svg";
import documentation from "../../utils/_documents.svg";
import arrowright from "../../utils/arrowright.png";

const Sidebar = ({ setActiveButton }) => {
  const Navigate = useNavigate();
  const lc = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSubmenus, setActiveSubmenus] = useState({});
  const [open, setopen] = useState(true);

  const access = localStorage.getItem('access');
  const accessModule = {
    access: JSON.parse(access)
  };

  // console.log(access)




  const handleSubMenuToggle = (submenuName) => {
    // alert(open)
    if (open) {
      toggleSidebarWidth(210)
      setopen(false);
    }

    // alert(open)
    // setopen(false);
    // alert(submenuName)
    if (moduleMapping[submenuName]?.route === 'vendorMaster') {
      Navigate(`/${moduleMapping[submenuName]?.route}`)

    }
    setActiveSubmenus(prevState => ({
      ...prevState,
      [submenuName]: !prevState[submenuName]
    }));
  };

  const moduleMapping = {
    "Dashboard": { route: "Dashboard", logo: dashboardlogo, displayName: "Dashboard", addroutenames: ["Dashboard"] },
    "Trips": { route: "trips", logo: triplogo, displayName: "Trips", addroutenames: ["trips", "addnewtrip"] },
    "Industry Process": { route: "industry", logo: triplogo, displayName: "Industry", addroutenames: ["industry", "AddNewindustry"] },
    "Billing": { route: "Billing", logo: triplogo, displayName: "Industry", addroutenames: ["Billing", "AddNewBill"] },
    "Company OnBoard": { route: "CompanyMaster", logo: permission, displayName: "Company OnBoard", addroutenames: ["CompanyMaster", "AddnewCompanyMaster"] },
    "Purchase Goods & Services": { route: "purchaseGoods", logo: triplogo, displayName: "Purchase Goods & Services", addroutenames: ["purchaseGoods", "AddNewpurchaseGoods"] },
    "Customer Facility": { route: "customerfacility", logo: driver, displayName: "Customer Facility", addroutenames: ["customerfacility", 'AddnewCustomerfacility'] },
    "Roles & Permissions": { route: "rollsandpermission", logo: permission, displayName: "Roles & Permissions", addroutenames: ["rollsandpermission"] },
    "Destination Master": { route: "locationmaster", logo: location, displayName: "Destination Master", addroutenames: ["locationmaster", 'AddNewLocationMaster'] },
    "Fuel Master": { route: "fuelmaster", logo: fuel, displayName: "Fuel Master", addroutenames: ["fuelmaster", 'AddNewFuelType'] },
    "Vendor Master": { route: "vendorMaster", logo: driver, displayName: "Vendor Master", addroutenames: ["vendorMaster", 'AddnewVendorMaster'] },
    "Vehicle Master": { route: "VehicleMaster", logo: vehicle, displayName: "Vehicle Master", addroutenames: ["VehicleMaster", 'AddNewVehicleMaster'] },
    "Driver Master": { route: "DriverMaster", logo: driver, displayName: "Driver Master", addroutenames: ["DriverMaster", "AddNewDriverMaster"] },
    "CO2 Calculator": { route: "co2calculatorpage", logo: co2, displayName: "CO2 emission estimator", addroutenames: ["co2calculatorpage"] },
    "Support": { route: "SupportPage", logo: support, displayName: "Support", addroutenames: ["SupportPage"] },
    "Documentation": { route: "Documentation", logo: documentation, displayName: "Documentation", addroutenames: ["Documentation"] },
    "Invoices": { route: "Invoices", logo: documentation, displayName: "Invoices", addroutenames: ["Invoices"] },
  };


  useEffect(() => {
    const currentPage = Object.values(moduleMapping).find(item => item.addroutenames?.includes(lc.pathname.slice(1)))?.route;

    if (currentPage == "locationmaster" || currentPage == "rollsandpermission" || currentPage == "AddnewCustomerfacility" || currentPage == "customerfacility" || currentPage == "vendorMaster" || currentPage == "industry" || currentPage == "purchaseGoods") {

      setActiveSubmenus(prevState => ({
        ...prevState,
        "Setup": true
      }));
    }

    if (currentPage == "DriverMaster" || currentPage == "VehicleMaster") {
      setActiveSubmenus(prevState => ({
        ...prevState,
        "Setup": true,
        "Vendor Master": true
      }));
    }

    if (open) {
      toggleSidebarWidth(210)
      setopen(false);
    }

    if (window.location.pathname === "/Dashboard" && open) {
      toggleSidebarWidth(210)
      // setopen(true);
    }



  }, [isSidebarOpen, lc.pathname]);


  const HandleLinkClick = (e) => {
    e.stopPropagation();
    setIsSidebarOpen((prev) => !prev);
  };



  const handleToggleArrow = (name) => {
    // alert(name)
  }

  function toggleSidebarWidth(width) {
    const sidebar = document.querySelector('.sidebar');
    let targetWidth
    if (width) {
      targetWidth = width;
    }
    else {
      targetWidth = sidebar.offsetWidth === 68 ? 210 : 68;

    }
    setTimeout(() => {
      sidebar.style.width = `${targetWidth}px`;
    }, 10); // Adjust delay as needed
  }


  return (
    <div
      data-color="#111"
      className={`sidebar  ${isSidebarOpen ? "open" : ""}`}
      style={{ backgroundColor: "#fff", boxShadow: '0 0 5px 0 rgba(0,0,0,0.4)' }}
    >
      <div className={'logo'} onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <img src={`${open ? 'https://cdn.builder.io/api/v1/image/assets/TEMP/d3488f1ad368f7400191add3e4d9a5d3e37f60d92cda1332c152f2d764d39857?apiKey=b3217365a35d45c196e47ed20ac8b87c&' : '/logoo.png'}`} alt="Nirantara Logo" />
      </div>
      <ul className={`hidden_scrollbar ${open}`} style={{ height: '86vh', overflowY: 'scroll' }}>
        <br />
        <span className={`burger ${open ? 'open' : ''}`} onClick={() => {
          setopen(prevOpen => !prevOpen);
          toggleSidebarWidth(open ? 210 : 68);
        }}>|||</span>
        {open && <><br /></>}

        {accessModule?.access.map((menuItem, index) => (
          <li key={index} onClick={typeof menuItem === 'string' ? () => Navigate(`/${moduleMapping[menuItem]?.route}`) : undefined}
            className={moduleMapping[menuItem]?.addroutenames?.includes(lc.pathname.slice(1)) && 'active'}>
            {typeof menuItem === 'string' ? (
              <span

                className=""
                style={{ color: "#6F6B7D", textDecoration: "none", cursor: "pointer" }}
                onClick={() => Navigate(`/${moduleMapping[menuItem]?.route}`)}
              >
                {moduleMapping[menuItem]?.logo && (
                  <img src={moduleMapping[menuItem].logo} alt={menuItem} />
                )}
                &nbsp; &nbsp;
                <span className={open && 'sidebar_Text'} style={{ letterSpacing: `${menuItem === "CO2 Calculator" ? '-0.3px' : null}` }}>{menuItem === "CO2 Calculator" ? 'CO2 Emission Estimator' : menuItem}</span>
              </span>
            ) : (
              <>
                <div
                  onClick={() => handleSubMenuToggle(menuItem.name)}
                  className={`main-menu df jsb `}
                >
                  <span ><SettingsIcon /> &nbsp;<span className={open && 'sidebar_Text'}> {menuItem.name}</span></span>
                  <span className="">{activeSubmenus[menuItem.name] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</span>
                </div>
                {activeSubmenus[menuItem.name] && (
                  <ul className="sub-menu">
                    {menuItem.submenus.map((submenuItem, subIndex) => (
                      <li key={subIndex}
                        className={moduleMapping[submenuItem]?.addroutenames?.includes(lc.pathname.slice(1)) && 'active'}>
                        {typeof submenuItem === 'string' ? (
                          <span
                            onClick={(e) => { Navigate(`/${moduleMapping[submenuItem]?.route}`); HandleLinkClick(e); }}
                            style={{ color: "#ddd", textDecoration: "none", cursor: "pointer" }}
                          >

                            {moduleMapping[submenuItem]?.logo && (
                              <img src={moduleMapping[submenuItem].logo} alt={submenuItem} />
                            )}
                            &nbsp;
                            <span className={open && 'sidebar_Text'} >{submenuItem}</span>
                          </span>
                        ) : (
                          <>
                            <div
                              onClick={() => handleSubMenuToggle(submenuItem.name)}

                              className={`sub-menu-toggle df jsb `}
                            >
                              <span><img src={fuel} />  {submenuItem.name}</span>
                              <span>{activeSubmenus[submenuItem.name] ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}</span>
                            </div>
                            {activeSubmenus[submenuItem.name] && (
                              <ul className="sub-menu mt-3">
                                {submenuItem.submenus.map((nestedSubmenuItem, nestedIndex) => (
                                  <li onClick={() => Navigate(`/${moduleMapping[nestedSubmenuItem]?.route}`)}
                                    key={nestedIndex} className={moduleMapping[nestedSubmenuItem]?.addroutenames?.includes(lc.pathname.slice(1)) && 'active'}>
                                    <span
                                      className=""

                                      style={{ color: "#6F6B7D", textDecoration: "none", cursor: "pointer" }}
                                    >
                                      {moduleMapping[nestedSubmenuItem]?.logo && (
                                        <img src={moduleMapping[nestedSubmenuItem].logo} alt={nestedSubmenuItem} />
                                      )}
                                      &nbsp; &nbsp;
                                      <span className={open && 'sidebar_Text'}>{nestedSubmenuItem}</span>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
