import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, BarChart, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, AreaChart, Area, Tooltip, Legend, LineChart, Line } from 'recharts';

// import { Line } from 'react-chartjs-2';

import axiosInstance from "../../../Components/utils/axios";
import { FormControl, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import { fetchFuelData } from '../../../Components/DataFunctions/Apidata';
import DatePicker from "react-datepicker";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ChartWithPointStyling from './PointStylingEmission';


function GetVehicleAnalytics({ facility }) {

    const [value, setvalue] = useState("time")
    const [timedata, settimedata] = useState([])
    const [Data, setData] = useState([])
    const [Fuels, setFuels] = useState(null)
    // const [Emdata, setEmData] = useState([]);
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const [startDate, setStartDate] = useState(firstDayOfYear);
    const [endDate, setEndDate] = useState(new Date());
    const [selectedRange, setSelectedRange] = useState('yearly');

    const [streamvalue, setstreamValue] = useState('All')


    useEffect(() => {
        // Fetch data initially
        if (value === "time") {
            const firstDayOfYear = new Date(today.getFullYear(), 0, 1);

            // fetchData(firstDayOfYear, new Date());
            fetchData(startDate, endDate);
        }

        // handleButtonClick("yearly")

    }, [startDate, endDate, value, facility, streamvalue]);



    const fetchData = async (startDate, endDate) => {
        // alert(facility)
        try {
            const response = await axiosInstance.get(`/getEmissionTrend?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&facilityId=${facility}&stream=${streamvalue}`);
            console.log("time", response.data)
            settimedata(response.data);
            // setvalue("time")


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleRangeChange = (event) => {
        setSelectedRange(event.target.value);
        // Adjust start and end dates based on selected range
        if (event.target.value === "custom") {
            setStartDate(null);
            setEndDate(null);
        }

        const today = new Date();
        switch (event.target.value) {
            case 'month':
                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                setStartDate(firstDayOfMonth);
                setEndDate(today);
                break;
            case 'quarterly':
                const firstDayOfQuarter = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
                setStartDate(firstDayOfQuarter);
                setEndDate(today);
                break;
            case 'yearly':
                const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
                setStartDate(firstDayOfYear);
                setEndDate(today);
                break;
            default:
                // For 'custom' range, do nothing here, let the user select custom dates
                break;
        }
    };

    const handleButtonClick = (value) => {
        setSelectedRange(value);

        // Adjust start and end dates based on selected range
        if (value === "custom") {
            setStartDate(null);
            setEndDate(null);
        }



        const today = new Date();
        switch (value) {
            case 'month':
                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                setStartDate(firstDayOfMonth);
                setEndDate(today);
                break;
            case 'quarterly':
                const firstDayOfQuarter = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
                setStartDate(firstDayOfQuarter);
                setEndDate(today);
                break;
            case 'yearly':
                const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
                setStartDate(firstDayOfYear);
                setEndDate(today);
                break;
            default:
                // For 'custom' range, do nothing here, let the user select custom dates
                break;
        }
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };


    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate();
        const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthIndex = date.getMonth();
        const monthAbbreviation = monthArray[monthIndex];
        const year = date.getFullYear();

        // Format the date as "day month year"
        const formattedDate = `${day} ${monthAbbreviation} ${year}`;

        return formattedDate;
    }


    const getVehData = async () => {
        try {
            await axiosInstance.get(`/getEmissionbyvehicle/${value}/${facility}/${streamvalue}`)
                .then((res) => {

                    const sortedData = res.data;
                    setData(sortedData);
                    console.log({ getEmissionbyvehicle: res });

                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (error) {
            console.log(error);
        }
    };


    const GetFuel = (fuelID) => {
        const thisFuel = Fuels.filter((fuel) => fuel.fuelID === fuelID)
        return thisFuel[0]?.fuelType
    }

    function CustomTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
            return (
                <div className="_custom-tooltip">
                    <span className="label">{`${value}: ${value === "fuelType" ? GetFuel(label) : label}`}</span><br />
                    <span className="total-emission">{`Total Emission: ${payload[0].value?.toFixed(5)} KgCO₂e`}</span><br />
                    <span className="average-emission">{`Average Emission: ${payload[0].payload.averageEmission?.toFixed(5)} KgCO₂e`}</span><br />
                </div>
            );
        }

        return null;
    }

    function TimeTooltip({ active, payload, label }) {
        // console.log({ payload })
        if (active && payload && payload.length) {
            return (
                <div className="_custom-tooltip">
                    <span className="label">{`Month: ${monthNames[label]}`}</span><br />
                    <span className="total-distance">{`Total Emission: ${payload[0].payload.totalEmission?.toFixed(5)} KgCO₂e`}</span><br />
                    <span className="avg-distance">{`Average Emission: ${payload[0]?.payload?.AvgEmission?.toFixed(5)} KgCO₂e`}</span><br />
                    <span className="avg-distance">{`Year: ${payload[0].payload?._id?.year} `}</span><br />
                </div>
            );
        }
        return null;
    }



    const getFuels = async () => {

        const fuels = await fetchFuelData()
        console.log({ fuels })
        setFuels(fuels)
        // return fuels
    }

    useEffect(() => { getFuels() }, [])

    useEffect(() => {

        getVehData()

    }, [value, facility, streamvalue])


    const handlSeleectChange = (value) => {
        // debugger;
        if (value === "time") {
            handleButtonClick("yearly")
        }
        setSelectedRange('yearly')
    }



    const handleStreamChange = (event) => {
        setstreamValue(event.target.value);
    };


    const chartData = {
        labels: value === "time" ? timedata.map(item => monthNames[item._id.month - 1]) : Data.map(item => item._id),
        datasets: [
            {
                label: 'Total Emission KgCO₂e',
                data: value === "time" ? timedata.map(item => item.totalEmission) : Data.map(item => item.totalEmission),
                backgroundColor: 'rgba(28, 72, 107, 0.2)',
                borderColor: 'rgba(28, 72, 107, 1)',
                borderWidth: 1
            },
            {
                label: 'Average Emission',
                data: value === "time" ? timedata.map(item => item.averageEmission) : Data.map(item => item.averageEmission),
                backgroundColor: 'rgba(130, 202, 157, 0.2)',
                borderColor: 'rgba(130, 202, 157, 1)',
                borderWidth: 1
            }
        ]
    };

    // Options for the chart
    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Emission (KgCO₂e)'
                }
            },
            x: {
                title: {
                    display: true,
                    text: value === "time" ? 'Month' : 'Category'
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toFixed(5) + ' kgCO₂e';
                        }
                        return label;
                    }
                }
            }
        }
    };


    return (
        <div className='Echart p-2' >


            <div className='df jsb'>
                {value !== "time" && <label className='_dash_heading'> Emission &nbsp;&nbsp;&nbsp;<span className="_MANDATORY"> </span></label>}
                {value === "time" &&
                    <Grid container xs={12} spacing={0} className="date_range " >
                        <Grid container xs={12} spacing={0} className="date_range " >
                            <Grid className="date_range " style={{ gap: '200px' }} >
                                <label className='_dash_heading' > Emission (kgCO<sub>2</sub>e) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="_MANDATORY"> </span></label>
                                <Grid  item xs={6} style={{ maxWidth: '100%', marginTop: '10px', marginBottom: "10px" , display : "flex" , gap:'20px' , alignItems : "center" }} >

                                    <ButtonGroup variant="contained" color="primary">
                                        <Button style={{
                                            color: selectedRange == "month" ? '#fff' : '#1c486b', fontSize: '12px', height: '20px', padding: '20px 20px',
                                            backgroundColor: selectedRange == "month" ? '#1c486b' : '#fff',
                                        }} onClick={() => handleButtonClick("month")}>Monthly</Button>
                                        <Button
                                            style={{
                                                color: selectedRange == "quarterly" ? '#fff' : '#1c486b', fontSize: '12px', height: '20px', padding: '20px 20px',
                                                backgroundColor: selectedRange == "quarterly" ? '#1c486b' : '#fff',
                                            }}
                                            onClick={() => handleButtonClick("quarterly")}>Quarterly</Button>
                                        <Button className="_YEARLY"
                                            style={{
                                                color: selectedRange == "yearly" ? '#fff' : '#1c486b', fontSize: '12px', height: '20px', padding: '20px 20px',
                                                backgroundColor: selectedRange == "yearly" ? '#1c486b' : '#fff',
                                            }}
                                            onClick={() => handleButtonClick("yearly")}>Yearly</Button>
                                        <Button
                                            style={{
                                                color: selectedRange == "custom" ? '#fff' : '#1c486b', fontSize: '12px', height: '20px', padding: '20px 20px',
                                                backgroundColor: selectedRange == "custom" ? '#1c486b' : '#fff',
                                            }}
                                            onClick={() => handleButtonClick("custom")}>Custom</Button>
                                    </ButtonGroup>
                                    <>
                                        {
                                            startDate ?
                                                <span className=' theme_c'>{formatDate(startDate)}</span>
                                                :
                                                <Grid item xs={2} md={2}>
                                                    <DatePicker
                                                        className=''
                                                        placeholderText='Start Date'
                                                        selected={startDate}
                                                        onChange={handleStartDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                    />
                                                </Grid>
                                        }
                                        <span className=''>-</span>
                                        {
                                            endDate ?
                                                <span className=' theme_c'>{formatDate(endDate)}</span>
                                                :
                                                <Grid item xs={2} md={2}>
                                                    <DatePicker
                                                        className=''
                                                        placeholderText='End Date'
                                                        selected={endDate}
                                                        onChange={handleEndDateChange}
                                                        dateFormat="dd-MM-yyyy"
                                                    />
                                                </Grid>
                                        }
                                    </>

                                </Grid >

                            </Grid>

                        </Grid>
                    </Grid>
                }

                <Grid item xs={4}>
                    <Select
                        fullWidth
                        style={{ width: '120px' }}
                        value={value}
                        onChange={(e) => { setvalue(e.target.value); handlSeleectChange(e.target.value) }}
                        required
                        name="ATTRIBUTES"
                    >
                        <MenuItem value="time" >Over the Time</MenuItem>
                        <MenuItem value="fuelType">Fuel Type</MenuItem>
                        <MenuItem value="TransportType">Transport Type</MenuItem>
                        <MenuItem value="vehicleCategory">Vehicle Category</MenuItem>
                        <MenuItem value="vehicleType">Vehicle Type</MenuItem>
                        {/* <MenuItem value="vehicleMake">vehicle Make</MenuItem>
                    <MenuItem value="vehicleModel">vehicle Model</MenuItem> */}
                        {/* <MenuItem value="vehicleEffeciency">vehicleEffeciency</MenuItem> */}
                    </Select>
                </Grid>


            </div>

            {/* <ResponsiveContainer width="100%" height="100%"> */}

            {/* <AreaChart
                width={1200}
                height={500}
                data={value === "time" ? timedata : Data}
            >
                <CartesianGrid strokeDasharray="3 3" />
                {value === "time" ? <XAxis dataKey="_id.month" tickFormatter={(month) => monthNames[month - 1]} /> : <XAxis dataKey="_id" />}
                <YAxis style={{ fontSize: '14px' }}
                    label={{ value: 'Emission ( KgCO2e )', angle: -90, position: 'outsideLeft', dx: -20 }}
                    tickFormatter={(value) => `${value} `}
                />
                <Tooltip position={{x:100,y:30}} content={value === "time" ? <TimeTooltip /> : <CustomTooltip />} />
                <Legend />
                <Area dataKey="totalEmission" name="Total Emission KGCO2e" fill="#1c486b" stroke="#1c486b" />
                <Area dataKey="averageEmission" name="avg Emission" fill="#82ca9d" stroke="#82ca9d" />
            </AreaChart> */}
            {/* </ResponsiveContainer> */}


            <div className='df mt-2'>
                {/* <div style={{height:'30px',transform:'rotate(-90deg)',marginTop:'150px'}}>
                    Emission (kgco2e)
                </div> */}
                <ChartWithPointStyling data={value === "time" ? timedata : Data} value={value} Typevalue={value} streamvalue={streamvalue} />

            </div>



            <div className='stream '>
                <FormControl component="fieldset" style={{ FlexDirection: 'row' }} >
                    <RadioGroup value={streamvalue} onChange={handleStreamChange} className="df">
                        <FormControlLabel value="All" control={<Radio />} label="All" />
                        <span className=' p-2 _circle'>
                            {/* <svg width="12" height="12" viewBox="0 0 12 12" fill="#eb7e0e" xmlns="http://www.w3.org/2000/svg">
                            <polygon points="6 0 12 12 0 12" />
                        </svg> */}
                        </span> <FormControlLabel value="Upstream" control={<Radio />} label=" Upstream  " />
                        <span className=' p-2 _triangle'>

                            {/* <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="5" fill="#488257" />
                            </svg> */}

                        </span> <FormControlLabel value="Downstream" control={<Radio />} label=" Downstream  " />
                    </RadioGroup>
                </FormControl>
            </div>

        </div>
    );
}
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default GetVehicleAnalytics;
