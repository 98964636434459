import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axiosInstance from "../../../Components/utils/axios";

function FuelTypeDistributionByVendorBar() {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axiosInstance.get("/getFuelTypeDistributionByVendor");
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, []);

    // Extract unique fuel types from data
    const fuelTypes = [...new Set(data.map(item => item._id.fuelType))];
console.log({fuelTypes})

    // Group data by vendor name
    const groupedData = data.reduce((acc, cur) => {
        const vendorName = cur._id.vendorName;
        if (!acc[vendorName]) {
            acc[vendorName] = {};
        }
        acc[vendorName][cur._id.fuelType] = cur.count;
        return acc;
    }, {});

    // Convert grouped data into array format required by Recharts
    const chartData = Object.keys(groupedData).map(vendorName => {
        const vendorData = groupedData[vendorName];
        return {
            vendorName,
            ...vendorData
        };
    });

    return (
        <div className='Echart p-2'>
        <label>Fuel Type Distribution By Vendor<span className="_MANDATORY"> </span></label>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="vendorName" />
                    <YAxis style={{ fontSize: '14px' }}
                    label={{ value: 'Emission ( KgCO₂e )', angle: -90, position: 'outsideLeft', dx: -20 }}
                    // ticks={[0, 20, 40, 60]}  // Define your ticks with units
                    tickFormatter={(value) => `${value}`}
                />                    <Tooltip />
                    <Legend />
                    {fuelTypes.map((fuelType, index) => (
                        <Bar
                            key={index}
                            dataKey={fuelType}
                            width={10}
                            name={fuelType}
                            fill={`#8884d8`} // Random color for each fuel type
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default FuelTypeDistributionByVendorBar;
