import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios"
import LocationAutocomplete from "../../Components/utils/locationFinder";
import calculateDistance from "../../Components/utils/distanceCalculatebylatlon";
import axios from "axios";
import { calculateCO2eqEmissions } from "../../Components/utils/Co2e";

const CO2Calculator = () => {
  const containerStyle = {
    display: "flex",
  };
  const tableStyle = {
    width: "100%",
    margin: "10px",
  };
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [scoordinates, setSCoordinates] = useState({ lat: 0, lng: 0 });
  const [dcoordinates, setDCoordinates] = useState({ lat: 0, lng: 0 });
  const [Vehical, setVehical] = useState([]);
  const [fuel, setfuel] = useState([]);
  const [address, setAddress] = useState("");
  const [daddress, setdAddress] = useState("");
  const [Distance, setDistance] = useState(0);
  const [FuelUsed, setFuelUsed] = useState("");
  const [result, setresult] = useState(false);
  const [fuelData, setfuelData] = useState([])

  const get_data = async () => {
    await axiosInstance.get("/fuels")
      .then((response) => {
        console.log("fueldata", response);
        setfuelData(response.data.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  useEffect(() => {
    axiosInstance.get("/vehicle")
      .then((response) => {
        // console.log(response.data.allVehicle);
        setVehical(response.data.data);
        console.log("data set", response.data.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Status Code:", error.response.status);
          console.error("Response Data:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", error.message);
        }
      });
    get_data()
  }, []);

  const [formData, setFormData] = useState({
    vehicleType: "",
    fuelType: "",
    vehicleAge: "",
    source: "",
    destination: "",
  });

  function calculateAge(startDate) {
    const today = new Date();
    const start = new Date(startDate);
    const differenceInTime = today.getTime() - start.getTime();

    // Calculate years
    const years = Math.floor(differenceInTime / (1000 * 3600 * 24 * 365));

    // Calculate remaining time after years
    const remainingTime = differenceInTime - (years * (1000 * 3600 * 24 * 365));

    // Calculate months
    const months = Math.floor(remainingTime / (1000 * 3600 * 24 * 30));

    // Calculate remaining time after months
    const remainingDays = Math.floor((remainingTime - (months * (1000 * 3600 * 24 * 30))) / (1000 * 3600 * 24));

    // Construct the age string
    let ageString = '';
    if (years > 0) {
      ageString += years + (years === 1 ? " year " : " years ");
    }
    if (months > 0) {
      ageString += months + (months === 1 ? " month " : " months ");
    }
    if (remainingDays > 0) {
      ageString += remainingDays + (remainingDays === 1 ? " day" : " days");
    }

    return ageString.trim();
  }



  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));


    if (name === "vehicleType") {
      // // debugger;
      const selectedVehicle = Vehical.find((veh) => veh.vehicleID === value);
      const fuel = fuelData.find((fuel) => fuel.fuelID === selectedVehicle.fuelType);
      const AGE = calculateAge(selectedVehicle?.vehicleYear)
      console.group(selectedVehicle);


      if (fuel) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          fuelType: fuel.fuelType,
          vehicleAge: AGE
        }));
        document.getElementById("vehicleAge").value = AGE;
        showSuccess(`${fuel.fuelType} measurement is in ${fuel.Measurement}`)
      }
      else {
        showError("Unknow Fuel type")
      }
    }
  };



  const measurementTypeConversionFactors = {
    Litres: 1,
    gallons: 3.78541178, // 1 galon = 3.78541178 litres
    Barrel: 158.9873, // 1 barrel = 158.9873 liters
    cubicmeters: 1000, // 1 cubic meter = 1000 liters
    cubicfeet: 28.3168, // 1 cubic foot = 28.3168 liters
    kiloliters: 1000, // 1 kiloliter = 1000 liters
    megajoule: 141.585, // Assuming 1 Megajoule is equivalent to 141.585 liters (please verify this conversion)
    watt: 0.001, // 1 Watt is equivalent to 0.001 liter
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    // // debugger;
    //Check if required fields are filleda
    const { vehicleType, fuelType, source, destination } = formData;
    if (!vehicleType) {
      showError("Please select the Vehicle !");
      return;
    }

    if (address == "") {
      showError("Please select the source !");
      return;
    }

    if (daddress == "") {
      showError("Please select the destination !");
      return;
    }

    const distance = calculateDistance(
      scoordinates.lat,
      scoordinates.lng,
      dcoordinates.lat,
      dcoordinates.lng
    );
    setDistance(distance.toFixed(2));
    console.log(formData);
    // showSuccess(`Distance between source and destination: ${distance.toFixed(2)} km`);
    const vehicalype = Vehical.find(
      (veh) => veh.vehicleID === formData.vehicleType
    );
    const fuel = fuelData.find((fuel) => fuel.fuelID === vehicalype.fuelType);

    setFuelUsed(`${(distance / vehicalype?.vehicleEffeciency).toFixed(5)} L`);
    const ans = calculateCO2eqEmissions(
      distance,
      vehicalype?.vehicleEffeciency,
      fuel.fuelType,
      fuel.Measurement
    );
    setresult(true);
    document.querySelector(".C02").innerHTML = ans.toFixed(5);
    showSuccess(`Total CO2eq emissions: ${ans.toFixed(2)} kg`);
  };

  const handlesourceLocation = (newCoordinates) => {
    setSCoordinates(newCoordinates);
    console.log("sourve", newCoordinates);
  };

  const handleDEstinationLocation = (newCoordinates) => {
    setDCoordinates(newCoordinates);
    console.log("des", newCoordinates);
  };

  const Resetform = () => {
    document.querySelector(".C02").innerHTML = "";
    setFormData({
      vehicleType: "",
      fuelType: "",
      vehicleAge: "",
      source: "",
      destination: "",
    });
    setAddress("");
    setdAddress("");
    setresult(false);
  };

  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={tableStyle}>
        <Header />
        <div className="table-heading">
          Emission Estimator
        </div>
        <div className="df jsb mt-3" style={{ display: 'inline-flex' }}>
          <Paper
            elevation={3}
            style={{
              padding: 20,

            }}
          >
            <form style={{ display: "flex" }} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="userRole">Vehicle *</InputLabel>
                    <Select
                      labelId=""
                      className="rolesSelect"
                      name="vehicleType"
                      label="Vehicle"
                      value={formData.vehicleType}
                      onChange={handleChange}
                      sx={{
                        "&:focus": {
                          borderColor: "green", // Change the border color when focused
                        },
                      }}
                    >
                      {Vehical.map((item, idx) => (
                        <MenuItem
                          style={{ height: "auto" }}
                          key={item.vehicleID}
                          value={item.vehicleID}
                        >
                          <span>
                            {item.vehicleID}&nbsp; <span style={{ color: localStorage.getItem('--theme') || '#ccc', fontSize: '10px' }}>  {item.vehicleType}</span>
                          </span>

                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled
                    // label="fuel "
                    placeholder="fuel"
                    variant="outlined"
                    name="fuelType"
                    value={formData.fuelType}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    // label="Vehicle Age"
                    placeholder="Vehicle Age"
                    variant="outlined"
                    name="vehicleAge"
                    id="vehicleAge"
                    value={formData.vehicleAge}
                    onChange={handleChange}
                    disabled
                  />
                </Grid>

                <Grid item xs={4}>
                  <LocationAutocomplete
                    onSelectLocation={handlesourceLocation}
                    coordinates={coordinates}
                    address={address}
                    setAddress={setAddress}
                    setCoordinates={setCoordinates}
                    placeHolder={"Source *"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LocationAutocomplete
                    onSelectLocation={handleDEstinationLocation}
                    coordinates={coordinates}
                    address={daddress}
                    setAddress={setdAddress}
                    setCoordinates={setCoordinates}
                    placeHolder={"Destination *"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    style={{
                      color: "white",
                      margin: "1px 0",
                      padding: "8px",
                      backgroundColor: "black",
                      letterSpacing: "1px",
                      fontWeight: "600",
                      height: "43px",
                      width: "100%",
                      fontFamily: "Lora",
                      textTransform: "none",
                    }}
                  >
                    ESTIMATE EMISSION
                  </Button>
                </Grid>
              </Grid>
            </form>
          
            {result &&  <><br/>  <br /> <br/></>}

            <div style={{ display: result ? "block" : "none" }}>
              <div className="df jsb mt-3">
                <div className="table-heading">Your Carbon Footprint </div>
                <Button
                  className="btn"
                  style={{ border: "2px solid #111", color: "#111" }}
                  onClick={Resetform}
                >
                  reset
                </Button>
              </div>
              <hr />
              <h4>
                {" "}
                <span className="C02"></span> <span style={{ color: 'grey' }}>KgCO₂e</span>{" "}
              </h4>
              <div className="df jsb co2details  mt-5">
                <p>
                  Vehicle : <span>{formData.vehicleType}</span>
                </p>
                {FuelUsed !== 0 ? (
                  <p>
                    ⛽ <span> {FuelUsed}</span>
                  </p>
                ) : null}

                <p>
                  Fuel : <span>{formData.fuelType}</span>{" "}
                </p>
              </div>
              <div className="df jsb co2details">
                <p>
                  Source : <span> {address}</span>
                </p>
                {Distance !== 0 ? (
                  <p>
                    ⬅️ <span> {Distance} KM</span>➡️
                  </p>
                ) : null}

                <p>
                  Destination : <span>{daddress}</span>
                </p>
              </div>
            </div>
          </Paper>
          <div> </div>
          <br />

        </div>
      </div>
    </div>
  );
};

export default CO2Calculator;
