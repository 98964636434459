import { Button, ButtonGroup, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Report from "./Report"
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import axios from "axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
import AllDocs from "./AllDocs";
import Docsbymodule from "./Docsbymodule";
import CustomButtonGroup from "./CustomButtonGroup";
import axiosInstance from "../../Components/utils/axios";

const Table = () => {
  const [data, setData] = useState([]);


  const getDocumentData = async () => {
    try {
      const res = await axiosInstance.get(
        "/getDocuments"
      );
      if (res.data.success) {

        console.log(res.data.allTripsdocs);
        setData(res.data.allTripsdocs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDocumentData();
  }, []);





  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading">
            Documentation
          </div>

        </div>
        {/* <AllDocs data={data} /> */}
        {/* <Report defaultdata={data} /> */}

        {/* <AllDocs defaultdata={data.drivers} /> */}
     
        <Docsbymodule data={data} />
      </div>
    </div>
  );
};

export default Table;
