import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import axiosInstance from "../../Components/utils/axios";
import IsModifiedModal from "../../Components/IsModified"

import Header from "../../Components/Header/Header";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import { enIN } from 'date-fns/locale'
import axios from "axios";
import { showSuccess, showError } from "../../toast";
const moment = require('moment');


const AddNewTrip = () => {
  const [dragging, setDragging] = useState(false);
  const generateRandom4DigitNumber = () => {
    return `TRIP${Math.floor(Math.random() * 900) + 101}`;
  };

  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action") || "";
  const TRIPID = searchParams.get("search") || "";
  // console.log(selectedid ,searchValue)
  // alert(searchValue)
  // alert(selectedid)
  const [errors, setErrors] = useState({}); // State to hold validation errors

  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDate1, setSelectedDate1] = useState("");
  const [selectedDate2, setSelectedDate2] = useState("");
  const [Drivers, setDrivers] = useState([]);
  const [vendors, setvendors] = useState([]);
  const [vehical, setvehical] = useState([]);
  const [distanceMeasureVEH, setdistanceMeasureVEH] = useState("");
  const [locations, setlocations] = useState([]);
  const [facilitys, setfacilitys] = useState([]);

  const [formModified, setFormModified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const  CURRENT_ADDRESS = JSON.parse(localStorage.getItem('_USER')).Facility._id

  const LOCATIONS = []
  useEffect(() => {
    // Use an asynchronous function to make the axios calls
    const fetchData = async () => {
      try {
        // Use await to wait for the promises to resolve
        const driverResponse = await axiosInstance.get(
          "/getDriver"
        );
        console.log("driver", driverResponse.data);
        setDrivers(driverResponse.data);

        //vendors
        axiosInstance.get('/vendors').then((res) => {
          setvendors(res.data)
          // console.log("wqw",res.data)
        })


        const vehicalResponse = await axiosInstance.get("/vehicle"
        );
        console.log("veh", vehicalResponse.data.data);
        setvehical(vehicalResponse.data.data);
        const facilityRes = await axiosInstance.get(
          "/customerFacility"
        );
        const locationres = await axiosInstance.get(
          "/location"
        );
        const USER = localStorage.getItem('_USER')
        const USERDATA = JSON.parse(USER)
        const ISMANAGER = !['SUPER ADMIN','ADMIN'].includes(USERDATA?.role.roleName);
       const  CURRENTUSER =  localStorage.getItem('user_id')
        console.log("loc", locationres.data);
       if(false){
         const filteredLocations = locationres.data.userLocations.filter(location => location.createdBy._id === CURRENTUSER);
         const filteredfacility = facilityRes.data.data.filter(facility => facility.createdBy._id === CURRENTUSER);
       
         setlocations(filteredLocations);
         setfacilitys(filteredfacility);
       }
       else{
        
        console.log({locationres ,facilityRes})
        setlocations(locationres.data.userLocations);
        setfacilitys(facilityRes.data.data);
       }
       
   


      } catch (error) {
        console.error("Axios Error:", error);
      }
    };



    // Call the asynchronous function
    fetchData();
  }, []);


  const getTripData = async () => {
    try {
      const response = await axiosInstance.get("/trips");
      const reversedata = response.data.reverse();
      // setList(reversedata);
      console.log(response.data);

      return reversedata; // Return the entire trip data
    } catch (error) {
      console.log(error);
      throw error; // Re-throw the error so that the caller can handle it
    }
  };







  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    // handleImageUpload(file);
  };
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, index) => currentYear - index);
  const [tripData, setTripData] = useState({
    tripID: generateRandom4DigitNumber(),
    Date: "",
    selectDriver: "",
    tripstartdate: "",
    tripenddate: "",
    Source: "",
    vehicleSource: "",
    DriverID: "",
    stopTime: "0",
    vehicleID: "",
    vehicleDestination: "",
    Destination: "",
    DistanceTravelled: 0,
    StartOdoReading: 0,
    EndOdoReading: 0,
    vehicleCapacity: "0",
    vehicleEffeciency: "",  // fuel consumed
    fuelConsumed: "",  // fuel consumed
    fuelType: "",
    status: 'Pending',
    tripDocs: '',
    vendor: ''
  });


  const uploadFile = async (file, name) => {
    try {
      console.log(file)
      const formData = new FormData();
      formData.append('pucFile', file);

      const response = await axiosInstance.post(
        "/uploadPUCFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("uploads", response)
      setFormModified(true)
      return response?.data?.imageurl?.Location;
    } catch (error) {
      console.log(error);

      return null;
    }
  };

  const handleDocsUpload = async (value, name) => {
    // alert(`${value} - ${name}`)
    // // debugger;
    console.log(value)
    const fileUrl = await uploadFile(value, name);
    console.log(name)
    setTripData((prevValues) => ({
      ...prevValues,
      [name]: fileUrl,
    }));

    console.log(tripData)
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    

    setTripData((prevTripData) => {
      let updatedData = {
        ...prevTripData,
        [name]: value,
      };


      if (name === "tripstartdate" || name === "tripenddate") {
        updatedData = {
          ...updatedData,
          [name]: formatDate(value),
        };
        setFormModified(true)
      }

      if (name === "Destination") {
        if (updatedData.Source === updatedData.Destination) {
          showError("Source and Destination cannot be the same");
          return prevTripData; // Don't update data if source is same as current address
        }
  
        const selectedLocation = locations.find((item) => item._id === value);
        const selectedFacility = facilitys.find((item) => item._id === value);
  
        if (selectedLocation) {
          updatedData = {
            ...updatedData,
            // Source: CURRENT_ADDRESS,
            vehicleDestination: "Location",
          };
        } else if (selectedFacility) {
          updatedData = {
            ...updatedData,

            vehicleDestination: "CustomerFacility",
          };
        }
      }
  
      if (name === "Source") {
        const selectedLocation = locations.find((item) => item._id === value);
        const selectedFacility = facilitys.find((item) => item._id === value);
  
  // // debugger;

        // if (value === CURRENT_ADDRESS) {
        //   updatedData = {
        //     ...updatedData,
        //     Destination: "",
        //   };
        // } else {
        //   updatedData = {
        //     ...updatedData,
        //     Destination: CURRENT_ADDRESS,
        //     vehicleDestination:"CustomerFacility"
        //   };
        // }
  
        if (selectedLocation) {
          updatedData = {
            ...updatedData,
            vehicleSource: "Location",
            // Destination: CURRENT_ADDRESS,
          };
        } else if (selectedFacility) {
          updatedData = {
            ...updatedData,
            vehicleSource: "CustomerFacility",
          };
        }
      }
     
     

      if (name === "selectDriver") {
        const selectedDriver = Drivers?.find((driver) => driver._id === value);
        if (selectedDriver) {
          updatedData = {
            ...updatedData,
            DriverID: selectedDriver.DriverID,
          };
        }
        setFormModified(true)
      }

      if (name === "vehicleID") {
        const selectedveh = vehical?.find((veh) => veh._id === value);
        // // debugger;
        setFormModified(true)
        console.log("selectedveh", selectedveh)
        setdistanceMeasureVEH(selectedveh.distancemeasure)


        if (selectedveh) {
          updatedData = {
            ...updatedData,
            fuelType: selectedveh.fuelType,
            vehicleEffeciency: selectedveh.vehicleEffeciency
          };
        }
      }

      if (name === "EndOdoReading" || name === "StartOdoReading") {
        const startOdo = parseFloat(updatedData.StartOdoReading) || 0;
        const endOdo = parseFloat(updatedData.EndOdoReading) || 0;

        updatedData = {
          ...updatedData,
          DistanceTravelled: Math.abs(endOdo - startOdo),
        };
      }

      setFormModified(true)
      return updatedData;
    });

  };


  const validateForm = () => {
    if (!tripData.Date) {
      showError("Please select the Trip Date ");
      return false;
    }
    if (!tripData.selectDriver) {
      showError("Please select the driver ");
      return false;
    }
    if (!tripData.tripstartdate) {
      showError("Please Enter the trip start Date  ");
      return false;
    }
    if (!tripData.vehicleSource || !tripData.vehicleDestination) {
      showError("Please Enter the trip source and Destination  ");
      return false;
    }

    if (!tripData.vehicleID) {
      showError("Please select the Vehicle  ");
      return false;
    }

    if (tripData.status != "Pending") {

      if (!tripData.vehicleEffeciency) {
        showError("Please Enter the Fuel Consumed ");
        return false;
      }
    }

    return true;
  };


  const formatDate = (date) => {
    // alert(moment.utc(date).local())
    const formatedDate = moment.utc(date).local()
    return formatedDate;
  };

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
    setTripData({
      ...tripData,
      tripstartdate: formatDate(date),
    });
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
    setTripData({
      ...tripData,
      tripenddate: formatDate(date),
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setTripData({
      ...tripData,
      Date: formatDate(date),
    });

  };


  const combinedOptions = [...locations, ...facilitys];




  const makeApiCall = async (method, url, payload) => {
    try {

      // const response = await axios[method.toLowerCase()](url, payload);


      const response = await axiosInstance[method.toLowerCase()](url, {
        ...payload
      });
      setFormModified(false)

      showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
      return response.data;
    } catch (error) {
      showError("Error making API call:", error);
      console.error("Error making API call:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(tripData);
    // alert(tripData.Date)
    const isValid = validateForm();
    // alert(isValid)
    // // debugger;
    if (action == "delete" || isValid) {
      console.log(tripData);
      try {
        const responseData = await makeApiCall(
          `${action == "" ? 'POST' : action == 'update' ? 'PUT' : 'DELETE'}`,
          `/${action == "" ? `trips` : action == 'update' ? `updateTrips/${tripData.tripID}` : `deleteTrips/${tripData.tripID}`}`,
          {
            ...tripData,
            Driver: tripData.selectDriver,
            Vehicle: tripData.vehicleID,
            vendor: tripData.vendor,
            user: localStorage.getItem('user_id'),
            Company: localStorage.getItem('COMPANY')
          });
        console.log(responseData);
        window.history.back();
      } catch (error) {
        console.log("err", error);
      }
    } else {
      showError("Form is not valid")
    }
  };


  const get_trip = async () => {
    await axiosInstance.get(`/trips/${TRIPID}`)
      .then((response) => {
        console.log("trip", response.data);

        const selectedtrip = response.data.find((id) => id._id === TRIPID);
        console.log("filter Trip", selectedtrip);
        setTripData({
          ...tripData,
          ...selectedtrip,
          Destination: selectedtrip.Destination,
          Source: selectedtrip.Source,

        });

      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  useEffect(() => {
    get_trip()
  }, [TRIPID])


  const handleVendorChange = (e) => {
    // alert(e.target.value)
    setTripData((prevTripData) => ({
      ...prevTripData,
      vendor: e.target.value
    }))
    // now get driver and vehicle of this vendor
    populateDriverAndVehicleByVendor(e.target.value)
  }

  const populateDriverAndVehicleByVendor = (vendorID) => {
    axiosInstance.get(`/vehiclebyVendor/${vendorID}`).then((res) => {
      console.log("vehicle of this vendor", res)
      setvehical(res.data.data)

    })

    axiosInstance.get(`/getDriver/${vendorID}`).then((res) => {
      console.log("driver of this vendor", res)

      if (res.data.length > 0) {
        setDrivers(res.data)
      }
      else {
        setDrivers([])
      }
    })
  }

  const handleModalClose = (result) => {
    setIsModalOpen(false);
    if (!result) {
      navigate('/trips')
    }
  };

  const handleBackbutton = () => {
    // alert(formModified)
    if (formModified) {
      setIsModalOpen(true)
    }
    else {
      navigate('/trips')
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div>
        <Sidebar />
      </div>
      <div style={{ width: "100%", margin: "10px" }}>
        <Header />
        <div className="table-heading theme_c"><span onClick={() => handleBackbutton()} style={{ textTransform: 'capitalize' }}><ArrowBackIcon /></span >{action ? action : '  Add New'}  Trips  <span className={action == "update" ? '_edit' : '_delete'}>{`> ${tripData?.tripID}`}</span></div>
        <Paper elevation={3} className="hidden_scrollbar" style={{ padding: "20px", marginTop: "15px", height: '89vh', overflowY: 'scroll' }}>
          <form className="tripform">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <label htmlFor="col1">Trip ID</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="col1"
                  name="tripID"
                  value={tripData.tripID}
                  onChange={(e) => handleInputChange(e)}
                  disabled
                  placeholder="1122"

                />
              </Grid>
              <Grid item xs={4} className="">
                <label>Date Entry <span className="_MANDATORY">*</span></label>
                <br />
                <DatePicker
                  selected={selectedDate}
                  value={selectedDate || tripData?.Date}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                  locale={enIN}
                  maxDate={new Date()} // Disable future dates
                  customInput={
                    <TextField
                      variant="outlined"
                      // placeholder="2244"
                      fullWidth
                      id="col2"
                      name="Date"
                      value={selectedDate}
                      placeholder="DD-MM-YYYY"
                      inputProps={{ readOnly: true }}

                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col2"> Vendor <span className="_MANDATORY">*</span></label>
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"

                    value={tripData.vendor}
                    onChange={(e) => handleVendorChange(e)}
                  >
                    {vendors?.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto" }}
                        key={item._id}
                        value={item._id}
                      >
                        {item.name}-{item.vendor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col2"> Driver <span className="_MANDATORY">*</span></label>
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="selectDriver"
                    value={tripData.selectDriver}
                    onChange={handleInputChange}
                    disabled={!tripData.vendor}
                  >
                    {Drivers?.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto" }}
                        key={item._id}
                        value={item._id}
                      >
                        {item?.profilePic

                          ?
                          <img className="menu_img" src={item?.profilePic} />
                          :
                          <span className="menu_img" >🙎🏻‍♂️</span>
                        }

                        &nbsp;&nbsp; &nbsp;  {item.DriverName}-{item.DriverID}

                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col4">Trip Start Date <span className="_MANDATORY">*</span></label>
                <DatePicker
                  onChange={handleDateChange1}
                  selected={selectedDate1}
                  dateFormat="yyyy/MM/dd HH:mm"
                  showTimeInput
                  timeFormat="HH:mm"
                  value={selectedDate1 || tripData?.tripstartdate}
                  // maxDate={new Date()} // Disable future dates
                  customInput={
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="col4"
                      value={selectedDate1}
                      name="tripstartdate"
                      inputProps={{ readOnly: true }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col5">Source <span className="_MANDATORY">*</span></label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="Source"
                    value={tripData.Source}
                    onChange={handleInputChange}
                  >
                    {facilitys && facilitys.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto", borderLeft: "3px solid tomato", margin: '3px 0' }}
                        key={item.address}
                        value={item._id}
                      >
                        <div className="df jsb w-100"><span> {item.address}</span><span className="vsm _red"> {item._id === CURRENT_ADDRESS ? 'CURRENT LOCATION' : 'Facility Adress'}</span></div>
                      </MenuItem>
                    ))}
                    {locations && locations.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto", borderLeft: "3px solid #10B36F", margin: '3px 0' }}
                        key={item.locationName}
                        value={item._id }
                      >
                        <div className="df jsb w-100"><span> {item.locationName}</span><span className="vsm _green">Destination Adress</span></div>
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <label>Destination</label> <span className="_MANDATORY">*</span>
                <FormControl fullWidth variant="outlined">
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="Destination"
                    value={tripData.Destination}
                    onChange={handleInputChange}
                  >
                    {/* {
                      tripData.vehicleSource === "Location" ?
                        facilitys && facilitys.map((item, idx) => (
                          <MenuItem
                            style={{ height: "auto", borderLeft: "3px solid tomato", margin: '3px 0' }}
                            key={item.address}
                            value={item._id}
                          >
                            <div className="df jsb w-100"><span> {item.address}</span><span className="vsm _red">Facility Adress</span></div>
                          </MenuItem>
                        ))
                        :
                        locations && locations.map((item, idx) => (
                          <MenuItem
                            style={{ height: "auto", borderLeft: "3px solid #10B36F", margin: '3px 0' }}
                            key={item.locationName}
                            value={item._id}
                          >
                            <div className="df jsb w-100"><span> {item.locationName}</span><span className="vsm _green">Destination Adress</span></div>
                          </MenuItem>
                        ))
                    } */}

                    {locations && locations.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto", borderLeft: "3px solid #10B36F", margin: '3px 0' }}
                        key={item.locationName}
                        value={item._id}
                      >
                        <div className="df jsb w-100"><span> {item.locationName}</span><span className="vsm _green">Destination Adress</span></div>
                      </MenuItem>
                    ))}
                    { facilitys && facilitys.map((item, idx) => (
                          <MenuItem
                            style={{ height: "auto", borderLeft: "3px solid tomato", margin: '3px 0' }}
                            key={item.address}
                            value={item._id}
                          >
                            <div className="df jsb w-100"><span> {item.address}</span><span className="vsm _red">{item._id === CURRENT_ADDRESS ? 'CURRENT LOCATION' : 'Facility Adress'}</span></div>
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col2">Trip End Date </label>
                  <DatePicker
                    onChange={handleDateChange2}
                    selected={selectedDate2}
                    dateFormat="yyyy/MM/dd HH:mm"
                    showTimeInput
                    placeholderText="auto filled on trips End"
                    value={selectedDate2 || tripData?.tripenddate}
                    maxDate={new Date()} // Disable future dates
                    disabled={true}
                    customInput={
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="2233"
                        id="col5"
                        name="tripenddate"
                        value={selectedDate2}

                      // onChange={handleInputChange}
                      />
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label>Driver ID</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Driver ID"
                  disabled
                  id="col5"
                  name="DriverID"
                  value={tripData.DriverID}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={tripData.status != "Pending" ? 4 : 8}>
                <label> Vehicle <span className="_MANDATORY">*</span></label>
                <FormControl fullWidth variant="outlined">
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="vehicleID"
                    value={tripData.vehicleID}
                    onChange={handleInputChange}
                    disabled={!tripData.vendor}

                  >
                    {vehical?.map((item, idx) => (
                      <MenuItem
                        style={{ height: "auto" }}
                        key={item.vehicleID}
                        value={item._id}
                      >


                        <span>
                          {item.vehicleID}_{item.vehicleModel}&nbsp; <span style={{ color: localStorage.getItem('--theme') || '#ccc', fontSize: '10px' }}> runs on {item.fuelname}</span>
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={tripData.status == "Pending" && ''}>
                <label>Declared Efficiency</label>
                <TextField
                  type="number"
                  variant="outlined"
                  fullWidth
                  placeholder="Efficiency"
                  id="col5"
                  name="vehicleEffeciency"
                  disabled={true}
                  value={tripData.vehicleEffeciency}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label> Stop Time</label>
                <TextField
                  type="number"
                  variant="outlined"
                  fullWidth
                  placeholder="Stop Time"
                  id="col5"
                  name="stopTime"
                  value={tripData.stopTime}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label> Distance Travelled in Km</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  // placeholder="2233"
                  id="col5"
                  name="DistanceTravelled"
                  value={distanceMeasureVEH == "miles" ? tripData.DistanceTravelled * 0.6213711922 : tripData.DistanceTravelled}
                  onChange={handleInputChange}
                  disabled={tripData.status == "Pending"}
                />
              </Grid>
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label> Enter Start Odometer Reading</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  // placeholder="2233"
                  type="number"
                  id="col5"
                  name="StartOdoReading"
                  value={tripData.StartOdoReading}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label> Trip End Odometer Reading</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  // placeholder="2233"
                  id="col5"
                  name="EndOdoReading"
                  value={tripData.EndOdoReading}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </Grid>
              {/* For Uploading the Image */}
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label htmlFor="col8">Fuel Type</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  // placeholder="2233"
                  id="col5"
                  name="fuelType"
                  value={tripData.fuelType}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} className="mt-3">
                <label htmlFor="col8">Upload</label>
                <div
                  style={{
                    width: "100%",
                    padding: "35px",
                    border: "2px dotted lightgrey",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: "12px",
                  }}
                >
                  {
                    !tripData.tripDocs &&
                    <label>
                      Drag and Drop or{" "}
                      <Button
                        sx={{
                          backgroundColor: "grey",
                          color: "white",
                          opacity: 0.8,
                          fontSize: "10px",
                          fontWeight: "300",
                        }}
                        onClick={() =>
                          document.querySelector('input[type="file"]').click()
                        }
                      >
                        Browse
                      </Button>
                      <br />
                      <span className="f-8 ">Acepted File types ( png , jpeg ,docx  )</span>

                    </label>
                  }

                  <input
                    type="file"
                    name="tripDocs"

                    onChange={(e) =>
                      handleDocsUpload(e.target.files[0], 'tripDocs')
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <div>
                    {tripData.tripDocs ? (
                      <img
                        src={tripData.tripDocs}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : null}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-3">
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label htmlFor="col9">Fuel Consumed in Ltr </label>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder="1210"
                  id="col9"
                  name="fuelConsumed"
                  value={tripData.fuelConsumed}
                  onChange={handleInputChange}
                  disabled={tripData.status == "Pending"}
                />
              </Grid>
              <Grid item xs={4} className={tripData.status == "Pending" && 'dn'}>
                <label htmlFor="col9">Load Capacity</label>
                <TextField
                  variant="outlined"
                  type="number"
                  fullWidth
                  placeholder="1021"
                  id="col9"
                  value={tripData.vehicleCapacity}
                  name="vehicleCapacity"
                  onChange={handleInputChange}
                  disabled={tripData.status == "Pending"}
                />
              </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
              <Button
                className="submit_btn"
                style={{
                  color: "white",
                  backgroundColor: "black",
                  width: "280px",
                  padding: "7px 45px 7px 45px",
                  height: "40px",
                  borderRadius: "5px",
                  textAlign: "center",
                  marginTop: "5%",
                  fontSize: "16px",
                  fontFamily: "Lora",
                  textTransform: "none",
                }}
                onClick={handleSubmit} // Use onClick instead of type="submit"
              >
                {action == '' ? `Submit` : action.toUpperCase()}
              </Button>
            </div>
            {isModalOpen &&
              <IsModifiedModal
                open={isModalOpen}
                onClose={handleModalClose}
                description="Do you want to save the data before going back?"
              />
            }

          </form>
        </Paper>
      </div>
    </div>
  );
};
export default AddNewTrip;
