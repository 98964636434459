import React, { useEffect, useState } from 'react'
import WorldVector from '../../Components/Map'
import axiosInstance from "../../Components/utils/axios";
import { showError, showSuccess } from '../../toast';
import { MenuItem, Modal, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { countries } from "../../Components/utils/Country"
import TableComponent from '../../Components/CustomTable/CustomTable';
import MapWithMarkers from '../../Components/utils/WorldMap';


function CountriesTable({ setfacilty, emLimit, setemLimit }) {
    const [data, setData] = useState([]);
    const [currentEmission, setcurrentEmission] = useState(0)
    const [EmissionPercentage, setEmissionPercentage] = useState('0%')
    const [selectedCountry, setselectedCountry] = useState("")
    const [modalopen, setmodalopen] = useState(false)
    const [MapModal, setMapModal] = useState(false)
    const [CurrentFacilityData, SetCurrentFacilityData] = useState([]);

    const last30Days = new Array(30).fill().map((_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - index);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    });

    const handleBlur = async (newEmisionLimit) => {
        try {
            const response = await axiosInstance.put(
                "/updateEmission/65b8ef301dc86e5d2739cfde",
                { EmmsionLimit: newEmisionLimit }
            );
            console.log("emission", response.data.EmmsionLimit);
            setemLimit(response.data.EmmsionLimit);
            showSuccess("Limit Updated !");
            let EmPrcnt = (currentEmission / emLimit) * 100;

            setEmissionPercentage(`${EmPrcnt?.toFixed(1)}%`)
        } catch (error) {
            console.error(error);
        }
    };

    const getVehData = async () => {
        try {
            const response = await axiosInstance.get(`/getCountryWiseEmissions`);
            console.log("emision data res", response.data);
            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVehData();

    }, []);

    // const handleCountryChange = (event) => {
    //     const selectedCountryName = event.target.value;
    //     const selectedCountryData = data.find(country => country._id === selectedCountryName);
    //     setselectedCountry(selectedCountryData);
    // };
    const [selectedFacility, setSelectedFacility] = useState(null);

    const handleFacilityChange = (facility, Selectedcountry) => {

        console.log('Selected Facility:', facility);
        // debugger;
        console.log({ Selectedcountry })

        const countryData = data.filter((country) => country._id === Selectedcountry);
        console.log(countryData)

        SetCurrentFacilityData(countryData[0])

        const selectedFacilityData = countryData[0]?.facilities.find(f => f._id === facility);
        setSelectedFacility(selectedFacilityData)
        setfacilty(selectedFacilityData._id)
        // const newData = [ { _id: Selectedcountry, totalEmission: selectedFacilityData.totalEmission }]
        // console.log({newData})
        // setData(newData)
        setmodalopen(false)
        if (selectedFacilityData) {
            document.querySelector('.left-section').scrollTop = "100";
            // showSuccess(`Total Emission of ${facility}: ${selectedFacilityData.totalEmission}`);
        } else {
            showError(`No data found for ${facility}`);
        }
    };

    const handleModalClose = () => {
        setmodalopen(false);
    }


    const Allcountries = countries.reduce((acc, country) => {
        acc[country.label.toUpperCase()] = country.code;
        return acc;
    }, {});


    const ISSUPERADMIN = localStorage.getItem('welcome-NIRANARA').split('-')[0]?.trim() === "SUPER ADMIN"
    const ISADMIN = localStorage.getItem('welcome-NIRANARA').split('-')[0]?.trim() === "ADMIN"


    const totalEmissions = CurrentFacilityData?.facilities?.reduce((total, facility) => total + facility.totalEmission, 0);


    return (
        <div className='Country_Emmsion ' >


            <div className='map'>

                <div className='df jsb p-1'>
                    <div className='_dash_heading'>Emission by location</div>
                    <div onClick={() => setMapModal(true)} style={{ float: 'right', letterSpacing: '4px', cursor: 'pointer' }}>[]</div>


                </div>

                {/* <div className='df jsb dashboard_select_Div'>
                    <select >
                        <option>Last 30 days</option>
                        {
                            last30Days.map((item, i) => (
                                <option key={i} value={item}>{item}</option>
                            ))
                        }
                    </select>
                    <select onChange={(e) => handleCountryChange(e)}>
                        <option value="">Countries</option>
                        {data.map((item, i) => (
                            <option key={item?.flag}> {item?.name}</option>
                        ))}
                    </select>
                </div> */}
                <div>
                    <WorldVector cdata={data} color={localStorage.getItem('--theme')} size="md" />
                </div>
            </div>

            <div className='dashboard_tbl hidden_scrollbar' style={{ fontSize: '12px' }}>


                <table>
                    <thead style={{ position: 'relative', margin: 'auto', cursor: 'pointer' }}>
                        <tr >
                            <th className=''>&nbsp;&nbsp;&nbsp;&nbsp;Country</th>
                            <th>CO₂ Emissions</th>
                            <th>Emission Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((country, index) => (
                            <tr key={index}>
                                <td className='df overflow map_table' style={{ margin: '0', width: '140px', cursor: 'pointer' }} onClick={() => { setmodalopen(true); setselectedCountry(country._id); SetCurrentFacilityData(country) }}>
                                    <img height={14} src={`https://flagcdn.com/w20/${Allcountries[country._id]?.toLowerCase()}.png`} />&nbsp;{country._id}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* {selectedCountry === country._id && (
                                        <Select onChange={(e) => handleFacilityChange(e.target.value, country._id)}>
                                            {country.facilities?.map((facility, index) => (
                                                <MenuItem selected={true} key={index} value={facility._id}><img style={{ mixBlendMode: 'multiply' }} height={40} src='https://miro.medium.com/v2/resize:fit:600/1*gwQB_VLm07m11ZLyO35d2g.gif' />{facility.address}</MenuItem>
                                            ))}
                                        </Select>
                                    )} */}

                                </td>
                                <td className='overflow'>
                                    {country.totalEmission >= 1000
                                        ? (
                                            <>
                                                {(country.totalEmission / 1000).toFixed(5)} 
                                                <span className={`vsm _green`}>tons CO₂e</span>
                                            </>
                                        )
                                        : (
                                            <>
                                                {country.totalEmission.toFixed(5)}
                                                <span className={`vsm _green`}> kgCO₂e</span>
                                            </>
                                        )
                                    }
                                </td>

                                <td>{((country.totalEmission / data.reduce((acc, curr) => acc + curr.totalEmission, 0)) * 100).toFixed(2)}%</td>
                            </tr>
                        ))}

                    </tbody>
                    {selectedFacility && (
                        <tfoot>
                            <tr>
                                <td colSpan="3" className='Echart'>
                                    <span className='_green '>  <img style={{ mixBlendMode: 'multiply' }} height={30} src='https://miro.medium.com/v2/resize:fit:600/1*gwQB_VLm07m11ZLyO35d2g.gif' /><span className='' style={{ textTransform: 'uppercase vsm' }}>{selectedFacility.Name?.slice(0, 20)}</span> -  <span className='_edit ' style={{ fontSize: '10px' }}>{selectedFacility.totalEmission?.toFixed(5)}  kgCO₂e</span></span>
                                    <span className="mt-2" onClick={() => { setfacilty('All'); setSelectedFacility(null); document.querySelector('.left-section').scrollTop = "0"; }} style={{ float: 'right', transform: 'scale(1.5)', color: '', font: '16px', cursor: 'pointer' }} className=''>&times;</span>
                                </td>
                            </tr>
                        </tfoot>
                    )}
                </table>
                <br />

            </div>

            <Modal open={modalopen} onClose={() => handleModalClose()}>
                <Paper style={{ margin: 'auto 20px', marginTop: 70, width: 'auto', padding: 20 }}>
                    <img height={14} src={`https://flagcdn.com/w20/${Allcountries[CurrentFacilityData?._id]?.toLowerCase()}.png`} /> &nbsp;{CurrentFacilityData?._id}

                    <p className='text-center'> Explore Facility Data: Click on any Facility to view its data.</p>
                    <table className='Echart' style={{ margin: 'auto' }}>
                        <thead className='table-header _t_head_bg _Custom_rows' style={{ position: 'relative', display: 'contents' }}>
                            <tr >
                                <th className=''>Facility Name</th>
                                <th>Address</th>
                                {/* <th>Total Distance Travelled</th> */}
                                <th>Total Emission </th>
                                <th>Emission Percentage</th>

                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px', cursor: 'pointer' }} >

                            {CurrentFacilityData?.facilities?.map((country, index) => {
                                const emissionPercentage = (country.totalEmission / totalEmissions) * 100;
                                return (
                                    <tr key={index} onClick={() => handleFacilityChange(country._id, CurrentFacilityData?._id)}>
                                        <td>{country.Name}</td>
                                        <td className='overflow' style={{ maxWidth: '400px' }}>{country.address}</td>
                                        {/* <td>{country.totalDistanceTravelled} KM</td> */}
                                        <td>
                                            {country.totalEmission >= 1000
                                                ? (country.totalEmission / 1000).toFixed(5) + " tons CO₂e"
                                                : country.totalEmission.toFixed(5) + " kgCO₂e"}
                                        </td>
                                        <td>{emissionPercentage.toFixed(2)}%</td>
                                    </tr>
                                );
                            })}

                        </tbody>

                    </table>
                </Paper>
            </Modal>

            <Modal open={MapModal} onClose={() => setMapModal(false)}>
                {/* <MapWithMarkers data={data} marker={true}  /> */}
                <WorldVector cdata={data} color={localStorage.getItem('--theme')} size="lg" />

            </Modal>

        </div>
    )
}

export default CountriesTable
