import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from "../../../Components/utils/axios";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Grid, MenuItem, Select, Modal, Paper, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Chart from 'chart.js/auto';

function GetFuelConsuptiononTrips() {

    const [data, setData] = useState([]);
    const [status, setStatus] = useState("DistanceTravelled");
    const [streamValue, setStreamValue] = useState('All');
    const [selectedVendorData, setSelectedVendorData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const chartRef = useRef(null);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const getVehData = async () => {
        try {
            const response = await axiosInstance.get(`/getCarbonIntensity/${status}/${streamValue}`);
            console.log("carbon ", response.data);
            const sortedData = response.data?.sort((a, b) => a._id - b._id);
            setData(sortedData);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getVehData();
    }, [status, streamValue]);

    const handleStreamChange = (event) => {
        setStreamValue(event.target.value);
    };

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');
        const myChart = new Chart(ctx, {
            type: 'line',

            data: {
                labels: data.map(item => monthNames[item._id - 1]), // Assuming _id is the label for x-axis
                datasets: [
                    {
                        label: 'Total Carbon Intensity',
                        data: data.map(item => item.totalcarbonIntensity),
                        fill: false,
                        borderColor: '#eb7e0e', // Downstream color
                        backgroundColor: '#eb7e0e',
                    },
                    {
                        label: 'Average Carbon Intensity',
                        data: data.map(item => item.averageCarbonIntensity),
                        fill: false,
                        borderColor: '#488257',
                        backgroundColor: '#488257',
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            display: true, 
                            callback: function (value) {
                                return value + (status === "DistanceTravelled" ? " Kg" : " Tons");
                            }
                        },
                    },

                },
            },
        });

        return () => {
            myChart.destroy();
        };
    }, [data]);

    return (
        <div className='Echart Em_vendor p-2'>
            <label  className="_dash_heading"  >Carbon Intensity <span className="_MANDATORY"> </span></label> &nbsp; &nbsp;
            <ButtonGroup
                default
                disableElevation
                variant="contained"
                aria-label="Disabled button group"
                className="docs-button-group">
                <Button
                    onClick={() => setStatus('DistanceTravelled')}
                    style={{
                        backgroundColor: 'transparent',
                        fontSize: '10px',
                        height: '20px',
                        padding: '8px',
                        color:status === "DistanceTravelled" ?  '#fff' : '#1c486b' ,
                        backgroundColor: status === "DistanceTravelled" ? '#1c486b' : '#fff',
                    }}>
                    Distance Travelled
                </Button>
                <Button
                    onClick={() => setStatus('vehicleCapacity')}
                    style={{
                        backgroundColor: 'transparent',
                        fontSize: '10px',
                        height: '20px',
                        padding: '5px',
                        backgroundColor: status === "vehicleCapacity" ? '#1c486b' : '#fff',
                        color:status === "vehicleCapacity" ?  '#fff' : '#1c486b' 
                    }}>
                    Load Capacity
                </Button>
            </ButtonGroup>
            <br />
            <canvas ref={chartRef} width={1100} height={300}></canvas>
            <div className='stream'>
                <FormControl component="fieldset" style={{ FlexDirection: 'row' }}>
                    <RadioGroup value={streamValue} onChange={handleStreamChange} className="df">
                        <FormControlLabel value="All" control={<Radio />} label="All" />
                        <FormControlLabel value="Upstream" control={<Radio />} label="Upstream" />
                        <FormControlLabel value="Downstream" control={<Radio />} label="Downstream" />
                    </RadioGroup>
                </FormControl>
            </div>
            {data.length === 0 && <span className='_delete mt-2'>No Trips Found of status :  {status}</span>}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Paper style={{ margin: 'auto 20px', marginTop: 70, width: 'auto', padding: 20 }}>
                    <label>Emission by All Vendors <span className="_MANDATORY"> </span></label>
                    {/* Chart.js Bar Chart for modal content */}
                </Paper>
            </Modal>
        </div>
    );
}

export default GetFuelConsuptiononTrips;
