import React, { useEffect } from 'react';
import DetailsForm from './DriverDetailsForm';

function DataViewer({config, data, pdfs  ,page ,edit ,deletelink ,setSelectedRowData ,updateTripStatus ,onlyDocs}) {
  useEffect(() => {
    if(page === "rollsandpermission"){
      console.log("ros data",{...data ,Company:data.Company?._id})
      setSelectedRowData({...data ,Company:data.Company?._id,role:data.role?._id});
    }

    if(page === "SupportPage"){
      setSelectedRowData(data)
    }
    console.log("--->", data);
  }, []);

  const handleFormSubmit = (updatedData) => {
    // Handle form submission logic if needed
    console.log('Form Submitted:', updatedData);
  };


  return (
    <div className="Details_CARD">
      <DetailsForm config ={config} updateTripStatus={updateTripStatus} page={page} data={data} onSubmit={handleFormSubmit} edit={edit} deletelink={deletelink} onlyDocs={onlyDocs} />
    </div>
  );
}

export default DataViewer;
