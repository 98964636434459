import React, { useEffect, useState } from 'react';
import './tripscard.css';
import CountUp from 'react-countup';
import alltrips from "../../utils/_alltrips.svg"
import approvedtrips from "../../utils/_approvedtrips.svg"
import pendingtrips from "../../utils/_pendingtrips.svg"
import inreviewtrips from "../../utils/_inreviewtrips.svg"

const ARROW_SVG = ({ onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 512 512" onClick={onClick}>
      <path fill="currentColor" d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z" />
    </svg>
  );
};

const UD = ({ u, d, f2f, l2l, showAllCounts, minimise }) => {
  const [showCounts, setShowCounts] = useState(false);

  const toggleCounts = () => {
    setShowCounts(!showCounts);
    showAllCounts(!showCounts);
  };

  useEffect(() => {
    // Reset showCounts when minimise becomes true
    if (minimise) {
      setShowCounts(false);
      showAllCounts(false);
    }
  }, [minimise, showAllCounts]);

  return (
    <div className='UD' style={{ display: `${!showCounts ? 'grid' : 'grid'}` }} onClick={toggleCounts}>

      <>

        <span className='sm _green'><span>UPSTREAM</span><span className='SMALL_COUNT'><CountUp start={0} end={u} /></span></span>
        {/* <span className='vsm _red'><span>F2F</span><span className='SMALL_COUNT'><CountUp start={0} end={f2f} /></span></span> */}

        <span className='sm _red'><span>DOWNSTREAM</span><span className='SMALL_COUNT'><CountUp start={0} end={d} /></span></span>
        {/* <span className='vsm _green'><span>L2L</span><span className='SMALL_COUNT'><CountUp start={0} end={l2l} /></span></span> */}

        {/* <span className='vsm _green'><span>L2L</span><span className='SMALL_COUNT'><CountUp start={0} end={l2l} /></span></span>
<span style={{ position: '' }} className='vsm _red' onClick={toggleCounts}>
  {ARROW_SVG({})}
</span> */}
      </>
    </div>
  );
};

const TripCard = ({ title, statusImage, status, Data, minimise }) => {
  const [showAllCounts, setShowAllCounts] = useState(false);

  const toggleAllCounts = () => {
    setShowAllCounts(!showAllCounts);
  };



  return status === "all" ?
    <div className={`trip-card  ${minimise}`}>
      <div className='df jsb'>
        <div className="_status">All trips</div>
        <div className='trip-count'>
          {/* <CountUp start={0} end={Data?.reduce((total, trip) => total + trip.totalTrips, 0)} duration={2} /> */}

        </div>
        <div className='Trip_status_img ' >{<CountUp start={0} end={Data?.reduce((total, trip) => total + trip.totalTrips, 0)} />}</div>
      </div>
      <div className="trip-count " style={{ display: `${minimise}` ? '' : '' }}>
        <UD
          u={Data?.reduce((total, trip) => total + trip.upstreamCount, 0)}
          d={Data?.reduce((total, trip) => total + trip.downstreamCount, 0)}
          f2f={Data?.reduce((total, trip) => total + trip.f2fCount, 0)}
          l2l={Data?.reduce((total, trip) => total + trip.l2lCount, 0)}
          showAllCounts={toggleAllCounts} minimise={minimise}
        />
      </div>
    </div>
    :
    <div className={`trip-card  ${minimise}`} >
      <div className='df jsb'>
        <div className="_status">{title}</div>
        <div className='trip-count'>
          {/* <CountUp start={0} end={Data?.filter(item => item.status === status).reduce((total, trip) => total + trip.totalTrips, 0)} duration={2} /> */}
        </div>
        <div className='Trip_status_img'>
          { <CountUp start={0} end={Data?.filter(item => item.status === status).reduce((total, trip) => total + trip.totalTrips, 0)} duration={2} />}
        </div>
      </div>
      <div className="trip-count"  >
        <UD
          u={Data?.filter(item => item.status === status).reduce((total, trip) => total + trip.upstreamCount, 0)}
          d={Data?.filter(item => item.status === status).reduce((total, trip) => total + trip.downstreamCount, 0)}
          f2f={Data?.filter(item => item.status === status).reduce((total, trip) => total + trip.f2fCount, 0)}
          l2l={Data?.filter(item => item.status === status).reduce((total, trip) => total + trip.l2lCount, 0)}
          showAllCounts={toggleAllCounts}
          minimise={minimise}
        />
      </div>
    </div>;
};

const TripsCard = ({ Data, minimise }) => {
  return (
    <div className={`trip-container ${minimise ? 'Min' : ''}`} style={{ height: `${minimise ? '50px' : "auto"}` }}>
      <TripCard title="All trips" statusImage={alltrips} status="all" Data={Data} minimise={minimise} />
      <TripCard title="Completed" statusImage={approvedtrips} status="Completed" Data={Data} minimise={minimise} />
      <TripCard title="Yet To Start" statusImage={pendingtrips} status="Pending" Data={Data} minimise={minimise} />
      <TripCard title="In Progress" statusImage={inreviewtrips} status="Progress" Data={Data} minimise={minimise} />
      <TripCard title="Cancelled" statusImage={inreviewtrips} status="Cancelled" Data={Data} minimise={minimise} />
    </div>
  );
};

export default TripsCard;
