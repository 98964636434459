import "./FuelPage.css";
import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import { showSuccess, showError } from '../../toast';
import saveFormData from "./saveFormData";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
// import { v4 as uuidv4 } from "uuid";
const measurementData = [
  "Litres",
  "Barrel",
  "Cubic Meters",
  "Cubic Foot",
  "Kiloliters",
  "Megajoule",
  "Watt",
];







const AddNewFuelType = () => {
  // const history = useHistory();
  const Navigate = useNavigate();
  const generateRandom4DigitNumber = () => {
    return `FUL${Math.floor(Math.random() * 900) + 101}`;
  };

  const [selectedMeasurement, setSelectedMeasurement] = useState("");
  const [MeasurementTypeDD, setMeasurementTypeDD] = useState([]);

  const handleMeasurementSelect = (measurement) => {
    setSelectedMeasurement(measurement);
    setMeasurementType(measurement);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action") || "";
  const selectedid = searchParams.get("search") || "";

  const [fuelId, setFuelId] = useState(action === "" ? generateRandom4DigitNumber() : null);
  const [fuelType, setFuelType] = useState("");
  const [measurementType, setMeasurementType] = useState("");
  const [Image, setImage] = useState("");

  const handleClickSubmit = (e) => {
    e.preventDefault();
    if (action !== "delete") {
      if (!fuelId || !fuelType) {
        showError('Please fill out all required fields.');
        return;
      }
      if (!selectedMeasurement) {
        showError(`Please Select the Measurement type of ${fuelType}.`);
        return;
      }
    }



    saveFormData({
      fuelID: fuelId,
      fuelType: fuelType,
      Measurement: measurementType,
      fuelDoc : Image
    }, action , ()=>{
      Navigate("/fuelmaster");
    });
  

  };

  const containerStyle = {
    display: "flex",
  };

  const formStyle = {
    height:'80vh',
    overflow:'scroll',
    padding: "10px",
    borderRadius: "10px",
    margin: "none",
    marginTop: '20px',
    backgroundColor: "white",
  };

  const get_data = async () => {
    try {
      const response = await axiosInstance.get("/fuels");
      console.log("fueldata", response);
      const filteredFuel = response.data.data.find((fuel) => fuel._id === selectedid);
      return filteredFuel;
    } catch (error) {
      console.error("Axios Error:", error);
      return null;
    }
  };

  const updateFuelInputBoxes = (data) => {
    console.log("filteredFuel", data)
    setFuelId(data.fuelID)
    updateFuelMeasurement(data.fuelType)
    setMeasurementType(data.Measurement)
    setFuelType(data.fuelType)
    setSelectedMeasurement(data.Measurement)
  };
  useEffect(() => {
    const fetchData = async () => {
      if (selectedid) {
        const data = await get_data(); // Await the result of get_data
        if (data) {
          updateFuelInputBoxes(data);

        }
      }
    };

    fetchData();
  }, [selectedid]);

  const petrol = [
    "Litres",
    "Gallon",
  ]
  const diesel = [
    "Litres",
    "Gallon",
  ]
  const hydrogen = [
    "Kilograms ",
    "Cubic Meters ",
  ]
  const Electric = [
    "Kilowatt-hours",
    "watt",
  ]
  const cng = [
    "Cubic Feet ",
    "Cubic Meters",
  ]

  const updateFuelMeasurement = (FUEL) => {
    let measurementTypeArray;

    switch (FUEL) {
      case "petrol":
        measurementTypeArray = petrol;
        break;
      case "diesel":
        measurementTypeArray = diesel;
        break;
      case "hydrogen":
        measurementTypeArray = hydrogen;
        break;
      case "electric":
        measurementTypeArray = Electric;
        break;
      case "cng":
        measurementTypeArray = cng;
        break;
      default:
        measurementTypeArray = [];
    }
    setMeasurementTypeDD(measurementTypeArray);
  }

  const handlefuelChange = (e) => {
    const FUEL = e.target.value;
    setFuelType(FUEL);

    updateFuelMeasurement(FUEL)

  };


  const handleFileUpload = async (file) => {
    console.log(file)
    try {
      const formData = new FormData();
      formData.append("DriverIDfile", file);  // we are using only url as a string 
      console.log("formData", formData)
      const response = await axiosInstance.post(
        "/uploadDriverID",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response?.data)
      return response?.data?.imageurl?.Location


    } catch (error) {
      console.log(error)
    }
  };

  const handleFileChange = async (file, name) => {
    // // debugger;
    const a = await handleFileUpload(file)
    setImage(a);

  };

  const handleBrowseClick = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };


  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={{ width: "100%", margin: '10px' }}>
        <Header />
        <div className="table-heading"><span onClick={() => Navigate('/fuelmaster')}>⬅️</span>{action ? action + '>' :'Add New'} Fuel  <span className={action ?`_${action}`:''}>{` ${fuelType} in ${selectedMeasurement}`}</span></div>
        <div className="hidden_scrollbar" style={formStyle}>
          <form className="fuelform">
            <div className="df jsb" >
              <Grid item xs={4} className="mt-3">
                <InputLabel id="fuelID">Fuel ID</InputLabel>
                <TextField
                  sx={{ width: '300px', height: '39px' }}
                  id="fuelId"
                  // label="Fuel ID"
                  disabled
                  value={fuelId}
                  onChange={(e) => setFuelId(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={4} className="mt-3">
                <InputLabel id="fuelType">Fuel Type <span className="_MANDATORY">*</span></InputLabel>
                <FormControl style={{ width: "300px", height: '39px' }}>
                  {/* <InputLabel id="fuelType-label">Fuel Type</InputLabel> */}
                  <Select
                    labelId="fuelType-label"
                    id="fuelType"
                    value={fuelType}
                    onChange={(e) => handlefuelChange(e)}
                    required
                  >

                    <MenuItem value="petrol">Petrol</MenuItem>
                    <MenuItem value="diesel">Diesel</MenuItem>
                    <MenuItem value="hydrogen">Hydrogen</MenuItem>
                    <MenuItem value="electric">Electric</MenuItem>
                    <MenuItem value="cng">CNG</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} className="mt-3">
                <label htmlFor="fuelType">Measurement Type <span className="_MANDATORY">*</span></label><br />
                <FormControl style={{ width: "300px", height: '39px' }}>

                  <Select
                    // labelId="measurementType-label"
                    id="measurementType"
                    value={selectedMeasurement}
                    onChange={(e) => handleMeasurementSelect(e.target.value)}
                    required
                  >
                    {/* <MenuItem value="">Select Measurement Type</MenuItem> */}
                    {MeasurementTypeDD.map((measurement, index) => (
                      <MenuItem key={index} value={measurement}>
                        {measurement}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            </div>
            <Grid item xs={12} className="mt-3">
              <label htmlFor="col8">Fuel ID Upload</label>
              <div
                style={{
                  width: "100%",
                  padding: "35px",
                  border: "2px dotted lightgrey",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: "12px",
                }}
              >
                <label>
                  {Image == "" ? `Drag and Drop or` : <img src={Image} />}
                  <input
                  style={{display:'none'}}
                    type="file"
                    id="fileInput"
                    name="fuelDoc"
                    onChange={(e) => handleFileChange(e.target.files[0], 'fuelDoc')}

                  />
                  {Image == "" &&
                    <Button
                      sx={{
                        backgroundColor: "rgba(192, 192, 192, 1)",
                        color: "white",
                      }}
                      onClick={(e) => handleBrowseClick(e)}
                    >
                      browse
                    </Button>}
                </label>
                <input
                  type="file"
                  name="image"

                  accept="*"
                  style={{ display: "none" }}
                />

              </div>
            </Grid>
            <div className="df mt-3 jsb">

              <button
                style={{
                  color: "white",
              
                  backgroundColor: "black",
                  width: "20%",
                  padding: "14px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
                // type="submit"
                onClick={handleClickSubmit}
              >
                {action ? action.toUpperCase() : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewFuelType;
