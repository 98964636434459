import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import avatar from "../../utils/Avatar.png";
import LanguageIcon from "@mui/icons-material/Language";
// import { useHistory } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Avatar, Menu, MenuItem } from "@mui/material";
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { highlightWord } from "../utils/hightlight"
import ColorPaletteToggle from "../utils/Cpallete";
import Profile from "../utils/Profile";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
const [UserData,setUserData] = useState(null);
  const [word, setSearchTerm] = useState("");


  // const history = useHistory();

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('token');
    // Navigate to the login page
    // history.push("/login");
    // Navigate("/login")
    // Close the menu
    // Navigate to the login page using useNavigate
    navigate("/");
    handleMenuClose();

  };


  useEffect(() => {
    const removeHighlight = () => {
      const highlightedSpans = document.querySelectorAll('.highlight');
      highlightedSpans.forEach(span => {
        const text = document.createTextNode(span.innerText);
        span.parentNode.insertBefore(text, span);
        span.parentNode.removeChild(span);
      });
    };

    const USER = JSON.parse(localStorage.getItem('_USER'));
    // console.log(USER)
    
    
    setUserData(USER)

  }, [])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
    highlightWord(e.target.value);
  };

  function firstLetters(word) {
    const words = word.split(" ");
    let result = "";
    for (let i = 0; i < Math.min(words.length, 2); i++) {
      if (words[i].length === 2) {
        result += words[i];
      } else {
        result += words[i][0];
      }
    }
    return result?.toUpperCase();
  }

 

  return (
    <div>
      <nav
        className="navbar navbar-light"
        style={{
          display: "flex",
          flexDirection: "row",
          padding: '2px 10px',
          justifyContent: "space-between",
          transform: "scale(0.8)",
          justifyContent: "space-between",

          borderRadius: "3px",
          position: 'absolute',
          right: '0',
          top: '0'
        }}
      >
        <div>
          <form className="container-fluid" >
            <div className="input-group" style={{ position: "relative" }}>

              <div style={{ display: 'none' }}>
                <input
                  onChange={handleSearchChange}
                  value={word}
                  className="form-control"
                  placeholder={`Welcome  ${localStorage.getItem("welcome-NIRANARA")?.split('-')[1] || ''}`}
                  aria-describedby="basic-addon1"
                  style={{
                    paddingLeft: "10px",
                    resize: "none",
                    border: "none",
                    borderRadius: "5px",

                  }}
                />

              </div>
            </div>
          </form>
        </div>

        <div style={{ display: 'flex',gap:'8px', justifyContent: 'space-between' }}>
          {/* <SearchIcon />
          <LanguageIcon /> */}
          {/* <ColorPaletteToggle /> */}
          {/*  <NotificationsNoneIcon /> */}


          <div className="USER_DETAILS">
            <span className="USER">{UserData?.name}</span>
          </div>

          <div className="avatar_div" style={{ width: '', height: '' }} title={localStorage.getItem('COMPANY')} onClick={handleAvatarClick}>
            <span className="" >🟢</span>
            <img src={UserData?.Company?.profile} title={UserData?.Company?.Company} />
     </div>
        </div>

        {/* Avatar dropdown menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} >{UserData?.role?.roleName}</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </nav>
    </div>
  );
};

export default Header;
