import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useNavigate } from "react-router-dom"; // Import useHistory from react-router-dom
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axiosInstance from "../../Components/utils/axios"

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
// Correct import statement
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import { useMemo } from "react";
import SaveFormData from "./saveFormData";
import axios from "axios";
// import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import { TextField } from "@mui/material";
import { showSuccess, showError } from "../../toast";

const LocationMasterForm = () => {
  // const history = useHistory(); // Initialize history
  const [locationId, setLocationId] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [locationName, setLocationName] = useState("WTT");
  const [locationdoc, setLocationdoc] = useState("");
  const [address, setAddress] = useState("");

  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search?.slice(1));
  const action = searchParams.get(`action`) || "";
  const search = searchParams.get("search") || "";
  const lat = searchParams.get("lat") || "";
  const log = searchParams.get("log") || "";
  // alert(search)
  //For the google map section used below the form
  // const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const generateLocationId = (name) => {
    const sequentialNumber = Math.floor(Math.random() * 100) + 101;
    return `LOC${sequentialNumber}`;
  };

  const [coordinates, setCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log(position.coords)
          const { latitude, longitude } = position.coords;

          setCoordinates({ latitude, longitude });
          const obj = { lat: parseFloat(lat), lng: parseFloat(log) }
          const allow = { lat: latitude, lng: longitude }
          //console.log("obj",obj)
          setCenter(action == "" ? allow : obj);
        },
        function (error) {
          console.error("Error getting geolocation:", error);
        }
      );
    }
  }, []);



  const getLoationId = async () => {
    let location = await axiosInstance.get(
      "/location?sort=-1&limit=1"
    );
    if (location?.data?.userLocations.length > 0) {
      const { locationID } = location?.data?.userLocations[0];
      let id = parseInt(locationID.slice(3), 10);
      // alert(search)
      setLocationId(`LOC${id + 1}`);
    }
    else {
      setLocationId(`LOC1`)
    }

  };



  useEffect(() => {
    if (search == "") {
      getLoationId();
    }

  }, [search]);

  useEffect(() => {


    setCoordinates({ latitude: lat, longitude: log })
  }, [lat, log])


  const UpdateLocationData = async () => {
    let location = await axiosInstance.get(
      "/location"
    );
    const data = location.data.allLocations;
    console.log(data)
    var selectedData = data?.filter((data) => data._id === search);
    if (selectedData) {
      setLocationId(selectedData[0]?.locationID)
      setAddress(selectedData[0]?.locationName)
      setCoordinates({ latitude: selectedData[0]?.latitude, longitude: selectedData[0]?.longitude })
      if (action != "") {
        setCenter({ lat: selectedData[0]?.latitude, lng: selectedData[0]?.longitude })
      }
    }

    //  
  }

  useEffect(() => {
    UpdateLocationData()

  }, [search])

  //for the isloaded dataset in the map
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyC1Cz13aBYAbBYJL0oABZ8KZnd7imiWwA4",
  });
  const [map, setMap] = React.useState(null);
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);

      const additionalInfo = extractAdditionalInfo(results[0]);

      setCoordinates({
        latitude: latLng.lat,
        longitude: latLng.lng,
      });

      setCenter({
        lat: latLng?.lat,
        lng: latLng?.lng,
      });

      setLocationName(additionalInfo.locationName);
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  useEffect(() => {
    if (map && center) {
      onLoad(map, center);
    }
  }, [map, center]);

  const handleMapClick = (event) => {
    const clickedLat = event.latLng.lat();
    const clickedLng = event.latLng.lng();

    setCoordinates({
      latitude: clickedLat,
      longitude: clickedLng,
    });

    // Use the reverse geocoding to get location details
    reverseGeocode({ lat: clickedLat, lng: clickedLng });
  };
  const reverseGeocode = async (location) => {
    try {
      console.log("location", location)
      const results = await geocodeByLatLng(location);

      if (results && results.length > 0) {
        console.log(results[0])
        const additionalInfo = extractAdditionalInfo(results[0]);
        setLocationName(additionalInfo.locationName);
        setAddress(additionalInfo.locationName); // Set address based on your requirement
        // Update other state variables if needed

        // You can also update the form fields with the selected location details if needed
        // setLocationId(additionalInfo.locationId);
      }
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };
  const geocodeByLatLng = async (location) => {
    try {
      const results = await geocodeByAddress(
        `${location.lat}, ${location.lng}`
      );
      return results;
    } catch (error) {
      console.error("Error geocoding by LatLng:", error);
    }
  };
  const onLoad = React.useCallback(function callback(map, center) {
    // This is just an example of getting and using the map instance!!! Don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const extractAdditionalInfo = (geocodingResult) => {
    const locationName =
      geocodingResult?.address_components
        ?.filter((component) => component.types.includes("route"))
        .map((component) => component.long_name)
        .join(" ") || "";

    const locationId = generateLocationId(locationName);
    return {
      locationName,
      locationId,
    };
  };



  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (action !== "delete") {
        if (
          !document.getElementById('locationId').value ||
          !address ||
          !coordinates.latitude ||
          !coordinates.longitude
        ) {
          showError("Please fill out all fields.");
          return;
        }
      }
// alert("Fd")
      // // debugger;
      // alert(document.getElementById('locationId').value)
      //  alert(locationdoc)
      const reqBody = {
        locationID: locationId || document.getElementById('locationId').value,
        locationName: address,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        locationdoc: locationdoc,
        createdBy: localStorage.getItem('user_id'),
        Company: localStorage.getItem('COMPANY')
      };
      console.log(reqBody)
      const updatedData = await SaveFormData(reqBody, action, () => navigate('/locationmaster'));

    } catch (error) {
      console.error(error);
      showError("An error occurred. Please try again.");
    }
  };

  const handleFileUpload = async (file) => {
    // // debugger;
    console.log(file)
    try {
      const formData = new FormData();
      formData.append("DriverIDfile", file);
      console.log("formData", formData)
      const response = await axiosInstance.post(
        "/uploadDriverID",  //using te url only dosent mind upload driverid api 
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response?.data)
      return response?.data?.imageurl?.Location

    } catch (error) {
      console.log(error)
    }
  };

  const handleFileChange = async (file, name) => {
    // // debugger;
    const a = await handleFileUpload(file)
    setLocationdoc(a);
  };

  return (
    <div>
      <div className="df">
        <div >
          <Sidebar />
        </div>
        <div style={{ width: "100%", margin: "10px" }}>
          <Header />
          <div>
            <div className="table-heading theme_c"><span onClick={() => navigate('/locationmaster')}><ArrowBackIcon /></span>{action ? action : '  Add New'} Location {address && <span className={action == "delete" ? '_delete' : "_edit"}>{` ${address.slice(0, 80)}...`}</span>}</div>
            <div className="df jsb mt-3" style={{ background: "#fff", height: '89vh' }}>
              <div
                style={{
                  padding: "0 12px",
                  borderRadius: "10px",
                  margin: "none",

                  marginTop: "10px",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <label htmlFor="locationId">Location ID</label>

                      <TextField
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Location Master"
                        id="locationId"
                        value={locationId}
                        onChange={(e) => setLocationId(e.target.value)}
                        required
                        disabled
                      />

                    </div>
                    <div className="Addressbutton">
                      <label htmlFor="address">Location Name <span className="_MANDATORY">*</span></label>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <TextField
                              {...getInputProps({
                                placeholder: "Enter your address",
                                className: "location-search-input",
                                style: {
                                  width: "100%", // Ensure the input takes full width
                                  // Adjust padding as needed
                                  // Add a border for better visibility
                                  // border: "1px solid red",
                                  height: "2px", // Set a fixed height for the input field
                                },
                              })}
                              required
                            />
                            <div
                              // className="autocomplete-dropdown-container"
                              style={{
                                maxHeight: "40px",
                                Width: "100%",
                                maxWidth: "100%",
                                zIndex: "999",
                                marginTop: "20px",
                                boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                                background: "#fff",
                                opacity: "1",
                                position: "relative",
                              }}
                            >
                              {loading && (
                                <div
                                  style={{ height: "40px", lineHeight: "40px" }}
                                >
                                  Loading...
                                </div>
                              )}
                              <div
                                style={{
                                  backgroundColor: "white",
                                }}
                              >
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#1c486b"
                                      : "",
                                    padding: "10px", // Adjust padding as needed
                                    border: "1px solid grey",
                                  };
                                  return (
                                    <div
                                      className="suggestion"
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <br />

                  <div>
                    <div>
                      <label htmlFor="latitude">Latitude</label>

                      <TextField
                        style={{ width: "100%" }}
                        type="text"
                        id="latitude"
                        value={coordinates.latitude}
                        placeholder="Latitude"
                        readOnly
                      />
                      <br />
                      <br />
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label htmlFor="longitude">Longitude</label>
                      <TextField
                        type="text"
                        id="longitude"
                        value={coordinates.longitude}
                        placeholder="Longitude"
                        readOnly
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <label htmlFor="longitude">Docs Upload</label>
                      {
                        locationdoc == "" ?
                          <TextField
                            type="file"
                            id="locationdoc"
                            value={locationdoc}
                            onChange={(e) => handleFileChange(e.target.files[0], 'DriverIDfile')}
                            placeholder="locationdoc"
                            readOnly
                          />
                          :
                          <img src={locationdoc} style={{ maxWidth: '50px', maxHeight: '50px' }} />
                      }

                    </div>
                  </div>

                  <div style={{ textAlign: "center" }}>
                    <button
                      className="submit_btn"
                      onClick={handleSubmit}
                      style={{
                        color: "white",
                        backgroundColor: "black",
                        width: "100%",


                        borderRadius: "5px",
                        textAlign: "center",
                        marginTop: "5%",
                        fontFamily: "Lora",

                      }}
                      type="submit"
                    >

                      <> {action == "" ? `Submit` : action.toUpperCase()} </>

                    </button>
                  </div>
                </form>
              </div>
              {/* For the map container used below the Form */}
              <div>
                <div className="App" >
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={{
                        width: "100%",
                        height: "512px",
                      }}
                      center={center}
                      zoom={1}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      onClick={handleMapClick}
                    >
                      {coordinates.latitude && coordinates.longitude && (
                        <Marker
                          position={{
                            lat: coordinates.latitude,
                            lng: coordinates.longitude,
                          }}
                          onClick={() => {
                            setSelectedMarker({
                              lat: coordinates.latitude,
                              lng: coordinates.longitude,
                            });
                          }}
                        />
                      )}
                    </GoogleMap>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationMasterForm;
