import React from 'react'

function HeroDesign() {
  return (
    <div>
      dsds
    </div>
  )
}

export default HeroDesign
