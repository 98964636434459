import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useJsApiLoader } from '@react-google-maps/api'; // Import useJsApiLoader
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'; // Import geocoding functions

// Define the flag icon
const flagIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + "/blink.gif",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const MapWithMarkers = ({ data }) => {
    const [markers, setMarkers] = useState([]);

    // Load the Google Maps JavaScript API script
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyC1Cz13aBYAbBYJL0oABZ8KZnd7imiWwA4",
    });

    useEffect(() => {
        const fetchCoordinates = async () => {
            const updatedMarkers = [];
            for (const country of data) {
                const countryName = country._id;
                const address = country.facilities[0].address; 
                try {
                    
                    const results = await geocodeByAddress(address);
                    const latLng = await getLatLng(results[0]);
                    updatedMarkers.push({ countryName, latLng });
                } catch (error) {
                    console.error(`Error fetching coordinates for ${countryName}:`, error);
                }
            }
            setMarkers(updatedMarkers);
        };

        if (isLoaded) {
            fetchCoordinates();
        }
    }, [data, isLoaded]);

    return (
        <MapContainer center={[0, 0]} zoom={1} style={{ height: '500px', width: '100%' }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.map((marker, index) => (
                <Marker key={index} position={[marker.latLng.lat, marker.latLng.lng]} icon={flagIcon}>
                    <Popup>{marker.countryName}</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default MapWithMarkers;
