
const LocationMasterConfig = async () => {
    try {
     
        return {
            _id: { type: 'none', disabled: true },
            __v: { type: 'none', disabled: true },
            locationID: { type: 'text', disabled: true, label: 'Location ID' },
            locationName: { type: 'text', label: 'Location Name', required: true },
            latitude: { type: 'text', label: 'Latitude', required: true },
            longitude: { type: 'text', label: 'Longitude', required: true },
            createdAt: { type: 'none', label: 'Phone Number', required: false },
            updatedAt: { type: 'none', label: 'Phone Number', required: false },
        };
    } catch (error) {
        console.error('Error fetching Driver  data:', error);
        return {};
    }
};

export default LocationMasterConfig;
