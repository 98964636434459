import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar"; // Assuming the correct path to your Sidebar component
import Header from "../../Components/Header/Header";
import axios from "axios";
import axiosInstance from "../../Components/utils/axios"
import { Link, useLocation } from "react-router-dom";
import { showSuccess } from "../../toast";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Typography } from "@mui/material";
import notfound from "../../utils/notfound.png"

const CompanyMaster = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get("search") || "";

    const [data, setData] = useState([]);
    const containerStyle = {
        display: "flex",
    };

    const tableStyle = {
        width: "100%",
        margin: "10px",
    };


    useEffect(() => {
        axiosInstance.get('/companies').then((res) => {
            setData(res.data?.reverse())
        })
    }, [])


    return (
        <div style={containerStyle}>
            <div>
                <Sidebar />
            </div>

            <div style={tableStyle}>
                <div className="Site_Header">
                    <Header />
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="table-heading">Company Master</div>

                </div>
                <div>
                    {/* <VehicleMasterTable data={data} /> */}
                    {
                        data.length > 0 ?
                            <TableComponent addLink="AddnewCompanyMaster" pagename="Company" page="CompanyMaster" data={data} exclude={['_id', '__v', 'countryCode']} edit={`/AddnewCompanyMaster?action=update&search=`} deletelink={`/AddnewCompanyMaster?action=delete&search=`} />
                            :
                            <>


                                <div class="wrapper">
                                    <div>
                                        <div class="img">
                                            <img src={notfound} />
                                        </div>
                                        <br />
                                        <h6>NO Vendor  Found </h6>
                                        <Link to="/AddnewVendorMaster" >
                                        <button className=" start_creating">START CREATING {window.location.pathname?.slice(1)}</button>

                                    </Link>
                                    </div>
                                </div>
                               

                            </>
                    }

                </div>
            </div>
        </div>
    );
};

export default CompanyMaster;
