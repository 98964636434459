import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
  import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from "uuid";
import { showSuccess, showError } from "../../toast";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import axiosInstance from "../../Components/utils/axios";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import DownloadIcon from '@mui/icons-material/Download';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

const VehicleForm = () => {
 

  // const history = useHistory();
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const navigate = useNavigate()
  const [fuelData, setfuelData] = useState([])
  const [vendorData, setvendorData] = useState([])

  // Validation function to check if required fields are not empty
  const validateForm = () => {

    // vehicleID: generateRandom4DigitNumber(),
    // vehicleNumber: "",
    // vehicleType: "",
    // vehicleMake: "",
    // vehicleModel: "",
    // vehicleYear: "",
    // distancemeasure:"litre",
    // lastService: selectedDate,
    // vehiclePOC: selectedDate1,
    // vehicleEffeciency: "",
    // vehicleCapacity: "",
    // fuelType: "",

    if (!vehicleData.vehicleNumber) {
      showError("Please Enter the Vehicle No.");
      return false;

    }

    if ( vehicleData?.vehicleNumber?.length > 6) {
      showError(`${vehicleData?.vehicleNumber} is not a valid vehicle No`);
      return false;

    }
    if (!vehicleData.vehicleType) {
      showError("Please Enter the Vehicle Type");
      return false;

    }
    if (!vehicleData.vehicleMake) {
      showError("Please Enter the Vehicle Make");
      return false;

    }
    if (!vehicleData.vehicleModel) {
      showError("Please Enter the Vehicle Modal");
      return false;

    }
    if (!vehicleData.vehicleYear) {
      showError("Please Enter the Vehicle Year");
      return false;

    }
    if (!vehicleData.lastService) {
      showError("Please Enter the Vehicle Last Service");
      return false;

    }
    if (!vehicleData.vehicleEffeciency) {
      showError("Please Enter the vehicle Effeciency");
      return false;

    }

    if (!vehicleData.fuelType) {
      showError("Please Enter the Fuel Type");
      return false;

    }
    // if (!vehicleData.vehicleCapacity ) {
    //   showError("Please Enter the vehicle Capacity Type");
    //   return false;

    // }




    return true;
  };
  const [purchaseDate, setpurchaseDate] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDate1, setSelectedDate1] = useState("");
  // const [pucfile, setpucfile] = useState("")

  function isWithinLast365Days(purchaseDate) {
    var currentDate = new Date();
        var timeDifference = currentDate.getTime() - purchaseDate.getTime();
    var daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference <= 365;
} 


  const handlepurchasedateChange = (date) => {

   
    
    
      const updatedVehicleData = {
        ...vehicleData,
        vehicleYear: formatDate(date),
        vehicleCondition: isWithinLast365Days(date)
      };

    setpurchaseDate(date);
    setVehicleData(updatedVehicleData);
    console.log(vehicleData);
  };
  

  const handleDateChange = (date) => {
    // alert(`last ${date}`);
    setSelectedDate(date);
    setVehicleData({
      ...vehicleData,
      lastService: formatDate(date),
    });
    console.log(vehicleData);
  };

  const handleDateChange1 = (date) => {
    // alert(`puc ${date}`);
    setSelectedDate1(date);
    setVehicleData({
      ...vehicleData,
      vehiclePOC: formatDate(date),
    });
  };

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, index) => currentYear - index);
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const [DocsUpload, setDocsUpload] = useState(false)
  const [vehicleData, setVehicleData] = useState({
    vehicleID: "",
    vehicleNumber: "",
    vendor: "",
    category: "",
    vehicleType: "",
    transportType: "",
    transport: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleYear: "",
    vehicleMonth: "",
    distancemeasure: "Km",
    lastService: selectedDate,
    vehiclePOC: selectedDate1,
    vehicleEffeciency: "",
    vehicleCapacity: "0",
    fuelType: "",
    vehicleCondition: true,
    pucFile: "",
    polutionFile: "",
    rcFile: "",
    rcinsuranceFileFile: ""
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action") || "";
  const VEHID = searchParams.get("search") || "";

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ':' + value);

//     const currentYear = new Date().getFullYear();
//     const isCurrentYear = name === "vehicleYear" && parseInt(value.split("/")[1]) === currentYear;
// alert(isCurrentYear)
    let updatedVehicleData = {
      ...vehicleData,
      [name]:
        name === "lastService" || name === "vehiclePOC"
          ? formatDate(value)
          : value
    };

    // If the vehicleMonth is selected, update vehicleYear accordingly
    // if (name === "vehicleMonth") {
    //   updatedVehicleData = {
    //     ...updatedVehicleData,
    //     vehicleYear: currentYear - 1
    //   };
    // }


    // const isVehicleOneYearOld = parseInt(updatedVehicleData.vehicleYear) === currentYear - 1 &&
    //   parseInt(updatedVehicleData.vehicleMonth) <= (new Date().getMonth() + 1);

    // updatedVehicleData = {
    //   ...updatedVehicleData,
    //   vehicleCondition: isVehicleOneYearOld
    // };

    setVehicleData(updatedVehicleData);
  };

  
  const generateRandom4DigitNumber = async () => {
    try {
        const response = await axiosInstance.get("/vehicle");
        // // debugger
        const sortedData = response?.data.data?.reverse()

        const latestIndustry = sortedData[0];
        console.log("latestIndustry", latestIndustry)
        let id = 1;
        if (latestIndustry) {
            const latestID = latestIndustry.vehicleID;
            id = parseInt(latestID?.substring(3)) + 1;
        }
        return `VEH${id}`;
    } catch (error) {
        console.error("Error fetching latest Vendor ID:", error);
        return `VNDR0`;
    }
};


useEffect(() => {
    const fetchData = async () => {
        const randomID = await generateRandom4DigitNumber();
        setVehicleData((prev) => ({
            ...prev,
            vehicleID: randomID 
        }));
    };

    fetchData();
}, []);



  const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };


  const makeApiCall = async (method, url, data, callback) => {
    try {

      const response = await axiosInstance[method.toLowerCase()](url, {
        ...data
      });

      console.log("res", response)

      if (response.data.success == false) {
        showError(response?.data?.message)
      }
      else {
        showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
        callback()
        return response.data;
      }

    } catch (error) {

      console.error("Error making API call:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };
  const uploadFile = async (file, name) => {
    try {
      console.log(file)
      const formData = new FormData();
      formData.append('pucFile', file);

      const response = await axiosInstance.post(
        "/uploadPUCFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("uploads", response)

      return response?.data?.imageurl?.Location;
    } catch (error) {
      console.log(error);
      // Handle error appropriately (e.g., show an error message)
      return null;
    }
  };

  // useEffect(() => {
  //   const handlePucFileUpload = async () => {
  //     if (!vehicleData.vehicleCondition && pucfile !== "") {
  //       try {
  //         const file = await UploadPucFile();
  //         setVehicleData({
  //           ...vehicleData,
  //           pucFile: file,
  //         });
  //       } catch (error) {
  //         console.error("Error uploading PUC file:", error);
  //       }
  //     }
  //   };

  //   handlePucFileUpload();
  // }, [pucfile, vehicleData.vehicleCondition]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateForm();

    const newdata = {
      ...vehicleData,
      TransportType: vehicleData.category,
      vehicleCategory: vehicleData.transportType,
      vehicle: vehicleData.transport,
      vehicleYear: vehicleData.vehicleYear,
      createdBy: localStorage.getItem('user_id'),
      Company :localStorage.getItem('COMPANY')
    };

    console.log("newvehdata", newdata)


    if (isValid) {
      try {

        const responseData = await makeApiCall(
          `${action == "" ? 'POST' : action == 'update' ? 'PUT' : 'DELETE'}`,
          `/${action == "" ? `addvehicle` : action == 'update' ? `updatevehicle/${vehicleData.vehicleID}` : `deletevehicle/${vehicleData.vehicleID}`}`,
          newdata, () => { navigate('/VehicleMaster') }
        );

        console.log(responseData);



      } catch (error) {

        console.error("Error submitting form:", error);
      }
    }
  };


  const get_fuel = async () => {
    await axiosInstance.get("/fuels")
      .then((response) => {
        console.log("fueldata", response);
        setfuelData(response.data.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };
  const get_Vendor = async () => {
    await axiosInstance.get("/vendors")
      .then((response) => {
        console.log("vendors", response);
        setvendorData(response.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  const get_Vehical = async () => {
    await axiosInstance.get("/vehicle")
      .then((response) => {
        console.log("veh", response.data);
        const selectedVeh = response.data.data.find((id) => id._id === VEHID);
        console.log("filter veh", selectedVeh);
        console.log("years", selectedVeh?.vehicleYear.split('-')[0]);
        setVehicleData({
          ...vehicleData,
          vehicleID: selectedVeh?.vehicleID,
          vehicleType: selectedVeh?.vehicleType,
          vehicleNumber: selectedVeh?.vehicleNumber,
          vendor: selectedVeh?.vendor,
          vehicleMake: selectedVeh?.vehicleMake,
          vehicleModel: selectedVeh.vehicleModel,
          vehicleYear: selectedVeh.vehicleYear?.split('-')[0],
          vehicleMonth: selectedVeh.vehicleYear.split('-')[1],
          lastService: selectedVeh.lastService,
          vehiclePOC: selectedVeh.vehiclePOC,
          vehicleEffeciency: selectedVeh.vehicleEffeciency,
          vehicleCapacity: selectedVeh.vehicleCapacity,
          vehicleCondition: selectedVeh.vehicleCondition,
          fuelType: selectedVeh.fuelType,
          pucFile: selectedVeh.pucFile,
          rcFile: selectedVeh.rcFile,
          polutionFile: selectedVeh.polutionFile,
          insuranceFile: selectedVeh.insuranceFile,
          category: selectedVeh.TransportType,
          transportType: selectedVeh.vehicleCategory,
          transport: selectedVeh.vehicle
        });
        // alert(selectedVeh.TransportType)


        // vehicleNumber: "",
        // vehicleType: "",
        // vehicleMake: "",
        // vehicleModel: "",
        // vehicleYear: "",
        // lastService: selectedDate,
        // vehiclePOC: selectedDate1,
        // vehicleEffeciency: "",
        // vehicleCapacity: "",
        // fuelType: "",
      })
      .catch((error) => {
        console.error("Axios Error:", error.message);
      });
  };

  useEffect(() => {
    get_fuel();
    get_Vendor()
  }, []);

  useEffect(() => {
    get_Vehical()
  }, [VEHID])


  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#dfedd1",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));


  const toggleVehCondition = (e) => {
    setDocsUpload((prev) => !prev)
  }

  const handleDocsUpload = async (value, name) => {
    console.log(value)
    const fileUrl = await uploadFile(value, name);
    //  alert(`${name}-${value}`)
    setVehicleData((prevValues) => ({
      ...prevValues,
      [name]: fileUrl,
    }));

    console.log(vehicleData)
  }

  const vehicleTypesMap = {
    passenger: { 'Public Transpotation (PT)': 'PT', 'Privately Owned (PO)': 'PO', 'Company Owned (CO)': 'CO' },
    freight: { 'Heavy Commercial Vehicle (HCV)': 'HCV', 'Light commercial vehicle (LCV)': 'LCV', 'Manufacturing Commercial Vehicles (MCV)': 'MCV', 'Small Commercial Vehicle (SCV)': 'SCV' },
  };

  const transportTypesMap = {
    PT: { 'buses': 'Buses', 'metro': 'Metro', 'taxi': 'Taxi / Cab' },
    PO: { 'car': 'Car', 'motercycle': 'Motorcycle / Scooter' },
    CO: { 'buses': 'Buses', 'car': 'Car', 'motercycle': 'Motercycle / Scooter' },
    HCV: { 'tractor-trailers': 'Tractor-Trailers / Articulated-Lorries / Flatbed Trucks', 'tanker-trucks': 'Tanker Trucks', 'refrigrator-trucks': 'Refrigerator Truck' },
    LCV: { 'pickup-truck': 'Pick-Up Trucks', 'tanker-trucks': 'cargo-vans', 'Cargo (Vans)': 'Refrigerator Truck' },
    MCV: { 'box-truck': 'Box Trucks', 'delivery-trucks': 'Delivery Trucks', 'flatbed-trucks': 'Flatbed Trucks' }
  }

  const transportMap = {
    passenger_PT_buses: { 'heavy-buses': 'Heavy Buses', 'medium-Buses': 'medium Buses' },
    passenger_PO_car: { 'sedan': 'Sedan', 'suv': 'SUV', 'hatchback': 'HatchBack' },
    passenger_CO_buses: { 'heavy-buses': 'Heavy Buses', 'medium-Buses': 'medium Buses' },
    passenger_CO_car: { 'sedan': 'Sedan', 'suv': 'SUV', 'hatchback': 'HatchBack' },
    defualt: { 'none': 'none' },
  }



  return (
    <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
      <div>
        <Sidebar />
      </div>
      <div style={{ width: "100%", margin: "10px", marginLeft: "1%" }}>
        <Header />
        <div className="table-heading theme_c"><span onClick={() => navigate('/VehicleMaster')}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New'} Vehicle  <span className={action ? `_${action}` : ''}>{` ${vehicleData.vehicleNumber}`}</span></div>
        <Paper elevation={3} style={{ padding: "20px", marginTop: "10px", height: '89vh', overflow: 'scroll' }}>
          <form className="vehicleform">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <label htmlFor="col1">Vehicle ID</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled
                  id="col1"
                  name="vehicleID"
                  onChange={handleInputChange}
                  placeholder=""
                  value={vehicleData.vehicleID}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col2">Associated Vendor <span className="_MANDATORY">*</span></label>
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="vendor"
                    value={vehicleData.vendor || ''}
                    onChange={handleInputChange}
                  >
                    {vendorData?.map((fuel, i) => (
                      <MenuItem key={i} value={fuel._id} className="">
                        <span>
                          {fuel.name}&nbsp;
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Vehicle Number  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="number"
                  fullWidth
                  id="col2"
                  value={vehicleData?.vehicleNumber.toString()?.slice(0,8)}
                  name="vehicleNumber"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col2">Transport type <span className="_MANDATORY">*</span></label>
                  <Select
                    // label="Type"
                    fullWidth
                    id="col3"
                    name="category"
                    value={vehicleData.category || ''}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="passenger">Passenger</MenuItem>
                    <MenuItem value="freight"> Freight</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {vehicleData.category !== "" &&
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <label htmlFor="col2">Vehicle Type  <span className="_MANDATORY">*</span></label>
                    <Select
                      // label="Type"
                      fullWidth
                      id="col3"
                      name="vehicleType"
                      value={vehicleData.vehicleType || ''}
                      onChange={handleInputChange}
                    >
                      {vehicleData.category && Object.keys(vehicleTypesMap[vehicleData.category]).map((description, index) => (
                        <MenuItem key={index} value={vehicleTypesMap[vehicleData.category][description]}>
                          {description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              }

              {vehicleData.vehicleType !== "" &&
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <label htmlFor="col2">Vehicle Category <span className="_MANDATORY">*</span></label>
                    <Select
                      fullWidth
                      id="col3"
                      name="transportType"
                      value={vehicleData.transportType}
                      onChange={handleInputChange}
                    >
                      {Object.keys(transportTypesMap[vehicleData?.vehicleType]).map((description, index) => (
                        <MenuItem key={index} value={description}>
                          {transportTypesMap[vehicleData?.vehicleType][description]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

              }

              {
                vehicleData?.category !== "" &&
                vehicleData?.vehicleType !== "" &&
                transportMap[`${vehicleData?.category}_${vehicleData?.vehicleType}_${vehicleData?.transportType}`] &&
                vehicleData?.transportType !== "" && (
                  <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined">
                      <label htmlFor="col2">Vehicle sub-Category  <span className="_MANDATORY">*</span></label>
                      <Select
                        fullWidth
                        id="col3"
                        name="transport"
                        value={vehicleData.transport}
                        onChange={handleInputChange}
                      >
                        {transportMap[`${vehicleData?.category}_${vehicleData?.vehicleType}_${vehicleData?.transportType}`] ?
                          Object.keys(transportMap[`${vehicleData?.category}_${vehicleData?.vehicleType}_${vehicleData?.transportType}`]).map((description, index) => (
                            <MenuItem key={index} value={description}>
                              {transportMap[`${vehicleData?.category}_${vehicleData?.vehicleType}_${vehicleData?.transportType}`][description]}
                            </MenuItem>
                          )) : (
                            <MenuItem disabled>No options available</MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                )
              }

              <Grid item xs={4}>
                <label htmlFor="col4">Make  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={vehicleData?.vehicleMake?.slice(0,20)}
                  id="col4"
                  name="vehicleMake"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col5">Model  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={vehicleData?.vehicleModel?.slice(0,20)}
                  id="col5"
                  name="vehicleModel"
                  onChange={handleInputChange}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col6">Year  <span className="_MANDATORY">*</span></label>
                  <Select
                    placeholder="Year"
                    // label="Year"
                    fullWidth
                    id="col6"
                    name="vehicleYear"
                    value={vehicleData.vehicleYear}
                    onChange={handleInputChange}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col6">Month  <span className="_MANDATORY">*</span></label>
                  <Select
                    placeholder="Months"
                    // label="Year"
                    fullWidth
                    id="col6"
                    name="vehicleMonth"
                    value={vehicleData.vehicleMonth}
                    onChange={handleInputChange}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={index} value={index+1}>{month}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item xs={4}>
                <label>Purchase date <span className="_MANDATORY">*</span></label>
                <DatePicker
                  style={{ width: "100%" }}
                  selected={purchaseDate}
                  name="vehicleYear"
                  value={purchaseDate || vehicleData.vehicleYear}
                  onChange={(date) => handlepurchasedateChange(date)}
                  dateFormat="MM/yyyy" // Set the date format to display only month and year
                  showMonthYearPicker // Show only the month and year picker
                  maxDate={new Date()}
                  customInput={

                    <TextField
                      variant="outlined"
                      fullWidth
                      id="col7"
                      value={purchaseDate}
                      name="vehicleYear"
                      placeholder="MM-YYYY"
                      inputProps={{ readOnly: true }} 
                    />
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <label>LAST Service  <span className="_MANDATORY">*</span></label>
                <DatePicker
                  style={{ width: "100%" }}
                  selected={selectedDate}
                  name="lastService"
                  value={selectedDate || vehicleData.lastService}
                  onChange={(date) => handleDateChange(date)}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()} // Disable future dates
                  customInput={
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="col7"
                      value={selectedDate}
                      name="lastService"
                      placeholder="DD-MM-YYYY"
                      inputProps={{ readOnly: true }} 
                    />
                  }
                />
              </Grid>
              <Grid item xs={4} className="">
                <FormControl fullWidth variant="outlined">
                  <label htmlFor="col8">Fuel Type  <span className="_MANDATORY">*</span></label>
                  <Select
                    // label="Fuel Type"
                    fullWidth
                    id="col8"
                    name="fuelType"
                    value={vehicleData.fuelType}
                    onChange={handleInputChange}
                  >

                    {fuelData.map((fuel, i) => (
                      <MenuItem key={i} value={fuel.fuelID} className="">
                        <span>
                          {fuel.fuelType}&nbsp; <span style={{ color: localStorage.getItem('--theme') || '#ccc', fontSize: '10px' }}> in {fuel.Measurement}</span>
                        </span>
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} sx={{ width: "100%" }}>
                <div style={{ margin: '20px' }}>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ m: 1 }}
                        defaultChecked={DocsUpload}
                        onChange={(event) => toggleVehCondition(event)}
                      />
                    }
                    label={`${'Upload Documents'}  `}
                  />
                </div>

              </Grid>

              {
                !vehicleData.vehicleCondition &&

                <Grid item xs={4}>
                  <div>
                    <label>PUC Date  <span className="_MANDATORY">*</span></label>
                    <DatePicker
                      style={{ width: "100%" }}
                      selected={selectedDate1}
                      value={selectedDate1 || vehicleData.vehiclePOC}
                      onChange={(date) => handleDateChange1(date)}
                      name="vehiclePOC"
                      dateFormat="dd-MM-yyyy"
                      maxDate={new Date()} // Disable future dates
                      customInput={
                        <TextField
                          // style={{width:'100%'}}
                          variant="outlined"
                          // fullWidth
                          value={vehicleData.vehiclePOC}
                          id="col7"
                          placeholder="DD-MM-YYYY"
                        />
                      }
                    />
                  </div>
                </Grid>

              }
              {
                DocsUpload &&
                <>
                  <Grid item xs={4}>
                    <label>PUC Docs <span className="_MANDATORY">*</span></label>
                    {
                      vehicleData?.pucFile ?

                        vehicleData.pucFile?.includes('.pdf') ?
                          <p className='docs-viewer-item'>{` ${vehicleData.pucFile?.split("/").pop()}`}  <a target='_blank' href={vehicleData.pucFile}><DownloadIcon /></a></p>

                          :
                          <img src={vehicleData?.pucFile} style={{ height: '60px', maxWidth: '220px' }} />
                        :
                        <TextField
                          type="file"
                          name="pucFile"
                          variant="outlined"

                          onChange={(e) =>
                            handleDocsUpload(e.target.files[0], 'pucFile')
                          }

                        />
                    }

                  </Grid>
                  <Grid item xs={4}>
                    <label>Pollution Docs <span className="_MANDATORY">*</span></label>
                    {
                      vehicleData?.polutionFile ?
                        vehicleData.polutionFile?.includes('.pdf') ?
                          <p className='docs-viewer-item'>{` ${vehicleData.polutionFile?.split("/").pop()}`}  <a target='_blank' href={vehicleData.polutionFile}><DownloadIcon /></a></p>

                          :
                          <img src={vehicleData?.polutionFile} style={{ height: '60px', maxWidth: '220px' }} />
                        :
                        <TextField
                          type="file"
                          name="polutionFile"
                          variant="outlined"

                          onChange={(e) =>
                            handleDocsUpload(e.target.files[0], 'polutionFile')
                          }

                        />
                    }
                  </Grid>
                </>
              }



              {
                DocsUpload &&

                <>
                  <Grid item xs={4}>
                    <label>RC Docs <span className="_MANDATORY">*</span></label>
                    {
                      vehicleData?.rcFile ?

                        vehicleData.rcFile?.includes('.pdf') ?
                          <p className='docs-viewer-item'>{` ${vehicleData.rcFile?.split("/").pop()}`}  <a target='_blank' href={vehicleData.rcFile}><DownloadIcon /></a></p>

                          :
                          <img src={vehicleData?.rcFile} style={{ height: '60px', maxWidth: '220px' }} />
                        :
                        <TextField
                          type="file"
                          name="rcFile"
                          variant="outlined"

                          onChange={(e) =>
                            handleDocsUpload(e.target.files[0], 'rcFile')
                          }

                        />
                    }

                  </Grid>
                  <Grid item xs={4}>
                    <label>Insurance Docs <span className="_MANDATORY">*</span></label>
                    {
                      vehicleData?.insuranceFile ?
                        vehicleData.insuranceFile?.includes('.pdf') ?
                          <p className='docs-viewer-item'>{` ${vehicleData.insuranceFile?.split("/").pop()}`}  <a target='_blank' href={vehicleData.insuranceFile}><DownloadIcon /></a></p>

                          :
                          <img src={vehicleData?.insuranceFile} style={{ height: '60px', maxWidth: '220px' }} />
                        :
                        <TextField
                          type="file"
                          name="insuranceFile"
                          variant="outlined"

                          onChange={(e) =>
                            handleDocsUpload(e.target.files[0], 'insuranceFile')
                          }

                        />
                    }

                  </Grid>
                </>
              }



              <Grid item xs={4} >
                <label htmlFor="col8">Delclared Effeciency in per  {vehicleData.distancemeasure}  <span className="_MANDATORY">*</span></label>
                <div className="df jsb">
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="number"
                    // placeholder="1210"
                    value={vehicleData?.vehicleEffeciency?.toString()?.slice(0,3)}
                    id="col9"
                    name="vehicleEffeciency"
                    onChange={handleInputChange}
                  />
                  <Select
                    // label="Type"
                    style={{ width: '100px', height: '42px' }}
                    fullWidth
                    id="col3"
                    name="distancemeasure"
                    value={vehicleData.distancemeasure}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Km" >Km</MenuItem>
                    <MenuItem value="miles">Mile</MenuItem>

                  </Select>
                </div>
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col9">Load Capacity(ton)</label>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={vehicleData?.vehicleCapacity?.toString()?.slice(0,3)}
                  // placeholder="1021"
                  id="col9"
                  name="vehicleCapacity"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                className="submit_btn"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    width: "100%",
                    padding: "10px",
                    height: "45px",
                    borderRadius: "5px",
                    textAlign: "center",
                    marginTop: "22px",
                    fontSize: "18px",
                    fontFamily: "Lora",
                    fontWeight: "600",
                    textTransform: "none",
                  }}
                  onClick={handleSubmit}
                >
                  {action != "" ? action.toUpperCase() : `Submit`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </div>
  );
};

export default VehicleForm;
