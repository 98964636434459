import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartWithPointStyling = ({ data, value, Typevalue, streamvalue }) => {
  const chartContainer = useRef(null);
  const monthNames = ["Jan", "Feb", "Mar", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

  useEffect(() => {
    console.log("timedata",data)
    if (data.length === 0) return;
    // debugger;
    const ctx = chartContainer.current.getContext('2d');

    const filteredData = streamvalue === 'All' ? data : data.filter(entry => entry.emissions[0]?.direction === streamvalue);

    // Separate arrays for upstream and downstream data
    const upstreamData = filteredData.filter(entry => entry.emissions[0]?.direction === 'Upstream');
    const downstreamData = filteredData.filter(entry => entry.emissions[0]?.direction === 'Downstream');
    // upstreamData.sort((a, b) => a._id.month - b._id.month);
    // downstreamData.sort((a, b) => a._id.month - b._id.month);

    const upstreamMonths = upstreamData.map(entry => `${value === "time" ? monthNames[entry._id.month - 1] : entry?._id}`);
    const downstreamMonths = downstreamData.map(entry => `${value === "time" ? monthNames[entry._id.month - 1] : entry?._id}`);

    const upstreamEmissions = upstreamData.map(entry => entry.emissions[0]?.totalEmission);
    const downstreamEmissions = downstreamData.map(entry => entry.emissions[0]?.totalEmission);


    const allMonths = Array.from({ length: 12 }, (_, i) => monthNames[i]);

  // // Populate arrays with corresponding emissions data or null values
  // const upstreamEmissions = allMonths.map(month => {
  //   const dataForMonth = upstreamData.find(entry => entry?._id.month === month);
  //   return dataForMonth ? dataForMonth.emissions[0]?.totalEmission : null;
  // });

  // const downstreamEmissions = allMonths.map(month => {
  //   const dataForMonth = downstreamData.find(entry => entry?._id.month === month);
  //   return dataForMonth ? dataForMonth.emissions[0]?.totalEmission : null;
  // });

    console.log({allMonths,upstreamEmissions,downstreamEmissions})
    const myChart = new Chart(ctx, {
      type: 'line',
      height: 200,
      data: {
        labels: value === "time"?  allMonths : [...upstreamMonths,...downstreamMonths], 
        datasets: [
          {
            label: 'Upstream Emission',
            data: upstreamEmissions,
            fill: false,

            borderColor: '#eb7e0e', // Downstream color
            backgroundColor: '#eb7e0e', // Semi-transparent red
            pointRadius: 5,
            pointHoverRadius: 10,
            pointStyle: 'circle',
          },
          {
            label: 'Downstream Emission',
            data: downstreamEmissions,
            fill: false,
            // Semi-transparent blue
            borderColor: '#488257', // Upstream color
            backgroundColor: '#488257',
            pointRadius: 5,
            pointHoverRadius: 10,
            pointStyle: 'circle',
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            fontSize: 8, 
            ticks: {
              display: true, 
              callback: function (value) {
                  return value + " ";
              }
          },
          },
         
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [data, streamvalue]);

  return (
    <div>
      <canvas ref={chartContainer}></canvas>
    </div>
  );
};

export default ChartWithPointStyling;