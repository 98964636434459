export const Category =  [
      {
        category: "fresh soybeans, canola, flaxseeds, and other oilseeds",
        kg_co2_eq_per_usd_1: 0.559,
        kg_co2_eq_per_usd_2: 0.416
      },
      {
        category: "fresh wheat, corn, rice, and other grains",
        kg_co2_eq_per_usd_1: 1.98,
        kg_co2_eq_per_usd_2: 1.47
      },
      {
        category: "fresh vegetables, melons, and potatoes",
        kg_co2_eq_per_usd_1: 0.457,
        kg_co2_eq_per_usd_2: 0.34
      },
      {
        category: "fresh fruits and tree nuts",
        kg_co2_eq_per_usd_1: 0.286,
        kg_co2_eq_per_usd_2: 0.213
      },
      {
        category: "greenhouse crops, mushrooms, nurseries, and flowers",
        kg_co2_eq_per_usd_1: 0.716,
        kg_co2_eq_per_usd_2: 0.533
      },
      {
        category: "tobacco, cotton, sugarcane, peanuts, sugar beets, herbs and spices, and other crops",
        kg_co2_eq_per_usd_1: 1.08,
        kg_co2_eq_per_usd_2: 0.807
      },
      {
        category: "dairies",
        kg_co2_eq_per_usd_1: 2.53,
        kg_co2_eq_per_usd_2: 1.88
      },
      {
        category: "cattle ranches and feedlots",
        kg_co2_eq_per_usd_1: 2.91,
        kg_co2_eq_per_usd_2: 2.17
      },
      {
        category: "poultry farms",
        kg_co2_eq_per_usd_1: 0.308,
        kg_co2_eq_per_usd_2: 0.23
      },
      {
        category: "animal farms and aquaculture ponds (except cattle and poultry)",
        kg_co2_eq_per_usd_1: 1.1,
        kg_co2_eq_per_usd_2: 0.818
      },
      {
        category: "timber and raw forest products",
        kg_co2_eq_per_usd_1: 0.132,
        kg_co2_eq_per_usd_2: 0.0981
      },
      {
        category: "wild-caught fish and game",
        kg_co2_eq_per_usd_1: 0.194,
        kg_co2_eq_per_usd_2: 0.144
      },
      {
        category: "agriculture and forestry support",
        kg_co2_eq_per_usd_1: 0.186,
        kg_co2_eq_per_usd_2: 0.139
      },
      {
        category: "unrefined oil and gas",
        kg_co2_eq_per_usd_1: 0.443,
        kg_co2_eq_per_usd_2: 0.33
      },
      {
        category: "coal",
        kg_co2_eq_per_usd_1: 1.45,
        kg_co2_eq_per_usd_2: 1.08
      },
      {
        category: "copper, nickel, lead, and zinc",
        kg_co2_eq_per_usd_1: 0.744,
        kg_co2_eq_per_usd_2: 0.554
      },
      {
        category: "iron, gold, silver, and other metal ores",
        kg_co2_eq_per_usd_1: 0.437,
        kg_co2_eq_per_usd_2: 0.326
      },
      {
        category: "dimensional stone",
        kg_co2_eq_per_usd_1: 0.412,
        kg_co2_eq_per_usd_2: 0.307
      },
      {
        category: "sand, gravel, clay, phosphate, other nonmetallic minerals",
        kg_co2_eq_per_usd_1: 0.747,
        kg_co2_eq_per_usd_2: 0.556
      },
      {
        category: "well drilling",
        kg_co2_eq_per_usd_1: 0.0574,
        kg_co2_eq_per_usd_2: 0.0428
      },
      {
        category: "construction and demolition",
        kg_co2_eq_per_usd_1: 0.144,
        kg_co2_eq_per_usd_2: 0.107
      },
      {
        category: "heavy construction",
        kg_co2_eq_per_usd_1: 0.218,
        kg_co2_eq_per_usd_2: 0.162
      },
      {
        category: "construction support services",
        kg_co2_eq_per_usd_1: 0.167,
        kg_co2_eq_per_usd_2: 0.124
      },
      {
        category: "transit and ground passenger transportation",
        kg_co2_eq_per_usd_1: 0.98,
        kg_co2_eq_per_usd_2: 0.729
      },
      {
        category: "truck transportation",
        kg_co2_eq_per_usd_1: 1.47,
        kg_co2_eq_per_usd_2: 1.09
      },
      {
        category: "pipeline transportation",
        kg_co2_eq_per_usd_1: 0.56,
        kg_co2_eq_per_usd_2: 0.416
      },
      {
        category: "water transportation",
        kg_co2_eq_per_usd_1: 0.234,
        kg_co2_eq_per_usd_2: 0.174
      },
      {
        category: "air transportation",
        kg_co2_eq_per_usd_1: 1.18,
        kg_co2_eq_per_usd_2: 0.877
      },
      {
        category: "rail transportation",
        kg_co2_eq_per_usd_1: 0.633,
        kg_co2_eq_per_usd_2: 0.471
      },
      {
        category: "other transportation and support activities",
        kg_co2_eq_per_usd_1: 0.975,
        kg_co2_eq_per_usd_2: 0.725
      },
      {
        category: "postal services and couriers",
        kg_co2_eq_per_usd_1: 0.262,
        kg_co2_eq_per_usd_2: 0.195
      },
      {
        category: "warehousing and storage",
        kg_co2_eq_per_usd_1: 0.1,
        kg_co2_eq_per_usd_2: 0.0745
      },
      {
        category: "scrap materials",
        kg_co2_eq_per_usd_1: 0.353,
        kg_co2_eq_per_usd_2: 0.263
      },
      {
        category: "other waste management services",
        kg_co2_eq_per_usd_1: 0.285,
        kg_co2_eq_per_usd_2: 0.212
      },
      {
        category: "hazardous waste treatment and disposal",
        kg_co2_eq_per_usd_1: 0.296,
        kg_co2_eq_per_usd_2: 0.22
      },
      {
        category: "nonhazardous waste treatment and disposal",
        kg_co2_eq_per_usd_1: 0.207,
        kg_co2_eq_per_usd_2: 0.154
      },
      {
        category: "remediation and other waste management services",
        kg_co2_eq_per_usd_1: 0.292,
        kg_co2_eq_per_usd_2: 0.217
      },
      {
        category: "elementary and secondary schools",
        kg_co2_eq_per_usd_1: 0.1,
        kg_co2_eq_per_usd_2: 0.0745
      },
      {
        category: "colleges, universities, and professional schools",
        kg_co2_eq_per_usd_1: 0.138,
        kg_co2_eq_per_usd_2: 0.102
      },
      {
        category: "business and secretarial schools",
        kg_co2_eq_per_usd_1: 0.105,
        kg_co2_eq_per_usd_2: 0.0782
      },
      {
        category: "computer training",
        kg_co2_eq_per_usd_1: 0.0718,
        kg_co2_eq_per_usd_2: 0.0534
      },
      {
        category: "management training",
        kg_co2_eq_per_usd_1: 0.0934,
        kg_co2_eq_per_usd_2: 0.0694
      },
      {
        category: "professional development training",
        kg_co2_eq_per_usd_1: 0.0829,
        kg_co2_eq_per_usd_2: 0.0617
      },
      {
        category: "vocational rehabilitation services",
        kg_co2_eq_per_usd_1: 0.197,
        kg_co2_eq_per_usd_2: 0.146
      },
      {
        category: "child day care services",
        kg_co2_eq_per_usd_1: 0.0813,
        kg_co2_eq_per_usd_2: 0.0605
      },
      {
        category: "other educational services",
        kg_co2_eq_per_usd_1: 0.129,
        kg_co2_eq_per_usd_2: 0.0961
      },
      {
        category: "hospitals",
        kg_co2_eq_per_usd_1: 0.123,
        kg_co2_eq_per_usd_2: 0.0917
      },
      {
        category: "offices of physicians",
        kg_co2_eq_per_usd_1: 0.143,
        kg_co2_eq_per_usd_2: 0.106
      },
      {
        category: "offices of dentists",
        kg_co2_eq_per_usd_1: 0.129,
        kg_co2_eq_per_usd_2: 0.0961
      },
      {
        category: "offices of other health practitioners",
        kg_co2_eq_per_usd_1: 0.156,
        kg_co2_eq_per_usd_2: 0.116
      },
      {
        category: "outpatient care centers",
        kg_co2_eq_per_usd_1: 0.129,
        kg_co2_eq_per_usd_2: 0.0961
      },
      {
        category: "medical and diagnostic laboratories",
        kg_co2_eq_per_usd_1: 0.183,
        kg_co2_eq_per_usd_2: 0.136
      },
      {
        category: "home health care services",
        kg_co2_eq_per_usd_1: 0.0497,
        kg_co2_eq_per_usd_2: 0.037
      },
      {
        category: "other ambulatory health care services",
        kg_co2_eq_per_usd_1: 0.153,
        kg_co2_eq_per_usd_2: 0.114
      },
      {
        category: "nursing care facilities",
        kg_co2_eq_per_usd_1: 0.076,
        kg_co2_eq_per_usd_2: 0.0566
      },
      {
        category: "residential mental health facilities",
        kg_co2_eq_per_usd_1: 0.116,
        kg_co2_eq_per_usd_2: 0.0865
      },
      {
        category: "community care facilities for the elderly",
        kg_co2_eq_per_usd_1: 0.126,
        kg_co2_eq_per_usd_2: 0.094
      },
      {
        category: "emergency and other relief services",
        kg_co2_eq_per_usd_1: 0.076,
        kg_co2_eq_per_usd_2: 0.0566
      },
      {
        category: "other social assistance",
        kg_co2_eq_per_usd_1: 0.11,
        kg_co2_eq_per_usd_2: 0.082
      },
      {
        category: "vocational rehabilitation services",
        kg_co2_eq_per_usd_1: 0.106,
        kg_co2_eq_per_usd_2: 0.079
      },
      {
        category: "child day care services",
        kg_co2_eq_per_usd_1: 0.078,
        kg_co2_eq_per_usd_2: 0.0581
      },
      {
        category: "other residential care facilities",
        kg_co2_eq_per_usd_1: 0.115,
        kg_co2_eq_per_usd_2: 0.0857
      },
      {
        category: "vocational rehabilitation services",
        kg_co2_eq_per_usd_1: 0.106,
        kg_co2_eq_per_usd_2: 0.079
      },
      {
        category: "child day care services",
        kg_co2_eq_per_usd_1: 0.078,
        kg_co2_eq_per_usd_2: 0.0581
      },
      {
        category: "veterinary services",
        kg_co2_eq_per_usd_1: 0.0728,
        kg_co2_eq_per_usd_2: 0.0542
      },
      {
        category: "other health care services",
        kg_co2_eq_per_usd_1: 0.101,
        kg_co2_eq_per_usd_2: 0.0752
      },
      {
        category: "legal services",
        kg_co2_eq_per_usd_1: 0.139,
        kg_co2_eq_per_usd_2: 0.103
      },
      {
        category: "accounting and bookkeeping services",
        kg_co2_eq_per_usd_1: 0.158,
        kg_co2_eq_per_usd_2: 0.118
      },
      {
        category: "architectural, engineering, and related services",
        kg_co2_eq_per_usd_1: 0.125,
        kg_co2_eq_per_usd_2: 0.0932
      },
      {
        category: "specialized design services",
        kg_co2_eq_per_usd_1: 0.1,
        kg_co2_eq_per_usd_2: 0.0745
      },
      {
        category: "computer systems design and related services",
        kg_co2_eq_per_usd_1: 0.0868,
        kg_co2_eq_per_usd_2: 0.0646
      },
      {
        category: "management, scientific, and technical consulting services",
        kg_co2_eq_per_usd_1: 0.131,
        kg_co2_eq_per_usd_2: 0.0976
      },
      {
        category: "scientific research and development services",
        kg_co2_eq_per_usd_1: 0.0694,
        kg_co2_eq_per_usd_2: 0.0517
      },
      {
        category: "advertising and related services",
        kg_co2_eq_per_usd_1: 0.183,
        kg_co2_eq_per_usd_2: 0.136
      },
      {
        category: "public relations agencies",
        kg_co2_eq_per_usd_1: 0.158,
        kg_co2_eq_per_usd_2: 0.118
      },
      {
        category: "other professional, scientific, and technical services",
        kg_co2_eq_per_usd_1: 0.123,
        kg_co2_eq_per_usd_2: 0.0917
      },
      {
        category: "management of companies and enterprises",
        kg_co2_eq_per_usd_1: 0.0735,
        kg_co2_eq_per_usd_2: 0.0547
      },
      {
        category: "employment services",
        kg_co2_eq_per_usd_1: 0.161,
        kg_co2_eq_per_usd_2: 0.12
      },
      {
        category: "business support services",
        kg_co2_eq_per_usd_1: 0.141,
        kg_co2_eq_per_usd_2: 0.105
      },
      {
        category: "travel arrangement and reservation services",
        kg_co2_eq_per_usd_1: 0.158,
        kg_co2_eq_per_usd_2: 0.118
      },
      {
        category: "security systems services",
        kg_co2_eq_per_usd_1: 0.0793,
        kg_co2_eq_per_usd_2: 0.0591
      },
      {
        category: "facility support services",
        kg_co2_eq_per_usd_1: 0.096,
        kg_co2_eq_per_usd_2: 0.0715
      },
      {
        category: "other support services",
        kg_co2_eq_per_usd_1: 0.132,
        kg_co2_eq_per_usd_2: 0.0981
      },
      {
        category: "waste collection",
        kg_co2_eq_per_usd_1: 0.14,
        kg_co2_eq_per_usd_2: 0.104
      },
      {
        category: "waste treatment and disposal",
        kg_co2_eq_per_usd_1: 0.19,
        kg_co2_eq_per_usd_2: 0.141
      },
      {
        category: "waste management and remediation services",
        kg_co2_eq_per_usd_1: 0.17,
        kg_co2_eq_per_usd_2: 0.126
      },
      {
        category: "local government excluding education and hospitals",
        kg_co2_eq_per_usd_1: 0.15,
        kg_co2_eq_per_usd_2: 0.112
      },
      {
        category: "state government excluding education and hospitals",
        kg_co2_eq_per_usd_1: 0.163,
        kg_co2_eq_per_usd_2: 0.121
      },
      {
        category: "federal government excluding education and hospitals",
        kg_co2_eq_per_usd_1: 0.17,
        kg_co2_eq_per_usd_2: 0.126
      },
      {
        category: "national defense and space administration",
        kg_co2_eq_per_usd_1: 0.163,
        kg_co2_eq_per_usd_2: 0.121
      },
      {
        category: "administration of environmental quality and housing programs",
        kg_co2_eq_per_usd_1: 0.195,
        kg_co2_eq_per_usd_2: 0.145
      },
      {
        category: "administration of economic programs and space research",
        kg_co2_eq_per_usd_1: 0.157,
        kg_co2_eq_per_usd_2: 0.117
      },
      {
        category: "other general government and support",
        kg_co2_eq_per_usd_1: 0.168,
        kg_co2_eq_per_usd_2: 0.125
      },
      {
        category: "justice, public order, and safety activities",
        kg_co2_eq_per_usd_1: 0.175,
        kg_co2_eq_per_usd_2: 0.13
      },
      {
        category: "administration of human resource programs",
        kg_co2_eq_per_usd_1: 0.155,
        kg_co2_eq_per_usd_2: 0.115
      },
      {
        category: "administration of veterans affairs",
        kg_co2_eq_per_usd_1: 0.122,
        kg_co2_eq_per_usd_2: 0.091
      },
      {
        category: "space research and technology",
        kg_co2_eq_per_usd_1: 0.173,
        kg_co2_eq_per_usd_2: 0.129
      },
      {
        category: "international affairs",
        kg_co2_eq_per_usd_1: 0.161,
        kg_co2_eq_per_usd_2: 0.12
      },
      {
        category: "public finance activities",
        kg_co2_eq_per_usd_1: 0.149,
        kg_co2_eq_per_usd_2: 0.111
      }
]