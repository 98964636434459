import React, { useEffect, useState } from "react";
// import awsConfig from "./aws-exports";

import {
  Typography,
  Paper,
  // IconButton,
  TextField,
  Button,
  Grid,
  Avatar,
  Container,
} from "@mui/material";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
// import DescriptionIcon from "@mui/icons-material/Description";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
// import AvatrImg from "../../utils/Random.png";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import vectorimage from "../../utils/Vector.png";
import "./SupportPage.css";
import axios from "axios";
import { showError, showSuccess } from "../../toast";
import axiosInstance from "../../Components/utils/axios"
import TableComponent from "../../Components/CustomTable/CustomTable";
import CustomButtonGrp from "./CustomButtonGrp";
// import Ticket from "../../../../app-backend/modal/ticketModel";
import CircularProgress from '@mui/material/CircularProgress';


const SupportPage = () => {
  // const history = useHistory();
  const [dragging, setDragging] = useState(false);
  const [gridColor, setGridColor] = useState("#ffffff");
  const [activeButtonClicked, setActiveButtonClicked] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [closedButtonClicked, setClosedButtonClicked] = useState(false);
  const [ticket, setTicket] = useState([]);
  const [mode, setmode] = useState(false)
  const [SelectedRowData, setSelectedRowData] = useState([])
  const [expandedStates, setExpandedStates] = useState(
    ticket ? Array(ticket.length).fill(false) : []
  );

  const [exclude,setexclude] = useState(['_id', '__v', 'upload', 'createdAt', 'updatedAt', 'expanded','user']);

  const [refreshData, setRefreshData] = useState(false);
  const [msg, setMsg] = useState("");
  const [inputData, setInputdata] = useState({
    title: "",
    category: "",
    description: "",
    upload: "",
    status: false,
  });

  const [alltickets,setalltickets] = useState('All')
  const [action, setaction] = useState("")
  const generateRandom4DigitNumber = () => {
    return `ST${Math.floor(Math.random() * 900) + 101}`;
  };

  const [supportid, setsupportID] = useState(generateRandom4DigitNumber());
  // const { title, category, description, upload } = inputData;

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = e.dataTransfer.files;
    // Handle the dropped files, you can perform file validation, upload, etc.
    console.log("Dragged file here");
    console.log(files);
    setImage(files[0]);
  };

  const goBack = () => {
    // Add logic to navigate back to the previous page
    window.history.back();
  };

  // For the first box
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = (index) => {
    const updatedTicket = [...ticket];
    updatedTicket[index].expanded = !updatedTicket[index].expanded;
    // setTicket(() => [...updatedTicket].reverse());
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setInputdata((prev) => ({
      ...prev,
      [name]: value,
    }));
    console.log("Form Submitted", { name: value });
  };

  const handleExpandClick2 = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);

    const isActive = newExpandedStates.some((state) => !state);

    if (isActive) {
      setActiveButtonClicked(true);
      setClosedButtonClicked(false);
    } else {
      setActiveButtonClicked(false);
      setClosedButtonClicked(true);
    }
  };

  const handleActiveButtonClick = () => {
    const newExpandedStates = expandedStates.map(() => false);
    setExpandedStates(newExpandedStates);
    setActiveButtonClicked(true);
    setClosedButtonClicked(false);
  };
  const handleClosedButtonClick = () => {
    const newExpandedStates = expandedStates.map(() => true);
    setExpandedStates(newExpandedStates);
    setActiveButtonClicked(false);
    setClosedButtonClicked(true);
  };
  

  const [Isfiltered, setIsfiltered] = useState(false);

  const containerStyle = {
    display: "flex",
  };

  const PageStyle = {
    width: "100%",
    margin: " 10px",
  };

  const tickets = async (by) =>
    await axiosInstance.get(`${by === "All"?'/getTickets/All':'/getTickets/Driver'}`);
   

  const fetchTickets = () => {
    tickets(alltickets)
      .then((res) => {
        setTicket(res.data.tickets)
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
console.log(alltickets)
if(alltickets === "All"){
  setexclude(['_id', '__v', 'upload', 'createdAt', 'updatedAt', 'expanded','user',])

}
else{
  setexclude(['_id', '__v', 'upload', 'updatedAt', 'expanded','user','title','description'])

}
    fetchTickets();

  }, [msg,alltickets]);


  const [image, setImage] = useState("");

  // console.log("mes", msg);

  const submitFormToAPI = async (formData, supportid, action) => {
    let apiUrl;

    if (action.toLowerCase() === "update") {
      apiUrl = `/updateTicket/${supportid}`;
      return await axiosInstance.put(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else if (action.toLowerCase() === "delete") {
      apiUrl = `/deleteTicket/${supportid}`;
      return await axiosInstance.delete(apiUrl, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      apiUrl = "/submitTicket";
      return await axiosInstance.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(supportid);
      console.log(inputData.description);
      console.log(inputData.category);
      if (!inputData.description || !inputData.category) {
        showError("Please fill the all fields");
        return;
      }
      console.log(inputData.description);
      console.log(inputData.category);

      const USER = localStorage.getItem("user_id");
      const Company = localStorage.getItem("COMPANY");
      // alert(USER);

      const formData = new FormData();
      formData.append("title", supportid);
      formData.append("user", USER);
      formData.append("Company", Company);
      formData.append("category", inputData.category);
      formData.append("description", inputData.description);
      formData.append("status", inputData.status);
      formData.append("upload", image);

      // console.log("formData",formData)
      const response = await submitFormToAPI(formData, supportid, action);
      showSuccess(`${action == "" ? 'Saved' : action} Successfully!`);
      setTicket((prevTickets) => [
        ...prevTickets,
        { ...response.data, expanded: true },
      ]);

      console.log("Before setInputdata", inputData);
      // setInputdata((prev) => ({
      //   ...prev,
      //   title: "",
      //   category: "",
      //   description: "",
      //   upload: "",
      // }));

      // setImage((prevImage) => null);

      console.log("After setInputdata", inputData);

      setMsg("Data updated");
      setmode(false)
      console.log(response.data);
    } catch (error) {
      showError("Error submitting form. Please try again.");
      console.error("Error submitting form:", error);
    }
  };


  const UpdateSupportstatus = async (item) => {
    console.log("support updated  item ", item)
    const formData = new FormData();
    formData.append("title", item.title);
    formData.append("category", item.category);
    formData.append("description", item.description);
    formData.append("status", !item.status);
    formData.append("upload", item.upload);

    // console.log("formData",formData)
    const response = await submitFormToAPI(formData, item._id, 'update');
    showSuccess(`Status Updated !`);
    fetchTickets()
    // setIsfiltered(true)
  }

  const handleFileUpload = async (file) => {
    console.log(file)
    try {
      const formData = new FormData();
      formData.append("DriverIDfile", file);  // we are using only url as a string 
      console.log("formData", formData)
      const response = await axiosInstance.post(
        "/uploadDriverID",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      console.log(response?.data)
      return response?.data?.imageurl?.Location


    } catch (error) {
      console.log(error)
    }
  };

  const handleFileChange = async (file) => {
    const a = await handleFileUpload(file)
    console.log(a);
    setImage(a);
  };

  const handleBrowseClick = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };


  const handleClick = (button) => {
    setmode(true)
    console.log("SelectedRowData--->", SelectedRowData)
    setaction(button.toUpperCase())
    setInputdata({
      ...SelectedRowData
    })
    setsupportID(SelectedRowData.title)
  }

  const FilterTickets = (e) => {
    const filteredTicket = ticket.filter((item) => item.category == e.target.value)
    if (e.target.value == "all") {
      setIsfiltered((prev) => !prev)
    }
    else if (filteredTicket.length > 0) {
      console.log(filteredTicket)
      setExpandedStates(filteredTicket ? Array(filteredTicket.length).fill(true) : []);
      setTicket(filteredTicket)
      // setIsfiltered(false)
    }

    else {
      showError(`No Tickets Found of ${e?.target?.value}`)
      // setIsfiltered(true)
    }



  }



  return (
    <div>
      <div style={containerStyle}>
        <div style={{ height: "100vh" }}>
          <Sidebar />
        </div>
        <div style={PageStyle}>
          <Header />
          <div className="table-heading">
            Support Tickets
          </div>
          <div
            className="support_page"

          >
            <div className="df jsb"       >
              <Grid style={{ width: '100%' }}
              >
                <div >
                  {/* <Grid sx={{ display: "flex" ,width:'700px'}}>
                    <Grid>
                      <Button
                        sx={{
                          fontFamily: "Lora",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "28px",
                          textTransform: "none",
                          color: activeButtonClicked ? "#4F8BFF" : "black",
                        }}
                        onClick={handleActiveButtonClick}
                      >
                        Active
                      </Button>
                      <Button
                        sx={{
                          fontFamily: "Lora",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "28px",
                          textTransform: "none",
                          color: closedButtonClicked ? "#4F8BFF" : "black",
                        }}
                        onClick={handleClosedButtonClick}
                      >
                        Closed
                      </Button>
                    </Grid>
                  </Grid> */}
                  {/* <div className="" style={{ marginRight: '100px',float:'right' }}>
                    <select
                     
                      name="category"
                      onChange={(e)=>FilterTickets(e)}
                      style={{ padding: '15px', marginTop: '16px', border: 'none' }}
                    >
                      <option value="all">All</option>
                      <option value="Dashboard">Dashboard</option>
                      <option value="Trips">Trips</option>
                      <option value="Vehicle">Vehicle</option>
                      <option value="Driver">Driver</option>
                      <option value="Fuel">Fuel</option>
                      <option value="Roles">Roles</option>
                      <option value="Documentation">Documentation</option>
                    </select>
                  </div> */}
                </div>
                <div className="hidden_scrollbar mt-3" style={{ height: "580px", width: '100%' }}>
                  {/* <Grid sx={{ display: "flex", flexDirection: "column" }}>

                    {ticket?.map((item, index) => (
                      <Paper
                        key={index}
                        elevation={3}
                        sx={{
                          height: expandedStates[index] ? `130px` : '',
                          margin: "10px",
                          padding: expandedStates[index] ? `1px` : `16px`,
                          backgroundColor: expandedStates[index]
                            ? "inherit"
                            : "rgba(223, 237, 209, 0.2)",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <Grid style={{ padding: '1px' }}>
                          <div className="titleticket" style={{ float: 'right' }}>

                            <div onClick={() => handleExpandClick2(index)}>
                              {expandedStates[index] ? (
                                <ExpandCircleDownIcon />
                              ) : (
                                <ExpandLessIcon
                                  sx={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius: "55%",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              )}
                            </div>
                          </div>

                          <Grid className="categorysection">
                            {expandedStates[index] ? (
                              <>
                                <div className="df jsb">
                                  <div
                                    style={{
                                      width: "220px",
                                      height: "130px",
                                    }}
                                  >
                                    <img
                                      src={item.upload}
                                      alt="Uploaded Image"
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "", 
                                      }}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "8px",
                                    }}
                                  >
                                    <b>{item.title}</b>
                                    <div
                                      style={{
                                        fontWeight: "500",
                                        color: "#333",
                                      }}
                                      className="categoryheading"
                                    >
                                      {item.category}
                                    </div>
                                    {item.description}
                                  </div>
                                </div>
                                <br />
                              </>
                            ) : (
                        
                              <>
                                <b>{item.title}</b>
                                <div
                                  style={{ fontWeight: "500", color: "#333" }}
                                  className="categoryheading"
                                >
                                  {item.category}
                                </div>
                                {item.description}
                                <br />
                                <span style={{ cursor: "pointer" }}>
               
                                  <div>
                                    <img
                                      src={item.upload}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        padding: "8px",
                                        borderRadius: "8px",
                                      }}
                                    />
                                    <div className="df jsb" style={{ float: 'right', width: '200px' }}>
                                      <svg onClick={() => handleClick(item, 'update')}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="23"
                                        viewBox="0 0 22 23"
                                        fill="none"
                                      >
                                        <path
                                          d="M19.5353 6.80503L15.6948 2.96534C15.5671 2.83763 15.4155 2.73632 15.2487 2.6672C15.0818 2.59808 14.903 2.5625 14.7224 2.5625C14.5418 2.5625 14.363 2.59808 14.1961 2.6672C14.0293 2.73632 13.8777 2.83763 13.75 2.96534L3.15305 13.5623C3.02482 13.6895 2.92315 13.841 2.85396 14.0078C2.78476 14.1747 2.74943 14.3536 2.75001 14.5342V18.3748C2.75001 18.7395 2.89487 19.0892 3.15274 19.3471C3.4106 19.6049 3.76033 19.7498 4.12501 19.7498H18.5625C18.7448 19.7498 18.9197 19.6774 19.0486 19.5484C19.1776 19.4195 19.25 19.2446 19.25 19.0623C19.25 18.88 19.1776 18.7051 19.0486 18.5762C18.9197 18.4472 18.7448 18.3748 18.5625 18.3748H9.91032L19.5353 8.74979C19.663 8.62211 19.7643 8.47051 19.8335 8.30367C19.9026 8.13683 19.9382 7.958 19.9382 7.77741C19.9382 7.59682 19.9026 7.41799 19.8335 7.25115C19.7643 7.0843 19.663 6.93271 19.5353 6.80503ZM7.96555 18.3748H4.12501V14.5342L11.6875 6.97175L15.5281 10.8123L7.96555 18.3748ZM16.5 9.84034L12.6603 5.99979L14.7228 3.93729L18.5625 7.77784L16.5 9.84034Z"
                                          fill="#4B465C"
                                        />
                                      </svg>
                                      <button style={{ background: 'transparent', border: 'none' }} onClick={() => handleClick(item, 'delete')}>delete</button>
                                    </div>
                                  </div>
                                </span>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    ))}
                  </Grid> */}

                  <p style={{ position: 'absolute', right: '20px' }} onClick={() => setmode(true)}>{mode === false &&
                    <button
                      className="themebg"
                      style={{
                        fontSize:'12px',
                        padding: "6px 12px",
                        color: "#fff",
                      textTransform:'uppercase',
                      letterSpacing: "1px",
                        borderRadius: "5px",
                        border: "none",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.2)",
                      }}
                    >
                    + Add Tickets
                    </button>
                  }</p>
             
                    <div className="supportBTN_GRP"><CustomButtonGrp setmodule={setalltickets} /></div>  
                  {
                    ticket.length > 0 ?
                    <TableComponent pagename="Tickets"  page="SupportPage" data={ticket} exclude={exclude} col_class="overflow"
                    UpdateSupportstatus={UpdateSupportstatus} edit={() => handleClick('update')} deletelink={() => handleClick('delete')} setSelectedRowData={setSelectedRowData} onlyDocs={alltickets === "All" ? false : true} />
                  : <div ><CircularProgress /></div>
                  }

                </div>
              </Grid>
              {
                mode &&
                <Grid>
                  <Grid
                    style={{
                      padding: 20,
                      marginTop: 10,
                      width: "450px",
                      height: "552px",
                    }}
                  >
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className="Form-content  p-4" style={{background:'#fff',marginTop:'30px'}}>
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: '',
                                }}
                              >
                                <div>
                                  <Avatar>
                                    <img
                                      style={{ height: "100%", width: "100%" }}
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/avatarimg.png"
                                      }
                                    />
                                  </Avatar>
                                </div>
                                <div className="df jsb " style={{ width: '325px' }}>
                                  <div
                                    style={{ marginTop: "2%", marginLeft: "2%" }}
                                  >
                                    <b>Raise a Ticket</b>
                                  </div>
                                  <p style={{transform:'scale(1.3)',marginLeft:'20px',cursor:'pointer'}} onClick={() => setmode(false)}>&times;</p>
                                </div>
                              </Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  //
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",

                                    // border: "1px solid black",
                                  }}
                                >
                                  <div>
                                    <label>Ticket Id</label>
                                  </div>
                                  <div>
                                    <TextField
                                      style={{ width: "90%" }}
                                      name="title"
                                      fullWidth
                                      margin="normal"
                                      variant="outlined"
                                      disabled
                                      value={supportid}
                                      onChange={handelChange}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div>
                                    <label>Category</label>
                                  </div>
                                  <div>


                                    <select
                                      value={inputData.category}
                                      name="category"
                                      onChange={handelChange}
                                      style={{ padding: '9px', marginTop: '16px', border: '0.3 solid grey' }}
                                    >
                                      <option value="Dashboard">Dashboard</option>
                                      <option value="Trips">Trips</option>
                                      <option value="Vehicle">Vehicle</option>
                                      <option value="Driver">Driver</option>
                                      <option value="Fuel">Fuel</option>
                                      <option value="Roles">Roles</option>
                                      <option value="Documentation">Documentation</option>
                                    </select>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div>
                                  <label>Description</label>
                                </div>
                                <div>
                                  <TextField
                                    // label="Type Here..."
                                    name="description"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    value={inputData.description}
                                    onChange={handelChange}
                                  />
                                </div>
                              </Grid>
                              <div
                                onDragEnter={handleDragEnter}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                                style={{
                                  borderRadius: "10px",
                                }}
                              >
                                <label>Upload</label>

                                <div className="file-upload-area">
                                  {
                                    image === "" ?
                                      <p>
                                        {image || "Drag and drop or"}
                                        &nbsp;
                                       

                                        <button
                                          className="uploadBrowse"
                                          onClick={(e) => handleBrowseClick(e)}
                                        >
                                          browse
                                        </button>
                                        <br/>
                                        <span className="" style={{fontSize:'10px'}}>Accepted File Types ( png, jpg, docx)</span>

                                        <input
                                          type="file"
                                          id="fileInput"
                                          name="upload"
                                          onChange={(e) => handleFileChange(e.target.files[0], 'upload')}
                                          style={{
                                            opacity: "0",
                                            width: "0.1px",
                                            height: "0.1px",
                                            overflow: "hidden",
                                            position: "absolute",
                                          }}
                                        />
                                      </p>
                                      :
                                      <img src={image} />
                                  }

                                </div>


                                {uploadedImage && (
                                  <div style={{ marginTop: "20px" }}>
                                    <img
                                      src={uploadedImage}
                                      alt="Uploaded"
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "200px",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: 20,
                                }}
                              >
                                <Button
                                className="submit_btn"
                                  type="submit"
                                  style={{
                                    color: "#fff",
                                    width: "100%",
                                    backgroundColor: "black",
                                    textTransform: "none",
                                    padding: "10px",
                                  }}
                                  onClick={handleSubmit}
                                >
                                  {action ? action : 'Submit'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SupportPage;
