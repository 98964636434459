import React from 'react';
import Modal from 'react-modal';

const CustomModal = ({ isOpen, closeModal, data }) => {

    const customStyles = {
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            background: '#fff',
            padding: '20px',
            borderRadius: '8px',
        }
    };

    const filteredData = Object.entries(data).filter(([key]) => !['_id', '__v', 'password','enteredOtp','fcmTokens','createdBy','Company'].includes(key));

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} className="tooltip_modal" >
            <div>
                {filteredData?.map(([key, value]) => (
                    <div key={key} className='df' style={{ gap: '20px', margin: '10px' }}>
                      
                        {(/\.(png|jpeg|jpg|svg)$/i.test(value)) ? (
                            <div style={{marginBottom:'30px'}} className='image-container df'>
                               <span className='_key mt-5'>{key}:</span>
                                <img src={value} className="_DP" alt={key}  />
                               
                            </div>
                        ) : (
                            <>
                            <span className='_key'>{key}:</span>
                            <span className='_value'>{value}</span>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default CustomModal;
