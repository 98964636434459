import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog({ industryData, updateData ,setGoodsData ,value }) {
  const [open, setOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState(value|| '');

  const handleClose = () => {
    setDialogValue('');
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateData({
      IndustryProcess: dialogValue,
    });
    handleClose();
  };

  const handleChange = (event, newValue) => {
    // // debugger;
    if (newValue && newValue.inputValue) {
      setDialogValue(newValue.inputValue);
      setOpen(true);
      updateData({
        IndustryProcess: newValue,
      });

    }
    else{
      setGoodsData(prevData => ({
        ...prevData,
        industryProcess: newValue 
      }))
    // selected(newValue)
    }
  }

  return (
    <React.Fragment>
      <Autocomplete
      //  value={value} 
      //  inputValue={value} // Set the inputValue prop of Autocomplete

      className='autocomplete_Search'
        onChange={handleChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={industryData?.filter(item => !!item)}
        getOptionLabel={(option) => option.inputValue || option}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        
        renderOption={(props, option) => <li {...props}>{option.inputValue || option}</li>}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <TextField {...params} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new Industry Process</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any industry process in our list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="industryProcess"
              value={dialogValue}
              onChange={(event) => setDialogValue(event.target.value)}
              label="Industry Process"
             
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
