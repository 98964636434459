import React, { useState, useEffect } from 'react';
import './TableComponent.css';
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from '@mui/material/CircularProgress';

import EastIcon from '@mui/icons-material/East';
import { Link } from 'react-router-dom';
import DetailsCard from './DetailsCard';
import DataViewer from '../MasterForms/DataViewer';
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DynamicForm from './DynamicForm';
import vehicleMasterConfig from '../MasterForms/config/VehicleConfig';
import { showError, showSuccess } from '../../toast';
import driverMasterConfig from '../MasterForms/config/DriverConfig';
import FuelMasterConfig from '../MasterForms/config/FuelConfig';
import LocationMasterConfig from '../MasterForms/config/LocationConfig';

import fetchData from "../DataFunctions/FetchDatabyID"
import CustomModal from '../DataFunctions/CustomModal';
import { calculateCO2eqEmissions } from '../utils/Co2e';
const TableComponent = ({ addLink,ONLYDATA, pagename, page, data = [], exclude, pdfs, col_class, edit, deletelink, setSelectedRowData, updateTripStatus, UpdateSupportstatus, onlyDocs }) => {
  const itemsPerPage = 7;

  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([...data]);
  const [sortKey, setSortKey] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [expandedRow, setExpandedRow] = useState(null);
  const [add, setaddmode] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    // showSuccess('Copied to clipboard');
}
  // Function to toggle the visibility of the popup and set the popup data
  const togglePopup = (data) => {

    if (typeof data !== 'object' ) {
      copyToClipboard(data);
        console.error('Invalid ObjectId:', data);
        return;
    }
    setIsModalOpen(true);
    setModalData(data)


  };

  const renderContent = (key, value) => {
    switch (key) {
      case 'Driver':

        return value?.DriverName || value?._id;
      case 'Vehicle':

        return value?.vehicleNumber || value?._id;
      case 'Source':

        return value?.address || value?._id;

      case 'Destination':

        return value?.locationName || value?._id;
      case 'user':
      case 'createdBy':

        return value?.name || value?._id;
      case 'role':
      case 'Role':

        return value?.roleName || value?._id;
      case 'Company':
      case 'companyName':

        return value?.Company || value?._id;
      case 'vendor':


        return value?.name || value?._id;
      default:
        return value._id;
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#ddd" : "#1c486b",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#1c486b",
        border: "6px solid #1c486b",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#ddd" : "#1c486b",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));


  const configMap = {
    VehicleMaster: vehicleMasterConfig,
    DriverMaster: driverMasterConfig,
    fuelmaster: FuelMasterConfig,
    locationmaster: LocationMasterConfig,
  };



  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  useEffect(() => {
    function handleKeyDown(event) {
      if ((event.ctrlKey || event.metaKey) && event.key === "k") {
        event.preventDefault(); 
        document.getElementById("search_by_page").focus();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const sortData = (key) => {
    const order = key === sortKey && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortKey(key);
    setSortOrder(order);

    const sortedDataCopy = [...sortedData].sort((a, b) => {
      const comparison = a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
      return order === 'asc' ? comparison : -comparison;
    });

    setSortedData(sortedDataCopy);
  };

  const toggleRow = (index) => {

    if (expandedRow === index) {
      setExpandedRow(null);

    } else {

      setExpandedRow(index);
    }
  };

  function formatKey(key) {
    let formattedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
    return formattedKey;
  }


  const renderTableHeaders = ({ columnLength }) => {
    if (!data || data.length === 0) {
      return null;
    }

    const keysToHide = ['__v', ...exclude];
    const visibleKeys = Object.keys(data[0]).filter((key) => !keysToHide?.includes(key));

    return (
      <div className="table-header _t_head_bg _Custom_rows " style={{ display: '' }}>
        {visibleKeys.map((key) => (
          <div key={key} onClick={() => sortData(key)} className='_table_head overflow'>
            {formatKey(key)} {<span className='toggle_span' style={{ fontSize: '10px', color: `${sortKey === key ? localStorage.getItem('--theme') || '#10B36F' : ''}` }}>{<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 512 512"><path fill="currentColor" d="M496.1 138.3L375.7 17.9c-7.9-7.9-20.6-7.9-28.5 0L226.9 138.3c-7.9 7.9-7.9 20.6 0 28.5 7.9 7.9 20.6 7.9 28.5 0l85.7-85.7v352.8c0 11.3 9.1 20.4 20.4 20.4 11.3 0 20.4-9.1 20.4-20.4V81.1l85.7 85.7c7.9 7.9 20.6 7.9 28.5 0 7.9-7.8 7.9-20.6 0-28.5zM287.1 347.2c-7.9-7.9-20.6-7.9-28.5 0l-85.7 85.7V80.1c0-11.3-9.1-20.4-20.4-20.4-11.3 0-20.4 9.1-20.4 20.4v352.8l-85.7-85.7c-7.9-7.9-20.6-7.9-28.5 0-7.9 7.9-7.9 20.6 0 28.5l120.4 120.4c7.9 7.9 20.6 7.9 28.5 0l120.4-120.4c7.8-7.9 7.8-20.7-.1-28.5z" /></svg>}</span>}
          </div>
        ))}
      </div>
    );
  };

  const renderTableRows = () => {
    if (!data || data.length === 0) {
      return null;
    }


    const keysToHide = exclude;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Filter the data based on the search input
    // const filteredData = sortedData.filter((item) =>
    //   Object.values(item).some((value) =>
    //     value?.toString().toLowerCase().includes(searchInput.toLowerCase())
    //   )
    // );

    const filteredData = sortedData.filter((item) => {
      const searchQuery = searchInput.trim();
      if (searchQuery) {
        return Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      return true;
    });


    const currentData = filteredData.slice(startIndex, endIndex);

    const handleCellClick = (key, itemsKi) => {

      // alert(`${key} - ${itemsKi} - ${page}`)
      // showSuccess(`${key}-${itemsKi}`)

      // fetchData(key, itemsKi, setModalData, setIsModalOpen)




      // setIsModalOpen(true);

    }

    const closeModal = () => {
      setIsModalOpen(false);
    };

    const handleSupportPageStatusChange = (key, item) => {


      UpdateSupportstatus(item)

    }


    const handleUpdateStatus = (e, id) => {
      // alert(`${e.target.value}  - ${id} -${page}`)
      updateTripStatus(id, e.target.value)
    }




    if (currentData.length === 0) {
      return (
        <div className="no-results-message">
          No results found for  &nbsp;<span style={{ color: 'red' }}>{searchInput}</span>
        </div>
      );
    }

    return currentData.map((item, index) => (
      <div key={index} className="table-row _Custom_rows">
        {expandedRow === startIndex + index ? (
          <div className="table-expanded-row _Detail_Div"  >
            <DataViewer onlyDocs={onlyDocs} config={configMap[page]} updateTripStatus={updateTripStatus} setSelectedRowData={setSelectedRowData} page={page} data={item} pdfs={pdfs} edit={edit} deletelink={deletelink} />
            <p style={{ float: 'right' }} onClick={() => toggleRow(startIndex + index)}>
              <span className='ArrowOpen_card' style={{ display: '', position: 'absolute', margin: '8px -30px', cursor: 'pointer', transform: 'rotate(270deg)' }} onClick={() => toggleRow(startIndex + index)}>{`>`}</span>

            </p>
          </div>
        ) : (
          <>
            <div className={`df Table_Box _table_body __${item['vehicleSource']}`}  >
              {Object.keys(item).map((key, index) => {
                if (keysToHide.includes(key)) {
                  return null; // Skip rendering for keys to hide
                }


                if (key === 'status' && page === 'SupportPage') {
                  return (
                    <div key={key} onClick={() => handleCellClick(key, item[key])} className={`_table_head ${col_class}`}>
                      <FormControlLabel
                        className={`${item[key]}_active`}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={item[key]}
                            // defaultChecked={item[key]}
                            onChange={() => handleSupportPageStatusChange(key, item)}
                          />
                        }
                        label={`${item[key] ? `Active ` : 'Closed'} `}
                        variant="outlined"
                      />
                    </div>
                  );
                }

                if (key === 'status' && page === 'trips') {
                  return (
                    <>
                      <div key={key} onClick={() => handleCellClick(key, item[key])} className={`_table_head ${col_class}`}>

                        <select
                          className={`table-select _${item[key]}`}
                          value={item[key]}
                          onChange={(event) => handleUpdateStatus(event, item._id)}
                        >

                          <option value="Pending">Yet to Start</option>
                          <option value="Progress">Progress</option>
                          <option value="Cancelled">Cancelled</option>
                          <option value="Completed">Completed</option>

                        </select>

                      </div>
                      <div>
                        
                      </div>
                    </>
                  );
                }
                if (key === 'profile' && ['rollsandpermission' ,"CompanyMaster"].includes(page)) {
                  return (
                    <>
                      <div  key={key} onClick={() => handleCellClick(key, item[key])} className={`_table_head  ${col_class}`}>
                          <img style={{height:'40px',marginTop:'-5px',width:'40px',borderRadius:'10px'}}  src={item[key]} />
                      </div>
                     
                    </>
                  );
                }
                if (key === 'latitude' || key === "longitude" && page === 'locationmaster') {

                  let content = item[key].toString();
                  if (typeof content == 'string') {
                    content = content.slice(0, 8);
                  }
                  return (
                    <div key={key} onClick={() => handleCellClick(key, item[key])} className={`_table_head   ${col_class}`}>
                      <p className={`_${key}`}>{content}</p>
                    </div>
                  );
                }

                if (key === "createdAt" && page === 'SupportPage' || key === "purchaseDate" && page === "Goods") {

                  let content = item[key].toString();
                  if (typeof content == 'string') {
                    content = content.slice(0, 10);
                  }
                  return (
                    <div key={key} onClick={() => handleCellClick(key, item[key])} className={`_table_head   ${col_class}`}>
                      <p className={`_${key}`}>{content}</p>
                    </div>
                  );
                }


                return (
                  <>
                    <div key={key} onClick={() => togglePopup(item[key])} className={`_table_head ${col_class} overflow`}>
                      {typeof item[key] === 'object' && item[key]?._id ? (
                        <p className={`_${key}`}>{renderContent(key, item[key])}</p>
                      ) : (
                        <p className={`_${key} ${item[key]}`}>{item[key]}</p>
                      )}
                    </div>


                  </>
                );
              })}
            </div>
            <div>
              {  <span className='ArrowOpen_card' style={{ display: `${expandedRow == null ? '' : ''}`, margin: '8px -30px', cursor: 'pointer', transform: 'rotate(90deg)' }} onClick={() => toggleRow(startIndex + index)}>{`>`}</span>}
            </div>
          </>
        )}

        {isModalOpen && modalData && <CustomModal isOpen={isModalOpen} closeModal={closeModal} data={modalData} setIsModalOpen={setIsModalOpen} />}

      </div>
    ));
  };


  const renderPagination = () => {
    if (!data || data.length === 0) {
      return null;
    }
    const filteredData = sortedData.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchInput.toLowerCase())
      )
    );
    const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
    const paginationButtons = [];

    for (let i = 1; i <= totalPages; i++) {
      paginationButtons.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={currentPage === i ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    return !ONLYDATA && (
      <div className="pagination df jsb" style={{ display: '' }} >

        <button style={{ opacity: `${currentPage !== 1 ? '1' : '0'}` }} className='prev_btn' onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>
          <KeyboardBackspaceIcon />
        </button>
        <div className='_pagination_buttons'> {paginationButtons}</div>
        <button style={{ opacity: `${currentPage !== data?.length / itemsPerPage ? '1' : '0'}` }} className='prev_btn' onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}>
          <EastIcon />
        </button>
      </div>
    );
  };
  const handleSave = (data) => {
    // alert(page)
    console.log(data)
  }

  return (
    <div>
      {data && data.length > 0 ? (
        <>
          <div className={`full_heightTable ${page} `}>
            <div className='Filters df jsb' >
              <div className='search_box'>
                <SearchIcon />
                <input placeholder={`Search ${pagename}`}
                  value={searchInput}
                  id='search_by_page'
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <span style={{ color: 'grey', fontSize: '10px', marginRight: '10px' }}>Ctrl + K</span>
              </div>

              {
                !page.includes('rollsandpermission') && !page.includes('SupportPage') && !page.includes('Documentation')&& !ONLYDATA && !onlyDocs &&
                <div className='mt-2' >
                  <Link to={`/${addLink}`}>
                    <button
                      className=''
                      style={{
                        fontSize: '12px',
                        height: "30px",
                        padding: "2px 10px",
                        color: "black",
                        textTransform: 'uppercase',
                        letterSpacing: '  1px',
                        backgroundColor: "white",
                        borderRadius: "5px",
                        border: "none",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0.1, 0.2)",
                      }}
                    >
                      {` + Add New ${pagename}`}
                    </button>
                  </Link>
                </div>
              }

            </div>

            {add ? (
              <div className='table-expanded-row _Detail_Div'>
                <DynamicForm setaddmode={setaddmode} formData={[]} mode="add" onSubmit={handleSave} masterPage={configMap[page]} />
              </div>
            ) : (
              <>
                <div style={{ height: '10px' }}></div>
                {renderTableHeaders(4)}
                <div className='Render_rows_area hidden_scrollbar'>
                  {renderTableRows()}
                </div>
              </>
            )}



          </div>
          {!add && renderPagination()}

        </>

      ) :
        <>
          <div className='_loader'><CircularProgress /></div>


        </>}

    </div>
  );
};

export default TableComponent;
