import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar"; // Assuming the correct path to your Sidebar component
import LocationTable from "./LocationTable";
import Header from "../../Components/Header/Header";
import axios from "axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axiosInstance from "../../Components/utils/axios"
import notfound from "../../utils/notfound.png"

const LocationMaster = () => {
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState(true);
  const containerStyle = {
    display: "flex",
  };

  const tableStyle = {
    width: "100%",
    margin: "10px",
  };

  // const [data, setData] = useState([]);
  // const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance.get("/location")
      .then((response) => {
        console.log("locationdata", response);
        setData(response?.data);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Status Code:", error.response.status);
          console.error("Response Data:", error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", error.message);
        }
      });
  }, []);



  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={tableStyle}>
        <div>
          <Header />
        </div>
        <div
          className="location_page"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading">Destination Master</div>

        </div>
        <div>
          {/* <LocationTable data={data.allLocations}  /> */}

          {
            data?.userLocations?.length > 0 ?
              <TableComponent addLink="AddNewLocationMaster" pagename="Location" page="locationmaster" data={data?.userLocations} exclude={['_id', '__v', 'createdAt', 'updatedAt', 'locationdoc', '']} col_class="overflow" edit={`/AddNewLocationMaster?action=update&search=`} deletelink={`/AddNewLocationMaster?action=delete&search=`} />
              :
              <>


                <div class="wrapper">
                  <div>
                    <div class="img">
                      <img src={notfound} />
                    </div>
                    <br />
                    <h6>NO Destination's Found</h6>
                    <br />
                    <Link to="/AddNewLocationMaster" >
                    <button className=" start_creating">START CREATING DESTINATION</button>

                  </Link>

                  </div>
                </div>


                

              </>
          }
        </div>
      </div>
    </div>
  );
};

export default LocationMaster;
