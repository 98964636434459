import Index from "./Route/Index";
import "./App.css";
// import axios from "axios";
import { useEffect, useState } from "react";
import { Toaster } from 'react-hot-toast'; // Changed import
function App() {
  // const [data, setData] = useState("");

  useEffect(() => { }, []);

  return (
    <div className="index" style={{background:'#F5F6FA' ,overflow:'hidden'}} >
    <Index />
    <Toaster
       position="top-center"
       reverseOrder={false}
       gutter={8}
       containerClassName=""
       containerStyle={{}}
       toastOptions={{
         className: '',
         duration: 5000,
         style: {
           background: '#363636',
           color: '#fff',
         },

         
         success: {
           duration: 3000,
           theme: {
             primary: 'green',
             secondary: 'black',
           },
         },
       }}
    />
  </div>
  );
}

export default App;
