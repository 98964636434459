import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import DriverTable from "./DriverMasterTable";
import axios from "axios";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axiosInstance from "../../Components/utils/axios"
import notfound from "../../utils/notfound.png"

const DriverMaster = () => {
  const [data, setData] = useState([]);
  const containerStyle = {
    display: "flex",
  };
  const tableStyle = {
    width: "100%",
    margin: "10px",
  };

  useEffect(() => {
    axiosInstance.get("/getDriver")
      .then((res) => {
        console.log(res);
        setData(res.data?.reverse());
      });
  }, []);

  return (
    <div style={containerStyle}>
      <div className="">
        <Sidebar />
      </div>
      <div style={tableStyle}>
        <Header />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="table-heading">Driver Master</div>

        </div>

        {
          data.length > 0 ?
            <TableComponent addLink="AddNewDriverMaster" pagename="Driver" page="DriverMaster" data={data} exclude={['_id', '__v', 'DriverIDfile', 'profilePic', 'licenseExpirationDate', 'countrycode', 'password', 'enteredOtp', 'ratings', 'noOfTrips', 'years', 'fcmTokens']} edit={`/AddNewDriverMaster?action=update&search=`} deletelink={`/AddNewDriverMaster?action=delete&search=`} ONLYDATA={JSON.parse(localStorage.getItem('_USER')).role.roleName ==="ADMIN"} />
            :

            <>

<div class="wrapper">
           <div>
             <div class="img">
               <img src={notfound} />
             </div>
             <br />
             <h6>NO Driver Found </h6>
             <br />
             {JSON.parse(localStorage.getItem('_USER')).role.roleName !=="ADMIN" &&
                <Link to="/AddNewDriverMaster" >
                  <button className=" start_creating">START CREATING {window.location.pathname?.slice(1)}</button>

                </Link>}
           </div>
         </div>
        
            </>
        }

      </div>
    </div>
  );
};

export default DriverMaster;
