import React, { useEffect, useState } from "react";
import LoginPage from "../../Components/Form/Form";


const Login = () => {

 

  return (
    <div>
      <LoginPage  />
    </div>
  );
};

export default Login;
