import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import ChartComponent from "./ChartComponent";
import TripsCard from "./TripsCard";
import CountriesTable from "./CountriesTable.js";
import EmissionBarChart from "./EmissionBarchart.js";
import TableComponent from "../../Components/CustomTable/CustomTable";
import { fetchTripsData, fetchFuelData } from "../../Components/DataFunctions/Apidata.js";
import axiosInstance from "../../Components/utils/axios.js"
import GetAverageEmmByFuel from "./Charts/GetAverageEmmByFuel.js";
import GetAverageEmissionsByVehicleType from "./Charts/getAvgEmmByVehType.js";
import GetAverageEmissionsByVendor from "./Charts/getAverageEmissionsByVendor.js";
import GetEmissionByVendor from "./Charts/getEmmisionbyVendor.js";
import GetTopEmissionContributorsBYVeh from "./Charts/getTopEmissionContributorsBYVeh.js";
import GetVehicleAnalytics from "./Charts/GetVehicleAnalytics.js";
import GetDistanceByVehAttribute from "./Charts/GetDistanceBVehAttr.js";
import EmissionTrend from "./Charts/EmissionTrendBymonths.js";
import GetFuelConsuptiononTrips from "./Charts/GetFuelConsuption.js";
import GetFuelConsuptionByVehicletype from "./Charts/GetfuelConsuptionbyVehCategory.js";
import GetEmissionByVendorandVehicles from "./Charts/GetEmisionbyVendorAndVeh.js";
import FuelTypeDistributionByVendorBar from "./Charts/GetFuelTypeDistributionByVendor.js";
import { showSuccess } from "../../toast.js";
import PointStyling from "./Charts/PointStylingEmission.js";

const Dashboard = () => {

  const [minimise, setMinimise] = useState(false);
  const [emLimit, setemLimit] = useState("")
  const [EmissionFuel, setEmissionFuel] = useState([])
  const [currentEmission, setcurrentEmission] = useState(0)
  const [EmissionPercentage, setEmissionPercentage] = useState('0%')

  const [facility,setfacilty] = useState("All")

  const [Data, setData] = useState([])


  

  const GetTripData = async () => {
    try {
        const response = await axiosInstance.get(`/getFacilatedTrips/${facility}`);
        console.log("faciliated trips",response.data);
        setData(response.data);
    } catch (error) {
        console.log(error);
    }
};



  const [fuelData, setfuelData] = useState([])
  const GetFuelData = async () => {
    console.log(await fetchFuelData())
    setfuelData(await fetchFuelData())
  }



  useEffect(() => {
    GetFuelData()
    getEmmsion()
    GetTripData()
// alert(facility)
    // setcurrentEmission(emmsiontotal.totalEmission?.toFixed(3))
    // const EmPercent = (emmsiontotal.totalEmission / emLimit) * 100;
    // setEmissionPercentage(EmPercent === Infinity ? '0%' : `${EmPercent?.toFixed(1)}%`)

  }, [facility])

  const headerStyle = {
    width: '100%', margin: '10px'
  }
  const data = [
    {
      "name": "USA",
      "flag": "us",
      "emission_co2": "5.41 billion metric tons",
      "emission_percentage": 14.5
    },
    {
      "name": "China",
      "flag": "cn",
      "emission_co2": "10.06 billion metric tons",
      "emission_percentage": 27.0
    },
    {
      "name": "India",
      "flag": "in",
      "emission_co2": "2.65 billion metric tons",
      "emission_percentage": 7.1
    },
    {
      "name": "Germany",
      "flag": "de",
      "emission_co2": "0.80 billion metric tons",
      "emission_percentage": 2.1
    },
    {
      "name": "Japan",
      "flag": "jp",
      "emission_co2": "1.34 billion metric tons",
      "emission_percentage": 3.6
    }
  ]

  const Chart = ({ span, children }) => {
    return (
      <div className="Echart" style={{ gridRow: `span ${span}` }}>
        {children}
      </div>
    );
  }

  const getEmmsion = async () => {
    try {
      axiosInstance.get("/Emmsion")
        .then((res) => {
          console.log("emmsion", res.data[0].EmmsionLimit);
          setemLimit(res.data[0].EmmsionLimit);
        });
    } catch (error) {
      console.log(error);
    }
  };

 
  
  const handleScroll = () => {

    // const dashboardLGrid = document.querySelector('.dashboard-grid .left-section');
    // const dashboardRGrid = document.querySelector('.dashboard-grid .right-section');
    // if (dashboardLGrid.scrollTop > 0 || dashboardRGrid.scrollTop > 0) {
    //   setMinimise(true);
    // } else {
    //   setMinimise(false);
    // }
  };

  return (
    <div className="df">
      <Sidebar />
      <div style={headerStyle}>
        <Header />
        {/* <ChartComponent/> */}
        <div className="table-heading dashboard-heading">Dashboard Overview</div>
    

            {/* <EmissionBarChart tripsData={Data} fuelData={fuelData} /> */}

  
        <div className="">
          <TripsCard Data={Data} minimise={minimise} />

          <div className="dashboard-grid mt-2" >
            <div className="left-section  "   onScroll={handleScroll}>
              <CountriesTable data={data} setfacilty={setfacilty} emLimit={emLimit} setemLimit={setemLimit} />
              <br />

              <br />
            </div>

            <div className="right-section  hidden_scrollbar" onScroll={handleScroll} style={{marginBottom:'-50px'}}>

              <div className="first-box sm"  >
              
                <GetVehicleAnalytics facility={facility} />
              </div>
              {/* <div className="second-box sm">

              <GetEmissionByVendor /> 
              </div> */}
              <div className="third-box sm ">
              <GetEmissionByVendor />
              </div>
              <div className="third-box sm ">
              <GetFuelConsuptiononTrips />
              </div>
              {/* <div className="sixth-box sm">
                <GetEmissionByVendor />
              </div> */}
              <div className="fourth-box sm">
                <GetFuelConsuptionByVehicletype facility={facility} />
              </div>
              <div className="fifth-box sm">
                <GetDistanceByVehAttribute  facility={facility} />
              </div>
              {/* <div className="sixth-box sm" style={{height:'400px'}}>
                <GetFuelConsuptiononTrips />
              </div> */}
             <br/>
             <br/>
             <br/>
          
             <br/>
             <br/>
             <br/> <br/>
             <br/>
             <br/>
             
            </div>


          </div>
        </div>

      </div>
    </div>
  );
};

export default Dashboard;
