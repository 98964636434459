import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IsModified from "../../Components/IsModified";

import { showSuccess, showError } from '../../toast';
// import saveFormData from "./saveFormData";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import CountrySelect from "../../Components/utils/CountryCode";




const AddnewVendorMaster = () => {
  // const history = useHistory();
  const Navigate = useNavigate();




  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action") || "";
  const selectedid = searchParams.get("search") || "";

 
  const [formModified, setFormModified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [vendorData, setvendorData] = useState({
    vendorID: "",
    vendor: '',
    name: '',
    countryCode: '',
    mobileNumber: '',
    email: '',
    driver: '',
    vehicle: ''
  });

  const [Driver, setDrivers] = useState([])
  const [Vehicle, setvehical] = useState([])
  const [CountryCode, setCountryCode] = useState("91")


  const generateRandom4DigitNumber = async () => {
    try {
        const response = await axiosInstance.get("/vendors");
        // // debugger
        const sortedData = response?.data

        const latestIndustry = sortedData[0];
        console.log("latestIndustry", latestIndustry)
        let id = 1;
        if (latestIndustry) {
            const latestID = latestIndustry.vendorID;
            id = parseInt(latestID.substring(4)) + 1;
        }
        return `VNDR${id}`;
    } catch (error) {
        console.error("Error fetching latest Vendor ID:", error);
        return `VNDR0`;
    }
};


useEffect(() => {
    const fetchData = async () => {
        const randomID = await generateRandom4DigitNumber();
        setvendorData((prev) => ({
            ...prev,
            vendorID: randomID 
        }));
    };

    fetchData();
}, []);



  useEffect(() => {
    axiosInstance.get(`/vendors/${selectedid}`).then((res) => {
      console.log("selectedID", res.data)
      const selectedVendor = res.data
      console.log("selectedVendor", selectedVendor)
      if (selectedVendor) {
        setvendorData({
          vendorID: selectedVendor.vendorID,
          name: selectedVendor.name,
          vendor: selectedVendor.vendor,
          countryCode: selectedVendor.countryCode,
          mobileNumber: selectedVendor.mobileNumber,
          email: selectedVendor.email,
          driver: selectedVendor.driver,
          vehicle: selectedVendor.vehicle
        });
      }
    })
  }, [selectedid])

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setvendorData((prevTripData) => {
      let updatedData = {
        ...prevTripData,
        [name]: value,
      };

      return updatedData;
    });
    setFormModified(true);
  };

  const validateForm = () => {
    // // debugger;
    // if (!vendorData.name) {
    //   showError("Please enter the name");
    //   return false;
    // }
  
    if (!vendorData.email) {
      showError("Please enter the email");
      return false;
    }
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(vendorData.email)) {
      showError("Please enter a valid email");
      return false;
    }
  
    if (!vendorData.mobileNumber) {
      showError("Please enter the mobile number");
      return false;
    }
    if (!(vendorData?.mobileNumber.toString().length > 9 && vendorData?.mobileNumber.toString().length < 11)) {
      showError("Mobile no must have 10 digits");
      return false;
  }
  
    return true;
  };
  

  const makeApiCall = async (method, url, data, callback) => {
    try {
      // // debugger;
      const response = await axiosInstance[method.toLowerCase()](url, {
        ...data
      });

      console.log("res", response)

      if (response.data.success === false) {
        showError(response?.data?.message)
      }
      else {
        showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
        callback()
        return response.data;
      }

    } catch (error) {

      console.error("Error making API call:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };

  const handleClickSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();


    var vendoID = document.getElementById('vendorID')
    const newData = { ...vendorData, countryCode: CountryCode, vendorID: vendoID.value,createdBy :localStorage.getItem('user_id') ,Company :localStorage.getItem('COMPANY')}
    console.log("newData", newData)

    if (isValid || action === "delete") {
      try {

        const responseData = await makeApiCall(
          `${action === "" ? 'POST' : action === 'update' ? 'PUT' : 'DELETE'}`,
          `/${action === "" ? `vendors` : action === 'update' ? `vendors/${selectedid}` : `vendors/${selectedid}`}`,
          newData, () => { Navigate('/vendorMaster') }
        );
        setFormModified(false);
        console.log("responseData",responseData.data);
        if(responseData.data.success){
            showSuccess('Vendor Created');
        }
        else{
          showError(responseData.data.message)
        }
      } catch (error) {

        console.error("Error submitting form:", error);
        
      }
    }

  };


  const handleModalClose = (result) => {
    setIsModalOpen(false);
    if (!result) {
      Navigate('/VendorMaster')
    }
  };

  const containerStyle = {
    display: "flex",
  };

  const formStyle = {
    height: '80vh',
    overflow: 'scroll',
    padding: "10px",
    borderRadius: "10px",
    margin: "none",
    marginTop: '20px',
    backgroundColor: "white",
  };


  const handleCountryChange = (code) => {
    setCountryCode(code)

  }

  const handleBackbutton = () => {
    if (formModified) {
      setIsModalOpen(true)
    }
    else {
      Navigate('/vendorMaster')
    }

  }

  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={{ width: "100%", margin: '10px' }}>
        <Header />
        <div className="table-heading theme_c"><span onClick={() => handleBackbutton()}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New'} Vendor  <span className={action ? `_${action}` : '_edit'}>{`${vendorData?.vendor || ''}`}</span></div>
        <div className="hidden_scrollbar" style={formStyle}>
          <form className="fuelform">
            <Grid container spacing={2} >
              <Grid item xs={4}>
                <label htmlFor="col2">Vendor ID  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  disabled
                  fullWidth
                  id="vendorID"
                  value={vendorData.vendorID}
                  name="vendorID"
                  // disabled={action === 'view'}
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Vendor <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="text"
                  fullWidth
                  id="col2"
                  value={vendorData?.vendor?.slice(0,20)}
                  name="vendor"
                  disabled={action === 'view'}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Vendor POC Name  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="text"
                  fullWidth
                  id="col2"
                  value={vendorData?.name?.slice(0,20)}
                  name="name"
                  disabled={action === 'view'}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Vendor Email  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="text"
                  fullWidth
                  id="col2"
                  value={vendorData.email}
                  name="email"
                  disabled={action === 'view'}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Vendor Mobile  <span className="_MANDATORY">*</span></label>
                <div className="df">
                  <CountrySelect id="newUserCNCode" width={170} CountryCode={CountryCode} onChange={handleCountryChange} />
                  <TextField
                    variant="outlined"
                    // placeholder="2244"
                    type="number"
                    fullWidth
                    id="col2"
                    disabled={action === 'view'}
                    value={(vendorData?.mobileNumber || '').toString().slice(0, 10)} 
                    name="mobileNumber"
                    onChange={handleInputChange}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <button
                  style={{
                    color: "white",
                    marginTop: '20px',
                    backgroundColor: "black",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                  // type="submit"

                  onClick={handleClickSubmit}
                  disabled={action === 'view'? true : false}
                >
                  {action ? action.toUpperCase() : 'Submit'}
                </button>
              </Grid>
            </Grid>


          </form>

          {isModalOpen &&
            <IsModified
              open={isModalOpen}
              onClose={handleModalClose}
              description="Do you want to save the data before going back?"
            />
          }
        </div>
      </div>

    </div>
  );
};

export default AddnewVendorMaster;
