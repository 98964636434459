import axios from 'axios';
import axiosInstance from "../../Components/utils/axios"

export const fetchFuelData = async () => {
  try {
    const response = await axiosInstance.get("/fuels");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching fuel data:", error);
    return [];
  }
};

export const fetchTripsData = async () => {
  try {
    const response = await axiosInstance.get("/trips");
    return response.data;
  } catch (error) {
    console.error("Error fetching fuel data:", error);
    return [];
  }
};

export const fetchBillingCountrywiseData = async () => {
  try {
    const response = await axiosInstance.get("/Billing?sort=-createdAt");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching fuel data:", error);
    return [];
  }
};



export const fetctVehicleData = async () => {
  try {
    const response = await axiosInstance.get("/vehicle");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching fuel data:", error);
    return [];
  }
};

export const fetctDriverData = async () => {
  try {
    const response = await axiosInstance.get("/getDriver");
    return response.data;
  } catch (error) {
    console.error("Error fetching fuel data:", error);
    return [];
  }
};

export const fetctLocationData = async () => {
  try {
    const response = await axiosInstance.get("/location");
    return response.data.allLocations;
  } catch (error) {
    console.error("Error fetching fuel data:", error);
    return [];
  }
};


