import * as React from "react";
import WorldMap from "react-svg-worldmap";
import { showError, showSuccess } from "../toast";

function WorldVector({cdata,color ,size}) {


const countries = {
  "INDIA":"IN",
  "UNITED STATES":"US",
  "MOROCCO":"MA"
}

  const transformedData = cdata?.map(item => ({
    country: countries[item._id]?.toUpperCase() || 'IN' ,
    value: item.totalEmission
  }));
  
  console.log(transformedData);



  // https://github.com/yanivam/react-svg-worldmap
  //  need to modify this component from this repo

  const MapClick = (e) => {
    if(e.countryValue){
      showSuccess(`  ${e.countryName}- ${e.countryValue} % `)
    }
    else{
      // showError(`  ${e.countryName}`)
    }
  };


  
  return (
    <div className={`map ${size}`} style={{background:'#fff'}}>
      <WorldMap
        color={'#10B36F' || color}
        fill="red"
        className="_custom-tooltip"
        title=""
        value-suffix="people"
        size={size}
        data={transformedData}

        width="140"
        valuePrefix =""
        valueSuffix = "KgCO₂e"
        strokeOpacity = "0.8"
        backgroundColor = ""
        tooltipBgColor = "#1c486be4"
        tooltipTextColor = "white"
        frame={false}
        frameColor = "red"
        borderColor = "#1c486be4"
        richInteraction={true}
        // styleFunction = 
        // tooltipTextFunction =  () => []
        onClickFunction={(e)=>MapClick(e)}
        // hrefFunction={hrefFunction}
        // textLabelFunction = () => []
      />
    </div>
  );
}

export default WorldVector;