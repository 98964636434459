import React, { useState } from 'react'
import TableComponent from '../../Components/CustomTable/CustomTable'
import CustomButtonGroup from './CustomButtonGroup'

function Docsbymodule({data}) {

const [module,setmodule] = useState('vehicles')


const excludeConfig = {
  trips: ['_id', '__v', 'Date', 'DistanceTravelled', 'selectDriver', 'stopTime', 'tripstartdate', 'tripenddate', 'StartOdoReading', 'EndOdoReading', 'vehicleCapacity', 'vehicleEffeciency', 'fuelType', 'fuelName', 'status', 'Driver', 'vehicleID', 'tripDocs', 'vehicleDestination', 'vehicleSource', 'Vehicle', 'isPaused', 'pauseReason', 'stopDuration', 'stopStartTime', 'tripStartedAt', 'StartOdoImage', 'EndOdoImage', 'TripDelayReason','vendor'],
  drivers: ['_id', '__v', 'DriverIDfile', 'profilePic', 'licenseExpirationDate', 'countrycode', 'password', 'enteredOtp', 'ratings', 'noOfTrips', 'years', 'fcmTokens', 'vendor'],
  vehicles: ['_id', '__v', 'vehicleMake', 'vehicleModel', 'vehicleYear', 'lastService', 'vehiclePOC', 'vehicleCapacity', 'distancemeasure', 'vehicleCondition', 'pucFile', 'rcFile', 'polutionFile', 'insuranceFile', 'fuelType', 'vehicleCategory', 'vehicle', 'TransportType', 'vendor'],
  locations: ['_id', '__v', 'createdAt', 'updatedAt', 'locationdoc']
};


  return (
    <div>
      <div className='docsBTN_GRP'><CustomButtonGroup setmodule={setmodule} /></div>
      <br/>
     <TableComponent addLink="AddNewFuelType" pagename={`${module} Docs`} page="fuelmaster" data={data[module]}  exclude={excludeConfig[module]} edit={`/AddNewFuelType?action=update&search=`} deletelink={`/AddNewFuelType?action=delete&search=`} onlyDocs={true} />


    </div>
  )
}

export default Docsbymodule
