import React, { useState, useEffect } from "react";
import { Navigate, useHistory, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import axiosInstance from "../../Components/utils/axios"
import { showSuccess, showError } from '../../toast';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IsModifiedModal from "../../Components/IsModified";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";



// import saveFormData from "./saveFormData";
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import CountrySelect from "../../Components/utils/CountryCode";




const AddnewCustomerMaster = () => {
  // const history = useHistory();
  const Navigate = useNavigate();
  const [address, setAddress] = useState("");




  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action") || "";
  const selectedid = searchParams.get("search") || "";

  const [formModified, setFormModified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [Companies, setCompanies] = useState([]);
  const [countries, setcountries] = useState([]);
  const [addCountry, setAddCountry] = useState("")

  const [customerData, setcustomerData] = useState({
    CustomerID: "", // Initialize vendorID here
    facilityName: '',
    address: '',
    nickname: '',
    incharge: '',
    companyName: localStorage.getItem('COMPANY'),
    Country: '',
    outsourceFacility: '',
    createdBy: localStorage.getItem('user_id')

  });

  const generateRandom4DigitNumber = async () => {
    try {
      const response = await axiosInstance.get("/customerFacility");
      // debugger
      const sortedData = response.data.data?.reverse()

      const latestIndustry = sortedData[0];
      console.log("customerFacility", sortedData)
      let id = 1;
      if (latestIndustry) {
        const latestID = latestIndustry.CustomerID;
        id = parseInt(latestID.substring(4)) + 1;
      }
      return `CUST${id}`;
    } catch (error) {
      console.error("Error fetching latest industry ID:", error);
      return `CUST0`;
    }
  };


  const getAllCompany = async () => {
    try {
      const response = await axiosInstance.get(
        `/companies`
      );
      console.log("rollsdata", response);
      const data = response.data;
      setCompanies(data)
    } catch (error) {
      console.error("Axios Error:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const randomID = await generateRandom4DigitNumber();
      setcustomerData((prev) => ({
        ...prev,
        CustomerID: randomID
      }));
    };
    getAllCompany()
    fetchData();
  }, []);


  useEffect(() => {

    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countries = response.data.map(country => ({
          name: country.name.common,
          currency: country.currencies ? Object.keys(country.currencies)[0] : 'Unknown' // Check if currencies property exists
        }));
        console.log("countries", countries)
        setcountries(countries)
      })
      .catch(error => console.error('Error fetching data:', error));





  }, []);

  const handleCurrencyChange = (e, newValue) => {

    setcustomerData((prev) => ({
      ...prev,
      Country: newValue?.name
    }));
    showSuccess(newValue?.name.toUpperCase())

  }



  useEffect(() => {
    if (selectedid) {
      axiosInstance.get(`/customerFacility/${selectedid}`).then((res) => {
        console.log("selectedID", res.data.data)
        const selectedVendor = res.data.data
        console.log("selectedVendor", selectedVendor)
        if (selectedVendor) {
          setcustomerData(selectedVendor);
          setAddress(selectedVendor?.address)
        }
      })
    }
  }, [selectedid])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormModified(true)
    setcustomerData((prevTripData) => {
      let updatedData = {
        ...prevTripData,
        [name]: value,
      };

      return updatedData;
    });
  };

  const validateForm = () => {

    if (!customerData.facilityName) {
      showError("Please Enter the facility Name");
      return false;
    }
    if (!address && action != "delete") {
      showError("Please Search the address ");
      return false;
    }
    if (!customerData.incharge) {
      showError("Please Enter the Admin Person Incharge");
      return false;
    }
    if (!customerData.companyName) {
      showError("Please Enter the Company Name");
      return false;
    }
    if (!customerData.outsourceFacility) {
      showError("Please Enter Owned / Outsourced Facility");
      return false;
    }
    return true;
  };

  const makeApiCall = async (method, url, data, callback) => {
    try {
      // debugger;
      const response = await axiosInstance[method.toLowerCase()](url, {
        ...data
      });

      console.log("res", response)
      if (response.data.status === "fail") {
        showError(response?.data?.message)
        return
      }
      if (response.data.success === false) {
        showError(response?.data?.message)
      }
      else {
        showSuccess(`${method === 'POST' ? 'saved' : action === 'update' ? 'Updated' : 'Deleted'} Successfully`);
        callback()
        return response.data;
      }

    } catch (error) {

      console.error("Error making API call:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  };

  const handleClickSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    // debugger

    const newData = {
      CustomerID: customerData.CustomerID,
      facilityName: customerData.facilityName,
      address: address,
      nickname: customerData.nickname,
      incharge: customerData.incharge,
      companyName: customerData.companyName,
      Country: addCountry?.toUpperCase(),
      outsourceFacility: customerData.outsourceFacility,
      createdBy: localStorage.getItem('user_id')
    }
    console.log("customerData", customerData);
    console.log("newdata", newData);
    if (isValid) {
      try {
        const responseData = await makeApiCall(
          `${action === "" ? 'POST' : action === 'update' ? 'PUT' : 'DELETE'}`,
          `/${action === "" ? `customerFacility` : action === 'update' ? `customerFacility/${selectedid}` : `customerFacility/${selectedid}`}`,
          newData, () => { Navigate('/customerfacility') }
        );
        console.log(responseData);
      } catch (error) {

        console.error("Error submitting form:", error);
      }
    }

  };

  const containerStyle = {
    display: "flex",
  };

  const formStyle = {
    height: '80vh',
    overflow: 'scroll',
    padding: "10px",
    borderRadius: "10px",
    margin: "none",
    marginTop: '20px',
    backgroundColor: "white",
  };

  const handleSelect = async (selectedAddress) => {
    try {
      // debugger;
      const results = await geocodeByAddress(selectedAddress);
      console.log("results", results);

      // Look for country in address components
      let countryName;
      for (const component of results[0].address_components) {
        if (component.types.includes("country")) {
          countryName = component.long_name;
          break;
        }
      }

      if (countryName) {
        console.log("Extracted country:", countryName);
        setAddCountry(countryName)
        const latLng = await getLatLng(results[0]);
        console.log(latLng);
        setAddress(selectedAddress);
        setcustomerData((prev) => ({
          ...prev,
          address: selectedAddress,
        }));
      } else {
        console.warn("Country not found in address components");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  const handleModalClose = (result) => {
    setIsModalOpen(false);
    if (!result) {
      Navigate('/VendorMaster')
    }
  };

  const handleBackbutton = () => {
    // alert(formModified)
    if (formModified) {
      setIsModalOpen(true)
    }
    else {
      Navigate('/customerfacility')
    }
  }





  return (
    <div style={containerStyle}>
      <div>
        <Sidebar />
      </div>
      <div style={{ width: "100%", margin: '10px' }}>
        <Header />
        <div className="table-heading theme_c"><span onClick={() => handleBackbutton()}><ArrowBackIcon /></span>{action ? action + '>' : '  Add New'} Facility  <span className={action ? `_${action}` : '_edit'}>{`${customerData?.nickname || ''}`}</span></div>
        <div className="hidden_scrollbar" style={formStyle}>
          <form className="fuelform">
            <Grid container spacing={2} >
              <Grid item xs={4}>
                <label htmlFor="col2">Customer ID  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  disabled
                  fullWidth
                  id="col2"
                  value={customerData.CustomerID}
                  name="vendorID"
                  onChange={(e) => handleInputChange(e)}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Facility Name <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="text"
                  fullWidth
                  id="col2"
                  value={customerData?.facilityName?.slice(0, 22)}
                  name="facilityName"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <div className="Addressbutton">
                  <label htmlFor="address">Address <span className="_MANDATORY">*</span></label>
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <TextField
                          {...getInputProps({
                            placeholder: "Enter your address",
                            className: "location-search-input",
                            style: {
                              width: "100%", // Ensure the input takes full width
                              // Adjust padding as needed
                              // Add a border for better visibility
                              // border: "1px solid red",
                              height: "2px", // Set a fixed height for the input field
                            },
                          })}
                          required
                        />
                        <div
                          // className="autocomplete-dropdown-container"
                          style={{
                            maxHeight: "40px",
                            Width: "100%",
                            maxWidth: "100%",
                            zIndex: "999",
                            marginTop: "20px",
                            boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                            background: "#fff",
                            opacity: "1",
                            position: "relative",
                          }}
                        >
                          {loading && (
                            <div
                              style={{ height: "40px", lineHeight: "40px" }}
                            >
                              Loading...
                            </div>
                          )}
                          <div
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#1c486b"
                                  : "",
                                color: 'grey',
                                padding: "10px", // Adjust padding as needed
                                border: "1px solid grey",
                              };
                              return (
                                <div
                                  className="suggestion"
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Grid>



              <Grid item xs={4}>
                <label htmlFor="col2">Facility ( nickname )  <span className="_MANDATORY">*</span></label>
                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="text"
                  fullWidth
                  id="col2"
                  value={customerData?.nickname?.slice(0, 22)}
                  name="nickname"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={4}>
                <label htmlFor="col2">Admin Person Incharge  <span className="_MANDATORY">*</span></label>
                <div className="df">
                  <TextField
                    variant="outlined"
                    type="text"
                    fullWidth
                    id="col2"
                    value={customerData?.incharge?.slice(0, 22)}
                    name="incharge"
                    onChange={handleInputChange}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                {/* <label htmlFor="col2">Company Name<span className="_MANDATORY">*</span></label>
                <div className="df">
                  <TextField
                    variant="outlined"
                    type="text"
                    fullWidth
                    id="col2"
                    value={customerData?.companyName?.slice(0,22)}
                    name="companyName"
                    onChange={handleInputChange}
                  />
                </div> */}

                <FormControl style={{ width: "400px", height: '39px' }}>
                  <label htmlFor="col2">Company Name<span className="_MANDATORY">*</span></label>

                  <Select
                    id="companyName"
                    value={customerData?.companyName}
                    name="companyName"
                    fullWidth

                    onChange={handleInputChange}
                    required
                    disabled={localStorage.getItem("welcome-NIRANARA")?.split('-')[0]?.trim() !== "SUPER ADMIN"}

                  >
                    {Companies?.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        <img className="menu_img" src={item.profile} /> &nbsp;  &nbsp;{item.Company}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>


              {/* <Grid item xs={4}>
                <label htmlFor="col2">Owned / Outsourced Facility<span className="_MANDATORY">*</span></label>
                <div className="df">
                  <TextField
                    variant="outlined"
                    // placeholder="2244"
                    type="text"
                    fullWidth
                    id="col2"
                    value={customerData?.outsourceFacility}
                    name="outsourceFacility"
                    onChange={handleInputChange}
                  />
                </div>
              </Grid> */}
              <Grid item xs={4}>

                <label className="">Country<span className="_MANDATORY"></span></label>
                {/* <Autocomplete
                  className="autocomplete_Search"
                  disablePortal
                  id="Currency"
                  name="Country"
                 placeholdertext="Country"
                  onChange={(e, newValue) => handleCurrencyChange(e, newValue)}
                  options={countries}
                  disabled={true}
                  getOptionLabel={(option) => option?.name }
                  // getOptionLabel={(option) => (<><span>{option.currency}</span>&nbsp;&nbsp; <span className="vsm _edit">{option.name}</span></>) }
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField {...params}  />}
                /> */}

                <TextField
                  variant="outlined"
                  // placeholder="2244"
                  type="text"
                  fullWidth
                  id="col2"
                  value={addCountry?.toUpperCase()}
                 disabled={true}
                />
              </Grid>
              <Grid item xs={4}>
                <label>Owned / Outsourced Facility<span className="_MANDATORY"> *</span></label>
                <Select
                  fullWidth
                  style={{ height: '38px' }}
                  value={customerData?.outsourceFacility}
                  onChange={handleInputChange}
                  required
                  name="outsourceFacility"

                >
                  <MenuItem value="Owned">Owned</MenuItem>
                  <MenuItem value="Out-Sourced">Out-Sourced</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <button
                  className="submit_btn"
                  style={{
                    color: "white",
                    marginTop: '20px',
                    backgroundColor: "black",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                  // type="submit"
                  onClick={handleClickSubmit}
                >
                  {action ? action.toUpperCase() : 'Submit'}
                </button>
              </Grid>
            </Grid>

            {isModalOpen &&
              <IsModifiedModal
                open={isModalOpen}
                onClose={handleModalClose}
                description="Do you want to save the data before going back?"
              />
            }
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddnewCustomerMaster;
